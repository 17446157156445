// Auth related messages
export const AUTH_MESSAGES = {
  ACCOUNT_CREATED: 'Account created successfully!',
  SIGNED_IN: 'Signed in successfully!',
  SIGNED_OUT: 'Signed out successfully',
  AUTH_ERROR: (message) => message
};

// Meal Plan related messages
export const MEAL_PLAN_MESSAGES = {
  LOADED: 'Meal plan loaded and ready to use!',
  LOAD_ERROR: 'Failed to load meal plan',
  GENERATED: 'Meal plan generated successfully!',
  GENERATE_ERROR: (message) => `Failed to generate meal plan: ${message}`,
  PREFERENCES_REQUIRED: 'Please set your preferences first',
  CHANGES_CANCELLED: 'Changes cancelled. Meal plan restored.',
  MEAL_SWAPPED: (name) => `Swapped to ${name}`,
  DAY_OCCUPIED: 'Selected day already has a meal assigned',
  PLAN_NAME_UPDATED: 'Plan name updated successfully!',
  PLAN_NAME_UPDATE_ERROR: 'Failed to update plan name',
  PLAN_DELETED: 'Meal plan deleted successfully!'
};

// Shopping List related messages
export const SHOPPING_LIST_MESSAGES = {
  UPDATE_ERROR: 'Failed to update shopping list',
  GENERATE_ERROR: 'Failed to generate shopping list. Please try again.'
};

// Feedback related messages
export const FEEDBACK_MESSAGES = {
  RATING_REQUIRED: 'Please select a rating',
  SUBMITTED: 'Thank you for your feedback!',
  SUBMIT_ERROR: 'Failed to submit feedback'
};

// Preferences related messages
export const PREFERENCES_MESSAGES = {
  SAVED: 'Preferences saved successfully',
  SAVE_ERROR: (message) => `Failed to save preferences: ${message}`,
  LOAD_ERROR: 'Failed to load preferences'
}; 