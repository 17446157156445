import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { RiCalendarLine, RiShoppingCart2Line, RiUserSettingsLine } from 'react-icons/ri';

const Welcome = () => {
  const navigate = useNavigate();

  const pulseAnimation = {
    scale: [1, 1.02, 1],
    transition: {
      duration: 2,
      repeat: Infinity,
      ease: "easeInOut"
    }
  };

  const features = [
    {
      icon: RiUserSettingsLine,
      title: 'Meals Tailored To You',
      description: 'Set your food preferences and get a healthy meal plan made just for you'
    },
    {
      icon: RiCalendarLine,
      title: 'Easy Meal Planning',
      description: 'Receive a weekly meal plan in 30 seconds and customize it to your liking'
    },
    {
      icon: RiShoppingCart2Line,
      title: 'Automated Shopping Lists',
      description: 'Get an organized shopping list for hassle-free grocery shopping'
    }
  ];

  return (
    <div className="container max-w-4xl mx-auto pt-4 sm:pt-8 px-4 pb-32 sm:pb-56 min-h-screen">
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="text-center relative z-10 w-full"
      >
        <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold text-dark mb-3 sm:mb-4 leading-tight max-w-4xl mx-auto">
          Effortless Meal Planning. Faster Grocery Shopping. Better Eating.
        </h1>
        <p className="text-base sm:text-lg text-foggy mb-6 sm:mb-8 max-w-2xl mx-auto">
          Easy Meals is your smart, personalized meal planning and grocery shopping assistant.
        </p>
        <motion.button 
          onClick={() => navigate('/preferences')}
          className="btn-primary text-base sm:text-lg py-3 px-6 shadow-md hover:shadow-lg transition-shadow"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.98 }}
          animate={pulseAnimation}
        >
          Create Your Meal Plan
        </motion.button>
      </motion.div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 sm:gap-6 mt-8 sm:mt-12">
        {features.map((feature, index) => {
          const Icon = feature.icon;
          return (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ 
                opacity: 1, 
                y: 0,
                transition: { delay: 0.2 * index }
              }}
              className="card text-center p-4 sm:p-6"
            >
              <div className="flex justify-center mb-3 sm:mb-4">
                <Icon className="w-10 h-10 sm:w-12 sm:h-12 text-primary" />
              </div>
              <h3 className="text-lg sm:text-xl font-semibold text-dark mb-1.5 sm:mb-2">
                {feature.title}
              </h3>
              <p className="text-sm sm:text-base text-foggy">
                {feature.description}
              </p>
            </motion.div>
          );
        })}
      </div>
    </div>
  );
};

export default Welcome;