import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  RiHome4Line, 
  RiUserSettingsLine, 
  RiCalendarLine, 
  RiShoppingCart2Line,
  RiSaveLine,
  RiMenuLine,
  RiCloseLine,
  RiUser3Line,
  RiChat1Line
} from 'react-icons/ri';
import { useAuth } from '../contexts/AuthContext';
import FeedbackButton from './FeedbackButton';
import toast from 'react-hot-toast';
import { AUTH_MESSAGES } from '../utils/toastMessages';
import AuthModal from './AuthModal';

const Navigation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, signIn, signUp, signOut, highlightSavedPlans } = useAuth();
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);

  const handleAuth = async (e) => {
    e.preventDefault();
    try {
      if (isSignUp) {
        const { data } = await signUp(email, password);
        if (data) {
          // Wait a moment for auth state to propagate
          await new Promise(resolve => setTimeout(resolve, 500));
          toast.success(AUTH_MESSAGES.ACCOUNT_CREATED);
          navigate('/saved-meal-plans');  // Navigate to saved meal plans after signup
        }
      } else {
        await signIn(email, password);
        toast.success(AUTH_MESSAGES.SIGNED_IN);
      }
      setIsAuthModalOpen(false);
      setEmail('');
      setPassword('');
    } catch (error) {
      toast.error(AUTH_MESSAGES.AUTH_ERROR(error.message));
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut();
      toast.success(AUTH_MESSAGES.SIGNED_OUT);
    } catch (error) {
      toast.error(AUTH_MESSAGES.AUTH_ERROR(error.message));
    }
  };

  const navItems = [
    { path: '/', icon: RiHome4Line, label: 'Home' },
    { path: '/preferences', icon: RiUserSettingsLine, label: 'Preferences' },
    { path: '/meal-plan', icon: RiCalendarLine, label: 'Meal Plan' },
    { path: '/shopping-list', icon: RiShoppingCart2Line, label: 'Shopping' },
    { path: '/saved-meal-plans', icon: RiSaveLine, label: 'Saved Plans', requiresAuth: true }
  ];

  return (
    <>
      {/* Desktop Navigation */}
      <header className="fixed top-0 w-full bg-white shadow-level2 z-50 hidden md:block">
        <div className="container mx-auto px-4">
          <div className="flex items-center justify-between h-16">
            <Link to="/" className="text-xl font-bold text-primary">Easy Meals</Link>
            <nav className="flex gap-8">
              {navItems
                .filter(item => !item.requiresAuth || user)
                .map(({ path, icon: Icon, label }) => {
                  const isHighlighted = highlightSavedPlans && path === '/saved-meal-plans';
                  const isActive = location.pathname === path;
                  
                  return (
                    <Link 
                      key={path} 
                      to={path}
                      className={`relative flex items-center gap-2 ${
                        isHighlighted ? 'animate-pulse' : ''
                      }`}
                    >
                      <Icon 
                        className={`text-xl ${
                          isActive ? 'text-primary' : 
                          isHighlighted ? 'text-green-500' : 
                          'text-foggy'
                        }`}
                      />
                      <span className={`${
                        isActive ? 'text-primary' : 
                        isHighlighted ? 'text-green-500 font-medium' : 
                        'text-foggy'
                      }`}>
                        {label}
                      </span>
                      {isActive && (
                        <motion.div
                          layoutId="nav-indicator-desktop"
                          className="absolute -bottom-1 w-full h-0.5 bg-primary rounded-full"
                          initial={false}
                          transition={{ type: "spring", stiffness: 300, damping: 30 }}
                        />
                      )}
                    </Link>
                  );
                })}
            </nav>
            <div className="flex items-center space-x-4">
              {user ? (
                <div className="flex flex-col items-end justify-center h-16">
                  <button
                    onClick={handleSignOut}
                    className="text-sm text-foggy hover:text-primary transition-colors mb-0.5"
                  >
                    Sign Out
                  </button>
                  <span className="text-xs text-gray-500">{user.email}</span>
                </div>
              ) : (
                <div className="flex items-center space-x-1">
                  <button
                    onClick={() => { setIsSignUp(false); setIsAuthModalOpen(true); }}
                    className="text-foggy hover:underline focus:outline-none"
                  >
                    Sign In
                  </button>
                  <span className="text-foggy">|</span>
                  <button
                    onClick={() => { setIsSignUp(true); setIsAuthModalOpen(true); }}
                    className="text-foggy hover:underline focus:outline-none"
                  >
                    Sign Up
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </header>

      {/* Mobile Top Bar */}
      <header className="fixed top-0 w-full bg-white shadow-level2 z-50 md:hidden">
        <div className="flex items-center justify-between h-16 px-4">
          <Link to="/" className="text-xl font-bold text-primary">Easy Meals</Link>
          <div className="flex items-center gap-3">
            <button
              onClick={() => setShowFeedback(true)}
              className="w-11 h-11 bg-white text-orange-400 border-2 border-orange-400 rounded-full shadow-level2 flex items-center justify-center hover:bg-orange-50 transition-colors animate-pulse-slow"
              aria-label="Give Feedback"
            >
              <RiChat1Line className="text-xl" />
            </button>
            <button
              onClick={() => setIsMobileMenuOpen(true)}
              className="p-2 text-foggy hover:text-primary transition-colors"
              aria-label="Open menu"
            >
              <RiMenuLine className="text-xl" />
            </button>
          </div>
        </div>
      </header>

      {/* Mobile Menu Overlay */}
      <AnimatePresence>
        {isMobileMenuOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 z-50 md:hidden"
            onClick={() => setIsMobileMenuOpen(false)}
          >
            <motion.div
              initial={{ x: '100%' }}
              animate={{ x: 0 }}
              exit={{ x: '100%' }}
              transition={{ type: 'spring', damping: 30, stiffness: 300 }}
              className="absolute right-0 top-0 bottom-0 w-4/5 max-w-sm bg-white shadow-lg"
              onClick={e => e.stopPropagation()}
            >
              <div className="flex flex-col h-full">
                <div className="flex items-center justify-between p-4 border-b border-light">
                  <h2 className="text-lg font-medium text-dark">Menu</h2>
                  <button
                    onClick={() => setIsMobileMenuOpen(false)}
                    className="p-2 text-foggy hover:text-primary transition-colors"
                    aria-label="Close menu"
                  >
                    <RiCloseLine className="text-2xl" />
                  </button>
                </div>

                {user && (
                  <div className="p-4 border-b border-light bg-gray-50">
                    <div className="flex items-center gap-3">
                      <div className="w-10 h-10 rounded-full bg-primary/10 flex items-center justify-center">
                        <RiUser3Line className="text-xl text-primary" />
                      </div>
                      <div className="flex-1 min-w-0">
                        <p className="text-sm font-medium text-dark truncate">
                          {user.email}
                        </p>
                      </div>
                    </div>
                    <button
                      onClick={() => {
                        handleSignOut();
                        setIsMobileMenuOpen(false);
                      }}
                      className="mt-3 text-sm text-foggy hover:text-primary transition-colors"
                    >
                      Sign Out
                    </button>
                  </div>
                )}

                {!user && (
                  <div className="p-4 border-b border-light">
                    <div className="flex flex-col items-center gap-3">
                      <button
                        onClick={() => {
                          setIsSignUp(false);
                          setIsAuthModalOpen(true);
                          setIsMobileMenuOpen(false);
                        }}
                        className="text-sm text-foggy hover:text-primary transition-colors"
                      >
                        Sign In
                      </button>
                      <button
                        onClick={() => {
                          setIsSignUp(true);
                          setIsAuthModalOpen(true);
                          setIsMobileMenuOpen(false);
                        }}
                        className="text-sm text-foggy hover:text-primary transition-colors"
                      >
                        Sign Up
                      </button>
                    </div>
                  </div>
                )}

                <div className="flex-1 overflow-y-auto">
                  {/* Add future menu items here */}
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Mobile Bottom Navigation */}
      <div className="fixed bottom-0 left-0 right-0 z-50 md:hidden">
        {/* Navigation Bar */}
        <nav className="bg-white shadow-level2-reverse">
          <div className="flex items-center justify-around h-16">
            {navItems
              .filter(item => !item.requiresAuth || user)
              .map(({ path, icon: Icon, label }) => {
                const isHighlighted = highlightSavedPlans && path === '/saved-meal-plans';
                const isActive = location.pathname === path;
                
                return (
                  <Link
                    key={path}
                    to={path}
                    className={`flex flex-col items-center justify-center w-full h-full relative ${
                      isHighlighted ? 'animate-pulse' : ''
                    }`}
                  >
                    <Icon 
                      className={`text-xl mb-1 ${
                        isActive ? 'text-primary' : 
                        isHighlighted ? 'text-green-500' : 
                        'text-foggy'
                      }`}
                    />
                    <span className={`text-xs ${
                      isActive ? 'text-primary' : 
                      isHighlighted ? 'text-green-500 font-medium' : 
                      'text-foggy'
                    }`}>
                      {label}
                    </span>
                    {isActive && (
                      <motion.div
                        layoutId="nav-indicator-mobile"
                        className="absolute -top-0.5 w-1/2 h-0.5 bg-primary rounded-full"
                        initial={false}
                        transition={{ type: "spring", stiffness: 300, damping: 30 }}
                      />
                    )}
                  </Link>
                );
              })}
          </div>
        </nav>
      </div>

      {/* Add padding to account for top navigation only */}
      <div className="h-16" /> {/* Top padding */}

      {/* Auth Modal */}
      <AuthModal
        isOpen={isAuthModalOpen}
        onClose={() => setIsAuthModalOpen(false)}
        isSignUp={isSignUp}
        setIsSignUp={setIsSignUp}
        handleAuth={handleAuth}
        email={email}
        setEmail={setEmail}
        password={password}
        setPassword={setPassword}
      />

      {/* Add FeedbackButton component */}
      <FeedbackButton isOpen={showFeedback} onClose={() => setShowFeedback(false)} />
    </>
  );
};

export default Navigation;