import React, { useState } from 'react';
import { RiCloseLine, RiRocketLine } from 'react-icons/ri';
import toast from 'react-hot-toast';
import { supabase } from '../config/supabase';
import { useAuth } from '../contexts/AuthContext';
import { useLocation } from 'react-router-dom';
import { FEEDBACK_MESSAGES } from '../utils/toastMessages';

const FeedbackModal = ({ isOpen, onClose }) => {
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { user } = useAuth();
  const location = useLocation();

  const ratingEmojis = {
    1: '😡',
    2: '😕',
    3: '😐',
    4: '🙂',
    5: '😍'
  };

  const ratingLabels = {
    1: 'Poor',
    2: 'Fair',
    3: 'Okay',
    4: 'Good',
    5: 'Great'
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (rating === 0) {
      toast.error(FEEDBACK_MESSAGES.RATING_REQUIRED);
      return;
    }

    setIsSubmitting(true);
    try {
      const feedbackData = {
        rating,
        content: comment.trim(),
        user_id: user?.id || null,
        user_email: user?.email || null,
        created_at: new Date().toISOString(),
        page_context: location.pathname,
        user_agent: navigator.userAgent,
        screen_size: `${window.innerWidth}x${window.innerHeight}`,
        status: 'new'
      };

      const { error } = await supabase
        .from('feedback')
        .insert([feedbackData]);

      if (error) throw error;

      toast.success('Thanks for your feedback! 😊', {
        duration: 4000,
        icon: '✨'
      });
      onClose();
      setRating(0);
      setComment('');
    } catch (error) {
      console.error('Error submitting feedback:', error);
      toast.error(FEEDBACK_MESSAGES.SUBMIT_ERROR);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-dark/50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-level2 w-full max-w-lg mx-4">
        <div className="flex items-center justify-between p-6 border-b border-light">
          <h2 className="text-xl font-semibold text-dark">How was your experience?</h2>
          <button
            onClick={onClose}
            className="text-foggy hover:text-dark transition-colors"
          >
            <RiCloseLine className="text-2xl" />
          </button>
        </div>
        
        <form onSubmit={handleSubmit} className="p-6 space-y-6">
          {/* Emoji Rating Selection */}
          <div className="flex justify-center gap-6">
            {[1, 2, 3, 4, 5].map((value) => (
              <button
                key={value}
                type="button"
                onClick={() => setRating(value)}
                className={`group relative text-3xl transition-all duration-200 ${
                  rating === value
                    ? 'transform scale-150 drop-shadow-[0_0_12px_rgba(0,0,0,0.15)]'
                    : 'hover:scale-115'
                } ${
                  rating === value 
                    ? 'opacity-100 after:absolute after:inset-0 after:rounded-full after:ring-2 after:ring-primary/30 after:-m-1' 
                    : 'opacity-50 hover:opacity-90'
                }`}
              >
                {ratingEmojis[value]}
                {/* Tooltip */}
                <div className="absolute -bottom-8 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 transition-opacity pointer-events-none">
                  <div className="bg-dark text-white text-xs py-1 px-2 rounded whitespace-nowrap">
                    {ratingLabels[value]}
                  </div>
                </div>
              </button>
            ))}
          </div>

          {/* Optional Comment Box */}
          <div className="mt-8">
            <label htmlFor="comment" className="block text-sm text-foggy mb-2">
              Want to share more?
            </label>
            <textarea
              id="comment"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Your thoughts help us improve..."
              className="w-full h-20 px-4 py-3 rounded-lg border border-light focus:border-primary focus:ring-1 focus:ring-primary outline-none transition-all resize-none text-sm"
            />
          </div>

          {/* Action Buttons */}
          <div className="flex justify-end gap-3">
            <button
              type="button"
              onClick={onClose}
              className="px-6 py-2 border border-light rounded-lg text-foggy hover:text-dark hover:border-dark transition-colors"
              disabled={isSubmitting}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-6 py-2 bg-primary text-white rounded-lg hover:bg-primary/90 transition-colors flex items-center gap-2"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                'Sending...'
              ) : (
                <>
                  Send Feedback
                  <RiRocketLine className="text-lg" />
                </>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FeedbackModal; 