import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  RiRefreshLine, 
  RiShoppingCart2Line, 
  RiCalendarLine, 
  RiListUnordered, 
  RiErrorWarningLine, 
  RiRestaurantLine,
  RiUserSettingsLine,
  RiAlertLine,
  RiArrowRightLine,
  RiCheckLine,
  RiTimeLine,
  RiCloseLine,
  RiInformationLine,
  RiArrowDownSLine,
  RiRepeatLine
} from 'react-icons/ri';
import { useMealPlan } from '../contexts/MealPlanContext';
import { usePreferences } from '../contexts/PreferencesContext';
import MealCard from '../components/MealCard';
import toast from 'react-hot-toast';
import MealSwap from '../components/MealSwap';
import ModificationWarningModal from '../components/ModificationWarningModal';
import StickyActions from '../components/StickyActions';
import { MEAL_PLAN_MESSAGES } from '../utils/toastMessages';
import ViewModeToggle from '../components/ViewModeToggle';
import { useViewMode, VIEW_MODES } from '../contexts/ViewModeContext';

// First, let's separate quotes and facts
const LOADING_QUOTES = [
  {
    text: "One cannot think well, love well, sleep well, if one has not dined well.",
    author: "Virginia Woolf",
    type: "quote"
  },
  {
    text: "People who love to eat are always the best people.",
    author: "Julia Child",
    type: "quote"
  },
  {
    text: "Cooking is like love. It should be entered into with abandon or not at all.",
    author: "Harriet Van Horne",
    type: "quote"
  },
  {
    text: "The only time to eat diet food is while you're waiting for the steak to cook.",
    author: "Julia Child",
    type: "quote"
  },
  {
    text: "Life is uncertain. Eat dessert first.",
    author: "Ernestine Ulmer",
    type: "quote"
  },
  {
    text: "Let food be thy medicine, and medicine be thy food.",
    author: "Hippocrates",
    type: "quote"
  },
  {
    text: "A recipe has no soul. You, as the cook, must bring soul to the recipe.",
    author: "Thomas Keller",
    type: "quote"
  },
  {
    text: "Cooking is the ultimate giving.",
    author: "Jamie Oliver",
    type: "quote"
  }
];

const LOADING_FACTS = [
  {
    text: "Did you know? The word 'companion' comes from Latin, meaning 'with bread' - someone you share meals with.",
    type: "fact"
  },
  {
    text: "The average person spends 37,000 hours of their life in the kitchen.",
    type: "fact"
  },
  {
    text: "Meal planning can reduce food waste by up to 60% and save an average family $1,500 per year.",
    type: "fact"
  },
  {
    text: "Cooking at home is not only healthier but typically uses 50% less energy than eating out.",
    type: "fact"
  }
];

const LoadingQuote = () => {
  const [messages, setMessages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  // Initialize messages in a random but alternating order
  useEffect(() => {
    const shuffledQuotes = [...LOADING_QUOTES].sort(() => Math.random() - 0.5);
    const shuffledFacts = [...LOADING_FACTS].sort(() => Math.random() - 0.5);
    
    // Create alternating array of quotes and facts
    const alternatingMessages = [];
    const maxLength = Math.max(shuffledQuotes.length, shuffledFacts.length);
    
    for (let i = 0; i < maxLength; i++) {
      if (i < shuffledQuotes.length) {
        alternatingMessages.push(shuffledQuotes[i]);
      }
      if (i < shuffledFacts.length) {
        alternatingMessages.push(shuffledFacts[i]);
      }
    }

    setMessages(alternatingMessages);
  }, []);

  useEffect(() => {
    if (messages.length === 0) return;

    const interval = setInterval(() => {
      setCurrentIndex((current) => (current + 1) % messages.length);
    }, 5000); // Change message every 5 seconds

    return () => clearInterval(interval);
  }, [messages.length]);

  if (messages.length === 0) return null;

  const message = messages[currentIndex];

  return (
    <motion.div
      key={currentIndex}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.5 }}
      className="max-w-md mx-auto text-center"
    >
      <p className="text-lg text-dark italic mb-2">"{message.text}"</p>
      {message.author && (
        <p className="text-sm text-foggy">— {message.author}</p>
      )}
      {message.type === 'fact' && (
        <p className="text-sm text-primary mt-1">Fun Fact!</p>
      )}
    </motion.div>
  );
};

const MealTypeSection = ({ type }) => {
  const { mealPlan, getMealsByType } = useMealPlan();
  const { viewMode } = useViewMode();
  
  // Use meal types from the meal plan's preferences instead of current preferences
  const selectedMealTypes = mealPlan?.preferences?.mealTypes?.includes('all')
    ? ['breakfast', 'lunch', 'dinner', 'snacks']
    : mealPlan?.preferences?.mealTypes || [];

  // Only show this section if the meal type is selected
  if (!selectedMealTypes.includes(type)) {
    return null;
  }
  
  // Get meals organized by type
  const mealsByType = getMealsByType(mealPlan?.days);
  const meals = mealsByType[type] || [];
  
  // Count removed meals to adjust the expected total
  const removedMealsCount = mealPlan?.days?.reduce((count, day) => {
    return count + (day.meals?.[type]?.isRemoved ? 1 : 0);
  }, 0) || 0;
  
  // Calculate total meals for this type (only count meals with frequency > 0)
  // Include both active and removed meals in the total count
  const total = meals.reduce((sum, meal) => sum + (meal.frequency || 0), 0);
  const expectedTotal = 7;
  const isValid = total === expectedTotal;
  const hasExcessMeals = total >= expectedTotal;

  return (
    <div className="space-y-3">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <h2 className="text-xl font-semibold text-dark capitalize">{type}</h2>
          <div className={`px-2 py-0.5 rounded-full text-sm ${
            isValid 
              ? 'bg-primary/10 text-primary' 
              : 'bg-orange-400/10 text-orange-400'
          }`}>
            {total}/{expectedTotal}
          </div>
        </div>
        {!isValid && !hasExcessMeals && (
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            className="text-sm flex items-center gap-1 text-orange-400"
          >
            <RiAlertLine />
            <span>Add more meals</span>
          </motion.div>
        )}
      </div>
      <div className={`${
        viewMode === VIEW_MODES.COLLAPSED 
          ? 'space-y-2' 
          : viewMode === VIEW_MODES.STANDARD
            ? 'grid grid-cols-1 lg:grid-cols-2 gap-4'
            : 'space-y-4'
      }`}>
        {meals.map((meal, index) => (
          <MealCard
            key={`${meal.name}-${index}`}
            meal={meal}
            mealType={type}
            totalMeals={total}
          />
        ))}
      </div>
    </div>
  );
};

const DayView = () => {
  const { mealPlan, swapDailyMeal, getAvailableMealsForDay } = useMealPlan();
  const { viewMode } = useViewMode();
  const [openMealSwap, setOpenMealSwap] = useState(null);
  const [savedMealId, setSavedMealId] = useState(null);
  const [expandedCards, setExpandedCards] = useState({});

  // Get selected meal types from meal plan preferences
  const selectedMealTypes = mealPlan?.preferences?.mealTypes?.includes('all')
    ? ['breakfast', 'lunch', 'dinner', 'snacks']
    : mealPlan?.preferences?.mealTypes || ['breakfast', 'lunch', 'dinner', 'snacks'];

  // Effect to handle auto-expansion in detailed view
  useEffect(() => {
    if (viewMode === VIEW_MODES.DETAILED) {
      // Create an object with all cards expanded
      const allExpanded = {};
      mealPlan?.days?.forEach(day => {
        selectedMealTypes.forEach(type => {
          if (day.meals?.[type]) {
            allExpanded[`${day.dayName}-${type}`] = true;
          }
        });
      });
      setExpandedCards(allExpanded);
    } else {
      // Collapse all cards when leaving detailed view
      setExpandedCards({});
    }
  }, [viewMode, mealPlan?.days, selectedMealTypes]);

  // Add click outside handler
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.meal-swap-container')) {
        setOpenMealSwap(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Reset saved meal indicator after animation
  useEffect(() => {
    if (savedMealId) {
      const timer = setTimeout(() => {
        setSavedMealId(null);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [savedMealId]);

  const handleCardClick = (cardId, event) => {
    // Don't expand if clicking meal swap
    if (event.target.closest('.meal-swap-container')) {
      console.log('Meal swap clicked, ignoring expansion');
      return;
    }
    
    console.log('Card clicked:', cardId);
    console.log('Current expanded state:', expandedCards[cardId]);
    console.log('View mode:', viewMode);
    
    setExpandedCards(prev => {
      const newState = {
        ...prev,
        [cardId]: !prev[cardId]
      };
      console.log('New expanded state:', newState);
      return newState;
    });
  };

  const renderMealCard = (day, type, meal) => {
    // Add null checks for day and meal
    if (!day?.meals || !meal || !meal.name) {
      return null;
    }

    const mealSwapId = `${day.dayName}-${type}`;
    const cardId = mealSwapId;
    // In detailed view, use the expandedCards state but default to true if not set
    const isExpanded = viewMode === VIEW_MODES.DETAILED 
      ? expandedCards[cardId] !== false  // default to true unless explicitly set to false
      : expandedCards[cardId];           // use exact value in other views
    
    console.log('Rendering card:', cardId, 'isExpanded:', isExpanded, 'expandedCards:', expandedCards);
    
    const isSaved = savedMealId === mealSwapId;
    const availableMeals = getAvailableMealsForDay(type);
    
    // Check if this is a "No meal assigned" card, either from removal or zeroing out
    const isNoMealAssigned = meal.isRemoved || meal.name === "No meal assigned" || meal.isPlaceholder;

    return (
      <motion.div
        key={type}
        animate={{
          borderColor: isSaved 
            ? isNoMealAssigned ? '#f97316' : '#22c55e'  // Orange for removed, green for added
            : '#f1f5f9',
          transition: { duration: 0.3 }
        }}
        onClick={(e) => handleCardClick(cardId, e)}
        className={`cursor-pointer border-2 rounded-lg bg-white shadow-sm hover:border-primary/20 transition-colors ${
          isExpanded ? 'border-primary/20' : ''
        }`}
      >
        {/* Header - Always Visible */}
        <div className="flex items-center justify-between p-2.5 gap-3">
          <div className="flex-1 min-w-0">
            <h3 className="text-sm font-medium text-foggy capitalize mb-1">{type}</h3>
            <p className={`text-sm font-medium ${isNoMealAssigned ? 'text-foggy italic' : 'text-dark'} break-words`}>
              {isNoMealAssigned ? 'No meal assigned' : meal.name}
            </p>
          </div>
          <div className="flex items-center gap-3 shrink-0">
            {!isNoMealAssigned && (
              <div className="flex items-center text-foggy text-xs">
                <RiTimeLine className="mr-1" />
                <span>{(meal.prepTime || '0m').split(' ')[0]}m</span>
              </div>
            )}
            <div className="meal-swap-container relative">
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenMealSwap(openMealSwap === mealSwapId ? null : mealSwapId);
                }}
                className="p-1.5 text-foggy hover:text-primary transition-colors bg-light/50 rounded-full shadow-sm"
              >
                <RiRepeatLine className="text-lg" />
              </motion.button>
              <AnimatePresence>
                {openMealSwap === mealSwapId && (
                  <motion.div
                    initial={{ opacity: 0, y: 5 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 5 }}
                    className="absolute right-0 top-full mt-1 w-64 bg-white rounded-lg shadow-lg border border-light/50 z-50"
                  >
                    <div className="max-h-[300px] overflow-y-auto py-1">
                      {/* Add Remove Meal Option - only show if not already removed */}
                      {!isNoMealAssigned && (
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            swapDailyMeal(day.dayName, type, null);
                            setOpenMealSwap(null);
                            setSavedMealId(mealSwapId);
                          }}
                          className="w-full text-left px-3 py-2 hover:bg-light/50 transition-colors border-b border-light/50"
                        >
                          <div className="flex items-center justify-between">
                            <div className="font-medium text-accent text-sm">Remove meal</div>
                          </div>
                          <p className="text-xs text-foggy mt-0.5">Remove a meal if you don't plan to cook anything for {type} on {day.dayName}.</p>
                        </button>
                      )}
                      
                      {availableMeals.map((availableMeal) => (
                        <button
                          key={availableMeal.name}
                          onClick={(e) => {
                            e.stopPropagation();
                            swapDailyMeal(day.dayName, type, availableMeal.name);
                            setOpenMealSwap(null);
                            setSavedMealId(mealSwapId);
                          }}
                          className="w-full text-left px-3 py-2 hover:bg-light/50 transition-colors border-b border-light/50 last:border-b-0"
                        >
                          <div className="flex items-center justify-between">
                            <div className="font-medium text-dark text-sm">{availableMeal.name}</div>
                            <div className="flex items-center text-foggy text-xs ml-2">
                              <RiTimeLine className="mr-1" />
                              <span>{availableMeal.prepTime}</span>
                            </div>
                          </div>
                          <p className="text-xs text-foggy mt-0.5">{availableMeal.description}</p>
                        </button>
                      ))}
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
        </div>

        {/* Expanded Content */}
        <AnimatePresence>
          {isExpanded && !isNoMealAssigned && (
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.2 }}
              className="border-t border-light"
            >
              <div className="px-3 pt-2 pb-3 space-y-4">
                {/* Description */}
                <p className="text-sm text-foggy leading-relaxed">{meal.description || 'No description available'}</p>

                {/* Notes (if available) */}
                {meal.notes && (
                  <div className="p-2.5 bg-[#E3F2FD] rounded-md flex items-start gap-2">
                    <RiInformationLine className="text-[#1976D2] shrink-0 mt-0.5 text-lg" />
                    <p className="text-sm text-[#0D47A1] leading-relaxed">{meal.notes}</p>
                  </div>
                )}

                {/* Macros (if available) */}
                {meal.macros && (
                  <div className="flex items-center gap-4 text-sm text-foggy">
                    <span>Protein: {meal.macros.protein}g</span>
                    <span>Carbs: {meal.macros.carbs}g</span>
                    <span>Fat: {meal.macros.fat}g</span>
                  </div>
                )}

                {/* Ingredients (if available) */}
                {meal.ingredients?.length > 0 && (
                  <div>
                    <h4 className="text-sm font-medium text-dark mb-2">Ingredients</h4>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-1.5">
                      {meal.ingredients.map((ingredient, index) => (
                        <div 
                          key={index}
                          className="text-sm text-foggy bg-light/50 px-3 py-1.5 rounded-md"
                        >
                          {ingredient}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    );
  };

  // Add null check for mealPlan and days
  if (!mealPlan?.days) {
    return null;
  }

  return (
    <div className="space-y-8">
      {mealPlan.days.map((day) => (
        <div key={day.dayName} className="space-y-3">
          <h2 className="text-xl font-semibold text-dark capitalize">{day.dayName}</h2>
          <div className={`${
            viewMode === VIEW_MODES.AT_A_GLANCE 
              ? 'grid grid-cols-1 md:grid-cols-2 gap-2'
              : 'space-y-4'
          }`}>
            {selectedMealTypes.map(type => 
              day.meals?.[type] ? renderMealCard(day, type, day.meals[type]) : null
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

const MealPlan = () => {
  const navigate = useNavigate();
  const { 
    mealPlan, 
    loading, 
    error, 
    generateMealPlan, 
    isModified,
    isFinalized,
    canModify,
    finalizeMealPlan,
    startModifications,
    cancelModifications,
    needsShoppingListUpdate,
    validationState
  } = useMealPlan();
  const { preferences, isModifiedSinceMealPlan, needsRevert, revertPreferences } = usePreferences();
  const [viewMode, setViewMode] = useState('type');
  const [showModifyWarning, setShowModifyWarning] = useState(false);
  const [showRefreshModal, setShowRefreshModal] = useState(false);

  const handleRegenerate = () => {
    if (!preferences?.peopleCount) {
      toast.error(MEAL_PLAN_MESSAGES.PREFERENCES_REQUIRED);
      navigate('/preferences');
      return;
    }
    generateMealPlan();
  };

  const handleFinalize = async () => {
    const success = await finalizeMealPlan();
    if (success) {
      navigate('/shopping-list');
    }
  };

  const handleStartModifications = () => {
    if (isFinalized) {
      setShowModifyWarning(true);
    } else {
      startModifications();
    }
  };

  if (loading) {
    return (
      <div className="container max-w-4xl mx-auto py-8 px-4">
        <div className="flex flex-col items-center justify-center min-h-[60vh] space-y-8">
          <motion.div
            animate={{ rotate: 360 }}
            transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
          >
            <RiRefreshLine className="w-12 h-12 text-primary" />
          </motion.div>
          <LoadingQuote />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container max-w-4xl mx-auto py-8 px-4">
        <div className="flex flex-col items-center justify-center min-h-[60vh] space-y-4 text-center">
          <RiErrorWarningLine className="w-12 h-12 text-accent" />
          <h2 className="text-xl font-medium text-dark">Something went wrong</h2>
          <p className="text-foggy">{error}</p>
          <button
            onClick={handleRegenerate}
            className="btn-primary"
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }

  if (!mealPlan) {
    return (
      <div className="max-w-4xl mx-auto py-12 text-center">
        <RiCalendarLine className="text-6xl text-foggy mx-auto mb-4" />
        <h2 className="text-2xl font-bold text-dark mb-4">
          No Meal Plan Yet
        </h2>
        <p className="text-foggy mb-8">
          Set your preferences to get started with your personalized meal plan
        </p>
        <div className="flex justify-center">
          <button
            onClick={() => navigate('/preferences')}
            className="btn-primary flex items-center gap-2"
          >
            <RiUserSettingsLine className="text-xl" />
            <span>Set Your Preferences</span>
          </button>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="container max-w-4xl mx-auto pt-0 sm:pt-4 px-4">
        <div className="flex flex-col space-y-8 pb-56">
          {/* Header */}
          <div>
            <div className="flex items-center gap-2.5 sm:gap-3 mb-3 sm:mb-4">
              <RiCalendarLine className="hidden sm:block w-12 h-12 sm:w-16 sm:h-16 text-primary" />
              <div>
                <h1 className="text-2xl sm:text-2xl font-bold text-dark leading-tight">Your Meal Plan</h1>
                <p className="text-sm sm:text-base text-foggy mt-0.5 sm:mt-1">
                  Customize your meal plan by adding or removing meals or swapping meals in the day view then create your shopping list. Each meal serves the number of people you've set in your preferences
                </p>
              </div>
            </div>

            {needsShoppingListUpdate && isFinalized && isModified && (
              <div className="mb-6">
                <div className="p-4 bg-orange-400/10 rounded-lg flex items-start gap-2">
                  <RiInformationLine className="text-orange-400 shrink-0 mt-0.5 text-lg" />
                  <div className="flex-1 min-w-0">
                    <p className="text-sm text-orange-600 leading-relaxed">
                      These meal plan changes will require a change to the shopping list.
                    </p>
                  </div>
                </div>
              </div>
            )}
            
            {needsRevert && (
              <div className="w-full flex items-center justify-between gap-4 p-4 bg-accent/10 text-accent rounded-lg mb-4">
                <div className="flex items-center gap-2">
                  <RiAlertLine className="text-xl" />
                  <span>Preference changes require a new meal plan</span>
                </div>
                <div className="flex items-center gap-2">
                  <button
                    onClick={revertPreferences}
                    className="btn-secondary text-sm"
                  >
                    Revert Changes
                  </button>
                  <button
                    onClick={handleRegenerate}
                    className="btn-primary text-sm"
                  >
                    Generate New Plan
                  </button>
                </div>
              </div>
            )}
          </div>

          {/* Control Panel */}
          <div className="bg-white rounded-lg border border-light p-2.5 mb-4">
            {/* View Controls */}
            <div className="flex flex-col sm:flex-row sm:items-center gap-3">
              <div className="flex-1">
                <div className="inline-flex rounded-lg border border-light p-1 bg-white w-full">
                  <button
                    onClick={() => setViewMode('type')}
                    className={`flex-1 px-2 py-1.5 rounded-md text-xs font-medium transition-colors ${
                      viewMode === 'type' 
                        ? 'border border-primary text-primary bg-primary/5' 
                        : 'text-foggy hover:text-primary'
                    }`}
                  >
                    <span className="flex items-center justify-center gap-1">
                      <RiListUnordered className="text-[14px]" />
                      <span>By Meal</span>
                    </span>
                  </button>
                  <button
                    onClick={() => setViewMode('day')}
                    className={`flex-1 px-2 py-1.5 rounded-md text-xs font-medium transition-colors ${
                      viewMode === 'day' 
                        ? 'border border-primary text-primary bg-primary/5' 
                        : 'text-foggy hover:text-primary'
                    }`}
                  >
                    <span className="flex items-center justify-center gap-1">
                      <RiCalendarLine className="text-[14px]" />
                      <span>By Day</span>
                    </span>
                  </button>
                </div>
              </div>

              <div className="h-4 w-px bg-light hidden sm:block" />

              <div className="flex-1">
                <ViewModeToggle />
              </div>
            </div>
          </div>

          {/* Content */}
          <div className="space-y-6 mb-8">
            {viewMode === 'type' ? (
              <>
                <MealTypeSection type="breakfast" />
                <MealTypeSection type="lunch" />
                <MealTypeSection type="dinner" />
                <MealTypeSection type="snacks" />
              </>
            ) : (
              <DayView />
            )}
          </div>

          {/* Refresh All Button */}
          <div className="flex justify-end mb-16">
            <button
              onClick={() => setShowRefreshModal(true)}
              className="inline-flex items-center gap-1.5 px-4 py-2 text-sm font-medium text-foggy hover:text-primary transition-colors rounded-md border border-light bg-white hover:border-primary/20"
            >
              <RiRefreshLine className="text-lg" />
              <span>Refresh All</span>
            </button>
          </div>
        </div>
      </div>

      {/* Refresh Modal */}
      {showRefreshModal && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            className="bg-white rounded-lg shadow-lg max-w-md w-full p-6 text-center"
          >
            <RiRefreshLine className="mx-auto text-4xl text-primary mb-4" />
            <h3 className="text-xl font-semibold text-dark mb-2">Want a Fresh Mix?</h3>
            <p className="text-foggy mb-6">
              Don't love this combination of meals? Refresh to get a new selection based on your preferences.
            </p>
            <div className="flex flex-col gap-2">
              <button
                onClick={() => {
                  setShowRefreshModal(false);
                  handleRegenerate();
                }}
                className="btn-primary py-2.5"
              >
                Refresh All Meals
              </button>
              <button
                onClick={() => setShowRefreshModal(false)}
                className="btn-secondary py-2"
              >
                Cancel
              </button>
            </div>
          </motion.div>
        </div>
      )}

      {/* Sticky Footer */}
      {needsShoppingListUpdate ? (
        <StickyActions>
          <div className="flex flex-col sm:flex-row gap-2 sm:gap-3 w-full sm:justify-center">
            {isFinalized && isModified && (
              <button
                onClick={cancelModifications}
                className="w-full sm:w-auto btn-secondary flex items-center justify-center gap-2 py-3 px-6 text-base sm:text-base whitespace-nowrap"
              >
                Cancel Changes
              </button>
            )}
            <button
              onClick={handleFinalize}
              className="w-full sm:w-auto btn-primary flex items-center justify-center gap-2 py-3 px-6 text-base sm:text-base whitespace-nowrap"
            >
              <RiShoppingCart2Line className="text-xl" />
              <span>{isFinalized ? 'Update Shopping List' : 'Create Shopping List'}</span>
            </button>
          </div>
        </StickyActions>
      ) : (
        <StickyActions>
          <button
            onClick={() => navigate('/shopping-list')}
            className="w-full btn-primary flex items-center justify-center gap-2 py-3"
          >
            <RiShoppingCart2Line className="text-xl" />
            <span>View Shopping List</span>
          </button>
        </StickyActions>
      )}

      {/* Other modals */}
      {showModifyWarning && (
        <ModificationWarningModal
          isOpen={showModifyWarning}
          onClose={() => setShowModifyWarning(false)}
          onConfirm={() => {
            setShowModifyWarning(false);
            startModifications();
          }}
        />
      )}
    </>
  );
};

export default MealPlan;