import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { usePreferences } from './PreferencesContext';
import { useAuth } from './AuthContext';
import { supabase } from '../config/supabase';
import toast from 'react-hot-toast';
import { RiAlertLine } from 'react-icons/ri';
import { openai } from '../config/openai';
import { useNavigate } from 'react-router-dom';

const MealPlanContext = createContext({});

export const useMealPlan = () => useContext(MealPlanContext);

/**
 * @typedef {Object} MealPlanState
 * @property {Object} mealPlan - The current meal plan
 * @property {Object} mealPlan.days - Array of days with their meals
 * @property {Object} mealPlan.extraMeals - Extra meals for each meal type
 * @property {Object} mealPlan.preferences - User preferences
 * @property {Object} mealPlan.shoppingList - Shopping list categorized by type
 * @property {Object} status - Current state status
 * @property {boolean} status.loading - Whether the state is loading
 * @property {string|null} status.error - Current error message if any
 * @property {boolean} status.hasUnsavedChanges - Whether there are unsaved changes
 * @property {Date|null} status.lastAutoSave - Timestamp of last auto-save
 * @property {boolean} status.isFinalized - Whether the meal plan is finalized
 * @property {boolean} status.canModify - Whether the meal plan can be modified
 * @property {boolean} status.needsShoppingListUpdate - Whether the shopping list needs to be updated
 * @property {Object} status.validationState - Current validation state
 * @property {boolean} status.validationState.isValid - Whether the current state is valid
 * @property {Object.<string, string[]>} status.validationState.mealValidation - Meal-specific validation errors
 * @property {string[]} status.validationState.preferencesValidation - Preference validation errors
 * @property {string[]} status.validationState.shoppingListValidation - Shopping list validation errors
 */

/** @type {MealPlanState} */
const initialState = {
  mealPlan: {
    days: [
      {
        dayName: 'monday',
        meals: {
          breakfast: {
            name: 'No meal assigned',
            description: 'No meal selected for this slot',
            prepTime: '-',
            ingredients: [],
            notes: '',
            isPlaceholder: true
          },
          lunch: {
            name: 'No meal assigned',
            description: 'No meal selected for this slot',
            prepTime: '-',
            ingredients: [],
            notes: '',
            isPlaceholder: true
          },
          dinner: {
            name: 'No meal assigned',
            description: 'No meal selected for this slot',
            prepTime: '-',
            ingredients: [],
            notes: '',
            isPlaceholder: true
          },
          snacks: {
            name: 'No meal assigned',
            description: 'No meal selected for this slot',
            prepTime: '-',
            ingredients: [],
            notes: '',
            isPlaceholder: true
          }
        }
      },
      {
        dayName: 'tuesday',
        meals: {
          breakfast: {
            name: 'No meal assigned',
            description: 'No meal selected for this slot',
            prepTime: '-',
            ingredients: [],
            notes: '',
            isPlaceholder: true
          },
          lunch: {
            name: 'No meal assigned',
            description: 'No meal selected for this slot',
            prepTime: '-',
            ingredients: [],
            notes: '',
            isPlaceholder: true
          },
          dinner: {
            name: 'No meal assigned',
            description: 'No meal selected for this slot',
            prepTime: '-',
            ingredients: [],
            notes: '',
            isPlaceholder: true
          },
          snacks: {
            name: 'No meal assigned',
            description: 'No meal selected for this slot',
            prepTime: '-',
            ingredients: [],
            notes: '',
            isPlaceholder: true
          }
        }
      },
      {
        dayName: 'wednesday',
        meals: {
          breakfast: {
            name: 'No meal assigned',
            description: 'No meal selected for this slot',
            prepTime: '-',
            ingredients: [],
            notes: '',
            isPlaceholder: true
          },
          lunch: {
            name: 'No meal assigned',
            description: 'No meal selected for this slot',
            prepTime: '-',
            ingredients: [],
            notes: '',
            isPlaceholder: true
          },
          dinner: {
            name: 'No meal assigned',
            description: 'No meal selected for this slot',
            prepTime: '-',
            ingredients: [],
            notes: '',
            isPlaceholder: true
          },
          snacks: {
            name: 'No meal assigned',
            description: 'No meal selected for this slot',
            prepTime: '-',
            ingredients: [],
            notes: '',
            isPlaceholder: true
          }
        }
      },
      {
        dayName: 'thursday',
        meals: {
          breakfast: {
            name: 'No meal assigned',
            description: 'No meal selected for this slot',
            prepTime: '-',
            ingredients: [],
            notes: '',
            isPlaceholder: true
          },
          lunch: {
            name: 'No meal assigned',
            description: 'No meal selected for this slot',
            prepTime: '-',
            ingredients: [],
            notes: '',
            isPlaceholder: true
          },
          dinner: {
            name: 'No meal assigned',
            description: 'No meal selected for this slot',
            prepTime: '-',
            ingredients: [],
            notes: '',
            isPlaceholder: true
          },
          snacks: {
            name: 'No meal assigned',
            description: 'No meal selected for this slot',
            prepTime: '-',
            ingredients: [],
            notes: '',
            isPlaceholder: true
          }
        }
      },
      {
        dayName: 'friday',
        meals: {
          breakfast: {
            name: 'No meal assigned',
            description: 'No meal selected for this slot',
            prepTime: '-',
            ingredients: [],
            notes: '',
            isPlaceholder: true
          },
          lunch: {
            name: 'No meal assigned',
            description: 'No meal selected for this slot',
            prepTime: '-',
            ingredients: [],
            notes: '',
            isPlaceholder: true
          },
          dinner: {
            name: 'No meal assigned',
            description: 'No meal selected for this slot',
            prepTime: '-',
            ingredients: [],
            notes: '',
            isPlaceholder: true
          },
          snacks: {
            name: 'No meal assigned',
            description: 'No meal selected for this slot',
            prepTime: '-',
            ingredients: [],
            notes: '',
            isPlaceholder: true
          }
        }
      },
      {
        dayName: 'saturday',
        meals: {
          breakfast: {
            name: 'No meal assigned',
            description: 'No meal selected for this slot',
            prepTime: '-',
            ingredients: [],
            notes: '',
            isPlaceholder: true
          },
          lunch: {
            name: 'No meal assigned',
            description: 'No meal selected for this slot',
            prepTime: '-',
            ingredients: [],
            notes: '',
            isPlaceholder: true
          },
          dinner: {
            name: 'No meal assigned',
            description: 'No meal selected for this slot',
            prepTime: '-',
            ingredients: [],
            notes: '',
            isPlaceholder: true
          },
          snacks: {
            name: 'No meal assigned',
            description: 'No meal selected for this slot',
            prepTime: '-',
            ingredients: [],
            notes: '',
            isPlaceholder: true
          }
        }
      },
      {
        dayName: 'sunday',
        meals: {
          breakfast: {
            name: 'No meal assigned',
            description: 'No meal selected for this slot',
            prepTime: '-',
            ingredients: [],
            notes: '',
            isPlaceholder: true
          },
          lunch: {
            name: 'No meal assigned',
            description: 'No meal selected for this slot',
            prepTime: '-',
            ingredients: [],
            notes: '',
            isPlaceholder: true
          },
          dinner: {
            name: 'No meal assigned',
            description: 'No meal selected for this slot',
            prepTime: '-',
            ingredients: [],
            notes: '',
            isPlaceholder: true
          },
          snacks: {
            name: 'No meal assigned',
            description: 'No meal selected for this slot',
            prepTime: '-',
            ingredients: [],
            notes: '',
            isPlaceholder: true
          }
        }
      }
    ],
    preferences: null,
    shoppingList: null  // Change this to null initially
  },
  status: {
    loading: false,
    error: null,
    hasUnsavedChanges: false,
    lastAutoSave: null,
    isFinalized: false,
    canModify: true,
    needsShoppingListUpdate: true,  // Set this to true initially
    validationState: {
      isValid: true,
      mealValidation: {},
      preferencesValidation: [],
      shoppingListValidation: []
    }
  }
};


const SYSTEM_MESSAGE = `You are a meal-planning expert. Your task is to generate a 7-day meal plan based on the preferences provided. Follow these strict rules to ensure all meals fully comply with the user's allergens and dietary restrictions. Under no circumstances may a meal include any ingredient that violates a specified allergen or dietary restriction.

GENERAL INSTRUCTIONS:
- Only generate meals only for the meal types specified in <mealTypes>.
  - If <mealTypes> is ["breakfast", "dinner"], only produce breakfast and dinner meals.
  - If <mealTypes> includes "all", generate meals for breakfast, lunch, dinner, and snacks.
- Produce exactly 7 unique meals per selected meal type, with no repetition. Each meal MUST be used exactly once.
- Output a final valid JSON object with a "days" array. Each day must contain the selected meal types with their complete details.

DIETARY RESTRICTIONS & ALLERGENS RULES:
1. **Absolute Compliance:**  
   - If any ingredient conflicts with the user's <allergens> or <dietaryRestrictions> (as defined in the Dietary Restrictions and Allergens Guide located at src/utils/substitution-examples.md), it MUST NOT appear in the final output.
2. **Substitution Protocol:**  
   - Only substitute an ingredient if and only if it is explicitly in violation based on the guide.
   - Do not substitute ingredients for restrictions that are not specified.
   - When a substitution is required:
     - Replace only the violating ingredient with a compliant alternative.
     - The substituted ingredient MUST be present in the ingredients array.
     - **MANDATORY NOTES:** Include a "notes" field when substituting an ingredient that explicitly states:
       - The original ingredient that was replaced.
       - The new ingredient used as a substitution.
       - The specific allergen or dietary restriction that necessitated the substitution.
     - Ensure the substituted ingredient does not violate any other specified restriction.
     - Repeat the substitution process until the ingredient list contains only compliant items.
3. **No Unnecessary Changes:**  
   - If <allergens> or <dietaryRestrictions> is empty, do not perform any substitutions or mention allergies in the notes.
   - NEVER create or assume a conflict that is not explicitly specified by the user.

MEAT MEAL AND INGREDIENT PRESERVATION:
- Meat-based meals and ingredients MUST be included by default unless the user's <allergens> or <dietaryRestrictions> explicitly restrict meat (e.g., vegan, vegetarian, pescatarian, or explicit exclusions like chicken, beef, pork).
- The substitution process MUST NOT substitute or remove meat ingredients if they do not conflict with any specified restriction.
- if the user has multiple allergens listed, meat based meals MUST be included (unless the allergen is in the meat ingredient).
- When generating the meal plan, verify that at least one meat-based dish appears each day. If meat-based options are missing, adjust the selection to include a compliant meat-based meal.

FINAL VALIDATION:
- Before outputting the meal plan, perform a final check that:
  - Each selected meal type has exactly 7 unique meals.
  - No meal contains any ingredient that violates dietary restrictions or allergens.
  - No substitutions are made if there are no dietary restrictions or allergens.
  - All substitutions (if any) are correctly applied, with consistent ingredients and notes fields.
  - Each day MUST have all SELECTED meal types filled
  - The final JSON is valid and can be parsed by JSON.parse() without any additional preprocessing.

MEAL PLAN SPECIFIC RULES:
1. **Meal Uniqueness and Variety:**  
   - Generate 7 different unique meals per selected meal type.
   - Each meal is used exactly once per day.
2. **Meal Details:**  
   Each meal must include:
   - name (string)
   - description (string)
   - prepTime (string) – must respect the user's <mealPreparationTime> setting (Quick: ≤30 minutes, Medium: ≤60 minutes, Any: no time limit)
   - ingredients (string[]) – containing the final, compliant ingredients only.
   - notes (string, optional) – **This field MUST be included if any substitution is made, with a clear explanation of the substitution. If no substitution occurs, the "notes" field may be omitted.
   - isPlaceholder (boolean)
3. **Cuisine and Preparation Preferences:**  
   - Follow the user's <cuisinePreferences> (if specified, use only those cuisines; if "Any", use a variety).
   - Ensure meal prep times adhere strictly to the specified <mealPreparationTime>.
4. Include a diverse range of meals to ensure the meal plan is balanced and nutritious while still adhering to all preferences
5. When constructing the meal plan, include meals with shared ingredients as much as possible (to reduce the number of items on the shopping list) while still maintaining meal diversity
6. The meals in the meal plan must follow Preferences:
  - Allergens: <allergens>
  - Dietary Restrictions: <dietaryRestrictions>
  - Cuisine Preferences: <cuisinePreferences>
  - Meal Preparation Time: <mealPreparationTime>
  - Selected Meal Types: <mealTypes>
7. To determine the prep time of meals, follow these rules:
  - Quick: All meals must take 30 minutes or less to prepare and cook
  - Medium: All meals must take 60 minutes or less to prepare and cook
  - Any: meals can take any amount of time to prepare and cook
  

OUTPUT FORMAT:
Return a JSON object structured as follows:
{
  "days": [
    {
      "dayName": "Monday",
      "meals": {
        "breakfast": {
          "name": "Oatmeal with Berries",
          "description": "Creamy oatmeal topped with fresh mixed berries and honey",
          "prepTime": "15 minutes",
          "ingredients": ["oats", "almond milk", "mixed berries", "honey"],
          "notes": "Using almond milk instead of regular milk to accommodate dairy-free restriction",
          "isPlaceholder": false
        },
        "lunch": {...},
        "dinner": {...},
        "snacks": {...}
      }
    }
  ]
}

Remember: The response must be VALID JSON that can be parsed by JSON.parse() without any preprocessing.`;

const EXAMPLE_OUTPUT = {
  days: [
    {
      dayName: "Monday",
      meals: {
        breakfast: {},
        lunch: {},
        dinner: {},
        snacks: {}
      }
    }
  ],
  preferences: {}
};

// Add a simpler getMealsByType function for shopping list generation
const getMealsByTypeForShoppingList = (days) => {
  if (!days || !Array.isArray(days)) {
    return {
      breakfast: [],
      lunch: [],
      dinner: [],
      snacks: []
    };
  }

  const mealTypes = ['breakfast', 'lunch', 'dinner', 'snacks'];
  const byType = {};

  // Initialize meal types
  mealTypes.forEach(type => {
    byType[type] = new Map();
  });

  // Count actual usage in days array ONLY
  days.forEach(day => {
    mealTypes.forEach(type => {
      const meal = day.meals[type];
      // Only include non-placeholder and non-removed meals
      if (!meal.isPlaceholder && !meal.isRemoved) {
        const mealKey = meal.name.toLowerCase();
        if (!byType[type].has(mealKey)) {
          byType[type].set(mealKey, {
            ...meal,
            frequency: 1,
            daysUsed: [day.dayName]
          });
        } else {
          const existingMeal = byType[type].get(mealKey);
          existingMeal.frequency += 1;
          existingMeal.daysUsed.push(day.dayName);
        }
      }
    });
  });

  // Convert to arrays, only including meals that are actually used
  const result = {};
  mealTypes.forEach(type => {
    result[type] = Array.from(byType[type].values())
      // Double-check that the meal has actual usage
      .filter(meal => meal.daysUsed && meal.daysUsed.length > 0);
  });

  return result;
};

// Update the shopping list prompt to enforce JSON response
const SHOPPING_LIST_PROMPT = `As a food prepping and grocery shopping expert, given the meal plan JSON, create a shopping list that includes all ingredients in the correct quantities required to prepare the meals in the meal plan for the number of people indicated in preferences. Group items by category. You also specialize in recipe scaling. 

IMPORTANT: You must respond with ONLY valid JSON that matches this exact format:
{
  "Produce": [{   
    "item": "string",
    "quantity": "string",
    "notes": "string",
    "usedIn": ["MealName (frequencyX)"]  // Array of strings in format "MealName (frequencyX)"
  }],
  "Protein": [...],
  "Dairy": [...],
  "Pantry": [...],
  "Spices": [...],
  "warnings": ["string"] // New field for any allergen or dietary warnings
}
  
Given the meal plan JSON with days and preferences, create a shopping list with items grouped by category.
The meal plan includes frequency information for each meal, and a peopleCount that determines servings.

REQUIREMENTS:
  - The shopping list must include all ingredients from the meal plan.
   - ONLY use ingredients that are explicitly listed in the meal plan
   - DO NOT add or substitute ingredients
   - If an ingredient appears in multiple meals, combine the quantities so that the ingredient is only listed once in the shopping list
  
 CRITICAL QUANTITY RULES:
   1. Base quantities MUST be multiplied by BOTH peopleCount AND meal frequency
    - Example: If a meal needs 2 tomatoes, peopleCount is 2, and meal frequency is 3:
    - Final quantity = 2 (base) × 2 (people) × 3 (frequency) = 12 tomatoes

   2. Use EXACT numeric quantities (no ranges or approximations)
   - CORRECT: "2 cups", "1.5 lbs", "3 each"
   - INCORRECT: "2-3 cups", "a few", "some"
  
   3. Use common units of measure found in grocery stores for each ingredient:"lb", "oz", "cup", "tbsp", "tsp", "package" etc.
   - Count: "total"
   - Weight: "lb", "oz"
   - Volume: "cup", "tbsp", "tsp"
   - Package: "package", "can"
   - Format examples:
     - Produce: "1 lb"
     - Meat/Fish: "1.5 lbs" or "12 oz"
     - Liquids: "2 cups" or "16 oz"
     - Spices: "2 tsp" or "1 tbsp"

   4. Format quantities consistently:
   - Include both number and unit: "2 cups", "1 lb"
   - Use decimals for fractions: "0.5 cup" not "1/2 cup"
   - Round to 2 decimal places maximum
   
   5. Scale ALL ingredients proportionally:
   - Proteins and main ingredients scale directly with servings
   - Spices and seasonings may scale slightly less (use 0.8 factor for 2x+ scaling)
   - Never leave quantities unspecified or approximate

   6. Quantitites of each ingredient must be based on the finalized meal plan (meals and frequencies) and the number of people indicated in the preferences.

  SAFETY CHECKS:
   - Check all ingredients against the preferences.allergens array
   - Check all ingredients against the preferences.dietaryRestrictions array
   - If any ingredients violate these restrictions, add a warning to the warnings array
   - Example warning: "Warning: Ingredient 'shrimp' in 'Seafood Pasta' conflicts with shellfish allergy"
   - Generate the shopping list even if warnings are found

  NOTES field: ONLY include information helpful for the shopper to pick the correct type of ingredients:
     - Form (fresh, frozen, canned)
   - Variety (e.g., roma tomatoes)
   - Ripeness (e.g., ripe)
   - DO NOT include meal usage information

  UsedIn field: List meals using format:
   - Each entry MUST be a string in format "MealName (frequencyX)"
   - For example: ["Chicken Tacos (3x)", "Chicken Salad (2x)"]
   - DO NOT use objects or other formats
   - ALWAYS include the frequency, even if it's (1x)

CATEGORY STANDARDS: Organize items by logical categories as they would be found in a typical grocery store:

- Produce: Fresh fruits and vegetables, organized by:
  - Fresh Vegetables (lettuce, tomatoes, onions, etc.)
  - Fresh Fruits (apples, bananas, berries, etc.)
  - Fresh Herbs (basil, cilantro, parsley, etc.)

- Meat & Seafood: Fresh and packaged proteins
  - Fresh Meat (chicken, beef, pork, etc.)
  - Seafood (fish, shrimp, etc.)
  - Plant-based Proteins (tofu, tempeh, etc.)

- Dairy & Eggs: Refrigerated dairy products
  - Milk & Cream
  - Cheese
  - Yogurt & Cultured Dairy
  - Eggs & Egg Substitutes
  - Butter & Margarine

- Bakery: Fresh and packaged baked goods
  - Bread
  - Rolls & Buns
  - Tortillas & Flatbreads
  - Baked Desserts

- Frozen Foods:
  - Frozen Vegetables
  - Frozen Fruits
  - Frozen Meals
  - Ice Cream & Desserts

- Pantry: Shelf-stable items
  - Pasta & Rice
  - Canned Goods
  - Baking Supplies
  - Cereal & Breakfast
  - Snacks
  - Condiments & Sauces
  - Oils & Vinegars

- Beverages:
  - Water
  - Coffee & Tea
  - Juice
  - Soft Drinks

- Household: Non-food items (if needed)
  - Paper Products
  - Cleaning Supplies
  - Storage & Bags

Items should be categorized based on where they would typically be found in a grocery store, making the shopping experience more efficient. When an item could belong to multiple categories (e.g., fresh pasta could be in either "Pasta & Rice" or refrigerated section), default to the most common location in typical grocery stores.

Remember: Response must be VALID JSON only. No explanations or text outside the JSON structure.

Example response format:
{
  "Produce": [{
    "item": "Tomatoes",
    "quantity": "2 lb",
    "notes": "Roma tomatoes, firm",
    "usedIn": ["Pasta Sauce (2x)", "Taco Night (1x)"]
  }],
  "warnings": ["Warning: Ingredient 'shrimp' in 'Seafood Pasta' conflicts with shellfish allergy"]
}

Remember: The response must be VALID JSON that can be parsed by JSON.parse() without any preprocessing.
Remember: Every quantity MUST be the result of: base_quantity × peopleCount × meal_frequency`


// Add new validation function for unnecessary substitutions
const checkUnnecessarySubstitutions = (meal, preferences) => {
  const warnings = [];
  
  // Skip if no notes field
  if (!meal.notes) return warnings;
  
  // Check for substitution notes when no allergens specified
  if ((!preferences.allergens || preferences.allergens.length === 0) && 
      meal.notes.toLowerCase().includes('allergy')) {
    warnings.push(`Unnecessary allergen substitution in ${meal.name}: No allergens specified but found substitution note`);
  }
  
  // Check for substitution notes when no dietary restrictions specified
  if ((!preferences.dietaryRestrictions || preferences.dietaryRestrictions.length === 0) && 
      /\b(vegan|vegetarian|gluten-free|dairy-free)\b/i.test(meal.notes)) {
    warnings.push(`Unnecessary dietary substitution in ${meal.name}: No dietary restrictions specified but found substitution note`);
  }
  
  return warnings;
};

const validateMealPlan = (mealPlan, preferences) => {
  if (!mealPlan || !preferences) return null;

  const warnings = [];

  // First validate variety level if specified
  if (preferences.varietyLevel) {
    const mealTypes = ['breakfast', 'lunch', 'dinner', 'snacks'];
    
    for (const type of mealTypes) {
      // Get all meals of this type across all days
      const meals = mealPlan.days?.map(day => day?.meals?.[type]).filter(Boolean) || [];
      
      // Count unique meals (excluding placeholders)
      const uniqueMeals = new Set(
        meals
          .filter(meal => meal && !meal.isPlaceholder)
          .map(meal => meal.name)
      ).size;
      
      // Get frequency distribution (excluding placeholders)
      const frequencies = {};
      meals.forEach(meal => {
        if (meal && !meal.isPlaceholder) {
          frequencies[meal.name] = (frequencies[meal.name] || 0) + 1;
        }
      });
      const sortedFrequencies = Object.values(frequencies).sort((a, b) => b - a);

      // Validate based on variety level (now as warnings)
      switch (preferences.varietyLevel.toLowerCase()) {
        case 'low': {
          if (uniqueMeals < 3) {
            warnings.push(`${type} has only ${uniqueMeals} unique meals (recommended minimum is 3 for low variety)`);
          }
          break;
        }
        case 'medium': {
          if (uniqueMeals < 4) {
            warnings.push(`${type} has only ${uniqueMeals} unique meals (recommended minimum is 4 for medium variety)`);
          }
          break;
        }
        case 'high': {
          if (uniqueMeals < 6) {
            warnings.push(`${type} has only ${uniqueMeals} unique meals (recommended minimum is 6 for high variety)`);
          }
          break;
        }
        default:
          warnings.push(`Invalid variety level: ${preferences.varietyLevel}`);
      }
    }
  }

  // Validate allergens and dietary restrictions (now as warnings)
  if (preferences.allergens?.length > 0 || preferences.dietaryRestrictions?.length > 0) {
    mealPlan.days?.forEach(day => {
      if (!day?.meals) return;
      
      Object.entries(day.meals).forEach(([type, meal]) => {
        if (!meal || meal.isPlaceholder) return;
        
        const result = validateMealAgainstPreferences(meal, preferences);
        if (result?.warnings?.length > 0) {
          warnings.push(...result.warnings.map(err => `${day.dayName} ${type}: ${err}`));
        }
      });
    });
  }

  // Always return warnings object, even if empty
  return { warnings };
};

// Add helper function to update meal description based on substitutions
const updateMealWithSubstitutions = (meal, substitutions) => {
  let updatedMeal = { ...meal };
  
  // Update each substitution
  substitutions.forEach(({ original, replacement, reason }) => {
    // Update description
    updatedMeal.description = updatedMeal.description.replace(
      new RegExp(original, 'gi'),
      replacement
    );
    
    // Update ingredients
    updatedMeal.ingredients = updatedMeal.ingredients.map(ingredient =>
      ingredient.toLowerCase() === original.toLowerCase() ? replacement : ingredient
    );
    
    // Update or add note
    if (!updatedMeal.notes) {
      updatedMeal.notes = `Using ${replacement} instead of ${original} due to ${reason}`;
    } else if (!updatedMeal.notes.includes(replacement)) {
      updatedMeal.notes += `; Using ${replacement} instead of ${original} due to ${reason}`;
    }
  });
  
  return updatedMeal;
};

// Add new helper function for synchronizing meal content
const synchronizeMealContent = (meal) => {
  const updatedMeal = { ...meal };
  
  // Extract substitutions from notes if they exist
  if (updatedMeal.notes) {
    const substitutions = [];
    const noteMatches = updatedMeal.notes.matchAll(/Using (.*?) instead of (.*?) due to (.*?)(?:;|$)/g);
    
    for (const match of noteMatches) {
      substitutions.push({
        replacement: match[1],
        original: match[2],
        reason: match[3]
      });
    }

    // Update description and ingredients based on noted substitutions
    substitutions.forEach(({ original, replacement }) => {
      // Update description if it contains the original ingredient
      updatedMeal.description = updatedMeal.description.replace(
        new RegExp(original, 'gi'),
        replacement
      );

      // Update ingredients if they contain the original ingredient
      updatedMeal.ingredients = updatedMeal.ingredients.map(ingredient =>
        ingredient.toLowerCase() === original.toLowerCase() ? replacement : ingredient
      );
    });
  }

  return updatedMeal;
};

// Update validateMealAgainstPreferences to include synchronization
const validateMealAgainstPreferences = (meal, preferences) => {
  const warnings = [];
  let updatedMeal = { ...meal };
  
  if (!meal || !preferences) {
    console.log('Missing meal or preferences');
    return { warnings, updatedMeal };
  }

  // Check for allergens
  if (preferences.allergens?.length > 0) {
    const mealIngredients = updatedMeal.ingredients.map(ing => ing.toLowerCase());
    const mealName = updatedMeal.name.toLowerCase();
    const mealDescription = updatedMeal.description.toLowerCase();
    const mealNotes = updatedMeal.notes?.toLowerCase() || '';

    preferences.allergens.forEach(allergen => {
      const normalizedAllergen = allergen.toLowerCase();
      const hasAllergen = mealIngredients.some(ing => ing.includes(normalizedAllergen)) ||
                         mealName.includes(normalizedAllergen) ||
                         mealDescription.includes(normalizedAllergen);
      
      if (hasAllergen) {
        warnings.push(`Contains allergen (${allergen})`);
      }
    });
  }

  // Check for dietary restrictions
  if (preferences.dietaryRestrictions?.length > 0) {
    preferences.dietaryRestrictions.forEach(restriction => {
      const normalizedRestriction = restriction.toLowerCase();
      let violatesRestriction = false;

      switch (normalizedRestriction) {
        case 'vegetarian':
          if (/(chicken|beef|pork|fish|meat)/i.test(JSON.stringify(meal))) {
            warnings.push(`Contains non-vegetarian ingredients`);
          }
          break;
        case 'vegan':
          if (/(chicken|beef|pork|fish|meat|milk|cheese|egg|honey)/i.test(JSON.stringify(meal))) {
            warnings.push(`Contains non-vegan ingredients`);
          }
          break;
        case 'gluten-free':
          if (/(wheat|bread|pasta|flour)/i.test(JSON.stringify(meal))) {
            warnings.push(`May contain gluten`);
          }
          break;
        // Add more dietary restrictions as needed
      }
    });
  }

  return {
    warnings,
    updatedMeal
  };
};

// Helper function to get dairy substitutes
const getDairySubstitute = (ingredient) => {
  const normalized = ingredient.toLowerCase();
  if (/(milk|cream)/.test(normalized)) return 'almond milk';
  if (/(cheese|feta|mozzarella|parmesan)/.test(normalized)) return 'hummus';
  if (/yogurt/.test(normalized)) return 'coconut yogurt';
  if (/butter/.test(normalized)) return 'olive oil';
  return null;
};

const validateShoppingList = (shoppingList, mealPlan, preferences) => {
  const errors = [];
  const warnings = [];
  const stats = {
    totalItems: 0,
    itemsWithQuantity: 0,
    itemsWithUnit: 0,
    categories: new Set(),
    quantityIssues: []
  };

  // Track meal frequencies for validation
  const mealFrequencies = new Map();
  mealPlan.days.forEach(day => {
    Object.values(day.meals).forEach(meal => {
      if (meal && !meal.isPlaceholder) {
        mealFrequencies.set(meal.name, (mealFrequencies.get(meal.name) || 0) + 1);
      }
    });
  });

  const peopleCount = preferences?.peopleCount || 1;

  // Validate structure and categories
  if (!shoppingList || typeof shoppingList !== 'object') {
    errors.push('Shopping list must be an object');
    return { errors, warnings, stats };
  }

  // Define expected units
  const validUnits = new Set([
    'each', 'piece',
    'lb', 'oz',
    'cup', 'tbsp', 'tsp',
    'package', 'can'
  ]);

  // Validate each category
  Object.entries(shoppingList).forEach(([category, items]) => {
    stats.categories.add(category);

    if (!Array.isArray(items)) {
      errors.push(`Category ${category} must contain an array of items`);
      return;
    }
    
    items.forEach((item, index) => {
      stats.totalItems++;

      // Basic item validation
      if (!item || typeof item !== 'object') {
        errors.push(`Invalid item at index ${index} in ${category}`);
        return;
      }
      
      // Required fields check
      if (!item.item || !item.quantity) {
        errors.push(`Missing required fields in item at index ${index} in ${category}`);
        return;
      }

      // Quantity format validation
      const quantityMatch = item.quantity.match(/^(\d+\.?\d*)\s+([a-zA-Z]+)$/);
      if (!quantityMatch) {
        stats.quantityIssues.push({
          item: item.item,
          quantity: item.quantity,
          issue: 'Invalid quantity format'
        });
            } else {
        const [_, amount, unit] = quantityMatch;
        stats.itemsWithQuantity++;
        
        // Validate unit
        if (!validUnits.has(unit.toLowerCase())) {
          stats.quantityIssues.push({
            item: item.item,
            quantity: item.quantity,
            issue: 'Invalid unit'
          });
        } else {
          stats.itemsWithUnit++;
        }

        // Check for suspiciously small or large quantities
        const numericAmount = parseFloat(amount);
        if (numericAmount < 0.1 || numericAmount > 100) {
          stats.quantityIssues.push({
            item: item.item,
            quantity: item.quantity,
            issue: 'Suspicious quantity amount'
          });
        }
      }

      // Validate usedIn and check quantity scaling
      if (item.usedIn && Array.isArray(item.usedIn)) {
        item.usedIn.forEach(usage => {
          const match = usage.match(/^(.+)\s+\((\d+)x\)$/);
          if (match) {
            const [_, mealName, frequency] = match;
            const actualFrequency = mealFrequencies.get(mealName) || 0;
            
            // Verify the frequency matches the meal plan
            if (parseInt(frequency) !== actualFrequency) {
              warnings.push(`Incorrect frequency for ${item.item} in ${mealName}: shows ${frequency}x but actual is ${actualFrequency}x`);
            }

            // Check if quantity seems proportional to frequency and people count
            const expectedScaling = actualFrequency * peopleCount;
            const quantityMatch = item.quantity.match(/^(\d+\.?\d*)/);
            if (quantityMatch) {
              const quantity = parseFloat(quantityMatch[1]);
              // Allow for some variation in spices (0.8 factor) but other ingredients should scale directly
              const isSpice = category === 'Spices';
              const scalingFactor = isSpice ? 0.8 : 1.0;
              const expectedMinQuantity = scalingFactor * expectedScaling;
              
              if (quantity < expectedMinQuantity) {
                stats.quantityIssues.push({
                  item: item.item,
                  quantity: item.quantity,
                  issue: `Quantity may be too low for ${peopleCount} people and ${actualFrequency}x frequency`
                });
              }
            }
          } else {
            warnings.push(`Invalid usedIn format for ${item.item}: ${usage}`);
          }
        });
      }
    });
  });

  // Log validation results
  console.group('Shopping List Validation');
  console.log('Stats:', stats);
  console.log('Warnings:', warnings);
  console.log('Errors:', errors);
  console.groupEnd();

  return { errors, warnings, stats };
}

// Add a new function to normalize ingredient names
const normalizeIngredient = (ingredient) => {
  const normalized = ingredient.toLowerCase().trim();
  
  // Handle common variations
  const variations = {
    // Eggs
    'egg': 'eggs',
    'whole egg': 'eggs',
    'fresh egg': 'eggs',
    'large egg': 'eggs',
    // Common produce
    'fresh tomato': 'tomatoes',
    'tomato': 'tomatoes',
    'fresh banana': 'bananas',
    'banana': 'bananas',
    // Common proteins
    'chicken breast': 'chicken breasts',
    'chicken thigh': 'chicken thighs',
    // Common measurements
    'lb': 'lbs',
    'pound': 'lbs',
    'pounds': 'lbs',
    'ounce': 'oz',
    'ounces': 'oz',
  };

  return variations[normalized] || normalized;
};

// Update standardizeQuantity to use more grocery-store friendly units
const standardizeQuantity = (quantity) => {
  // Common unit conversions and thresholds
  const conversions = {
    // Weight
    'g': { to: 'lbs', factor: 0.00220462, threshold: 453.59237 }, // 1 lb = 453.59237g
    'gram': { to: 'lbs', factor: 0.00220462, threshold: 453.59237 },
    'grams': { to: 'lbs', factor: 0.00220462, threshold: 453.59237 },
    'kg': { to: 'lbs', factor: 2.20462, threshold: 0.453592 },
    'oz': { to: 'lbs', factor: 0.0625, threshold: 16 }, // Convert to lbs if >= 16 oz
    'ounce': { to: 'lbs', factor: 0.0625, threshold: 16 },
    'ounces': { to: 'lbs', factor: 0.0625, threshold: 16 },
    // Volume
    'ml': { to: 'cups', factor: 0.00422675, threshold: 236.588 }, // 1 cup = 236.588ml
    'milliliter': { to: 'cups', factor: 0.00422675, threshold: 236.588 },
    'milliliters': { to: 'cups', factor: 0.00422675, threshold: 236.588 },
    'l': { to: 'cups', factor: 4.22675, threshold: 0.236588 },
    'liter': { to: 'cups', factor: 4.22675, threshold: 0.236588 },
    'liters': { to: 'cups', factor: 4.22675, threshold: 0.236588 }
  };

  // Extract number and unit from quantity string
  const match = quantity.match(/^(\d*\.?\d+)\s*(.*)$/);
  if (!match) return quantity;

  const [_, amount, unit] = match;
  const normalizedUnit = unit.toLowerCase().trim();
  const numericAmount = parseFloat(amount);

  // Handle special cases for common grocery items
  if (/(chicken|meat|beef|pork|fish)/i.test(normalizedUnit)) {
    const lbs = Math.round(numericAmount / 453.59237 * 100) / 100;
    return `${lbs} lbs`;
  }

  // Convert if we have a known conversion
  const conversion = conversions[normalizedUnit];
  if (conversion && numericAmount >= conversion.threshold) {
    const convertedAmount = numericAmount * conversion.factor;
      // Round to 2 decimal places for weight, 1 for volume
      const roundedAmount = conversion.to === 'lbs' 
        ? Math.round(convertedAmount * 100) / 100
        : Math.round(convertedAmount * 10) / 10;
      return `${roundedAmount} ${conversion.to}`;
  }

  // Format common measurements
  if (['piece', 'pieces', 'count', 'whole', 'wholes'].includes(normalizedUnit)) {
    return numericAmount === 1 ? '1 piece' : `${numericAmount} pieces`;
  }

  // Handle small measurements
  if (numericAmount < 0.25 && !/(tsp|tbsp)/i.test(normalizedUnit)) {
    return `${Math.round(numericAmount * 48)} tsp`;
  } else if (numericAmount < 1 && !/(tsp|tbsp)/i.test(normalizedUnit)) {
    return `${Math.round(numericAmount * 16)} tbsp`;
  }

  // Round to reasonable numbers
  const roundedAmount = Math.round(numericAmount * 100) / 100;
  return `${roundedAmount} ${unit}`;
};

const calculateQuantity = (baseQuantity, peopleCount, frequency = 1) => {
  // Extract number and unit from quantity string
  const match = baseQuantity.match(/^(\d*\.?\d+)\s*(.*)$/);
  if (!match) return baseQuantity;

  const [_, amount, unit] = match;
  const baseAmount = parseFloat(amount);
  const totalAmount = baseAmount * peopleCount * frequency;

  // Format the quantity using standardized measurements
  return standardizeQuantity(`${totalAmount} ${unit}`);
};

const combineQuantities = (quantities) => {
  // Group by normalized unit
  const unitGroups = quantities.reduce((groups, quantity) => {
    const match = quantity.match(/^(\d*\.?\d+)\s*(.*)$/);
    if (!match) return groups;

    const [_, amount, unit] = match;
    const normalizedUnit = unit.toLowerCase().trim();
    
    // Convert common unit variations
    let standardUnit = normalizedUnit;
    if (['g', 'gram', 'grams'].includes(normalizedUnit)) standardUnit = 'g';
    if (['oz', 'ounce', 'ounces'].includes(normalizedUnit)) standardUnit = 'oz';
    if (['lb', 'lbs', 'pound', 'pounds'].includes(normalizedUnit)) standardUnit = 'lb';
    if (['cup', 'cups', 'c'].includes(normalizedUnit)) standardUnit = 'cup';
    if (['tbsp', 'tablespoon', 'tablespoons'].includes(normalizedUnit)) standardUnit = 'tbsp';
    if (['tsp', 'teaspoon', 'teaspoons'].includes(normalizedUnit)) standardUnit = 'tsp';
    if (['piece', 'pieces', 'count'].includes(normalizedUnit)) standardUnit = 'piece';
    if (['whole', 'wholes'].includes(normalizedUnit)) standardUnit = 'whole';
    
    if (!groups[standardUnit]) {
      groups[standardUnit] = 0;
    }
    groups[standardUnit] += parseFloat(amount);
    return groups;
  }, {});

  // Format each group with proper units
  return Object.entries(unitGroups)
    .map(([unit, total]) => {
      // Round to 2 decimal places for most units, 1 for volume
      const isVolume = ['cup', 'tbsp', 'tsp'].includes(unit);
      const roundedTotal = isVolume 
        ? Math.round(total * 10) / 10 
        : Math.round(total * 100) / 100;
      
      // Format based on unit type
      switch(unit) {
        case 'g':
          return roundedTotal >= 1000 
            ? `${roundedTotal / 1000} kg`
            : `${roundedTotal} g`;
        case 'oz':
          return roundedTotal >= 16
            ? `${Math.round(roundedTotal * 0.0625 * 100) / 100} lbs`
            : `${roundedTotal} oz`;
        case 'lb':
          return `${roundedTotal} ${roundedTotal === 1 ? 'lb' : 'lbs'}`;
        case 'cup':
          return `${roundedTotal} ${roundedTotal === 1 ? 'cup' : 'cups'}`;
        case 'tbsp':
          return roundedTotal >= 16 
            ? `${Math.floor(roundedTotal / 16)} cup${roundedTotal >= 32 ? 's' : ''} ${roundedTotal % 16 > 0 ? `+ ${Math.round(roundedTotal % 16)} tbsp` : ''}`
            : `${roundedTotal} tbsp`;
        case 'tsp':
          if (roundedTotal >= 48) { // 16 tbsp = 48 tsp = 1 cup
            const cups = Math.floor(roundedTotal / 48);
            const remainingTbsp = Math.floor((roundedTotal % 48) / 3);
            const remainingTsp = Math.round(roundedTotal % 3);
            return `${cups} cup${cups > 1 ? 's' : ''}${remainingTbsp > 0 ? ` + ${remainingTbsp} tbsp` : ''}${remainingTsp > 0 ? ` + ${remainingTsp} tsp` : ''}`;
          } else if (roundedTotal >= 3) { // 3 tsp = 1 tbsp
            const tbsp = Math.floor(roundedTotal / 3);
            const remainingTsp = Math.round(roundedTotal % 3);
            return `${tbsp} tbsp${remainingTsp > 0 ? ` + ${remainingTsp} tsp` : ''}`;
          }
          return `${roundedTotal} tsp`;
        case 'piece':
          return roundedTotal === 1 ? '1 piece' : `${roundedTotal} pieces`;
        case 'whole':
          return roundedTotal === 1 ? '1 whole' : `${roundedTotal} whole`;
        default:
          return `${roundedTotal} ${unit}`;
      }
    })
    .join(' + ');
};

// Update validateIngredientSafety to be more thorough
const validateIngredientSafety = (ingredient, preferences) => {
  const normalizedIngredient = ingredient.toLowerCase();
  
  // Check allergens more thoroughly
  if (preferences.allergens?.some(allergen => {
    const normalizedAllergen = allergen.toLowerCase();
    // Check for exact match or common variations
    return normalizedIngredient.includes(normalizedAllergen) ||
           (normalizedAllergen === 'eggs' && /(^|\s)(egg|eggs)(\s|$)/i.test(ingredient)) ||
           (normalizedAllergen === 'dairy' && /(milk|cheese|cream|butter|yogurt)/i.test(ingredient)) ||
           (normalizedAllergen === 'nuts' && /(nut|almond|cashew|pecan|walnut)/i.test(ingredient)) ||
           (normalizedAllergen === 'shellfish' && /(shrimp|crab|lobster|shellfish)/i.test(ingredient));
  })) {
    throw new Error(`Unsafe ingredient (allergen): ${ingredient}`);
  }

  // Check dietary restrictions more thoroughly
  if (preferences.dietaryRestrictions?.includes('vegan') && 
      /(milk|cheese|cream|butter|egg|honey|meat|fish|chicken|beef|pork)/i.test(normalizedIngredient)) {
    throw new Error(`Unsafe ingredient (vegan restriction): ${ingredient}`);
  }
  
  if (preferences.dietaryRestrictions?.includes('vegetarian') && 
      /(chicken|beef|pork|fish|meat|gelatin)/i.test(normalizedIngredient)) {
    throw new Error(`Unsafe ingredient (vegetarian restriction): ${ingredient}`);
  }

  return true;
};

// Update generateShoppingList to include safety checks and ensure consistency
const generateShoppingList = (mealPlan, preferences) => {
  console.log('[generateShoppingList]', { input: { mealPlan, preferences } });
  console.group('Shopping List Generation');
  console.log('Input meal plan:', mealPlan);
  console.log('Input preferences:', preferences);

  if (!mealPlan || !mealPlan.days) {
    console.warn('No meal plan or days array provided');
    console.groupEnd();
    return null;
  }

  const peopleCount = preferences?.peopleCount || 1;
  const mealsByType = getMealsByTypeForShoppingList(mealPlan.days);
  console.log('Meals organized by type:', mealsByType);

  const ingredientMap = new Map();

  // Process each meal type
  Object.entries(mealsByType).forEach(([type, meals]) => {
    console.log(`Processing ${type} meals:`, meals);
    meals.forEach(meal => {
      console.log(`Processing meal "${meal.name}":`, {
        hasIngredients: !!meal.ingredients,
        isPlaceholder: meal.isPlaceholder,
        frequency: meal.frequency
      });
      // Skip if meal has no ingredients or is a placeholder
      if (!meal.ingredients || meal.isPlaceholder) {
        console.log(`Skipping meal "${meal.name}": ${!meal.ingredients ? 'no ingredients' : 'is placeholder'}`);
        return;
      }
      
      // Skip if meal is not actually used in the plan (double check)
      if (!meal.daysUsed || meal.daysUsed.length === 0) {
        console.log(`Skipping meal "${meal.name}": not used in any days`);
        return;
      }

      console.log(`Processing ingredients for ${meal.name} (used ${meal.daysUsed.length} times):`, meal.ingredients);

      meal.ingredients.forEach(ingredient => {
        const normalizedIngredient = normalizeIngredient(ingredient);
        if (!ingredientMap.has(normalizedIngredient)) {
          ingredientMap.set(normalizedIngredient, {
            item: normalizedIngredient,
            quantity: calculateQuantity(1, peopleCount, meal.daysUsed.length),
            usedIn: [`${meal.name} (${meal.daysUsed.length}x)`]
          });
        } else {
          const value = ingredientMap.get(normalizedIngredient);
          value.quantity = combineQuantities([
            value.quantity,
            calculateQuantity(1, peopleCount, meal.daysUsed.length)
          ]);
          value.usedIn.push(`${meal.name} (${meal.daysUsed.length}x)`);
        }
      });
    });
  });

  console.log('Final ingredient map:', Object.fromEntries(ingredientMap));

  // Convert to categorized format
  const result = {};
  ingredientMap.forEach((value) => {
    // Only include ingredients that are actually used
    if (value.usedIn.length > 0) {
      const category = determineCategory(value.item);
      if (!result[category]) {
        result[category] = [];
      }
      result[category].push(value);
    }
  });

  console.log('Final categorized shopping list:', result);
  console.groupEnd();
  console.log('[generateShoppingList] Generated list:', result);
  return result;
};

/**
 * Determines the category for an ingredient
 */
const determineCategory = (ingredient) => {
  const normalized = ingredient.toLowerCase().trim();
  
  // Produce patterns
  const producePatterns = [
    // Fresh Vegetables
    /lettuce|spinach|kale|arugula|cabbage/,
    /tomato|cucumber|carrot|celery|pepper/,
    /onion|garlic|shallot|leek|ginger/,
    /potato|sweet potato|yam|squash|zucchini/,
    /broccoli|cauliflower|asparagus|brussels/,
    /mushroom|eggplant|radish|turnip/,
    // Fresh Fruits
    /apple|orange|banana|berry|fruit/,
    /melon|grape|peach|plum|pear|mango/,
    /lemon|lime|citrus|avocado/,
    // Fresh Herbs
    /basil|cilantro|parsley|mint|dill|thyme|rosemary/
  ];

  // Meat & Seafood patterns
  const meatSeafoodPatterns = [
    // Fresh Meat
    /chicken|turkey|beef|pork|lamb|veal/,
    /meat|steak|roast|chop|ground|sausage/,
    // Seafood
    /fish|salmon|tuna|shrimp|seafood|crab|lobster/,
    // Plant-based Proteins
    /tofu|tempeh|seitan|plant.based protein/
  ];

  // Dairy & Eggs patterns
  const dairyEggsPatterns = [
    // Milk & Cream
    /milk|cream|half.and.half/,
    // Cheese
    /cheese|mozzarella|cheddar|parmesan/,
    // Yogurt & Cultured
    /yogurt|sour cream|cottage cheese|ricotta/,
    // Eggs
    /egg|egg substitute/,
    // Butter
    /butter|margarine|ghee/
  ];

  // Bakery patterns
  const bakeryPatterns = [
    /bread|roll|bun|bagel|muffin/,
    /tortilla|pita|flatbread|naan/,
    /croissant|pastry|cake|cookie/
  ];

  // Frozen Foods patterns
  const frozenPatterns = [
    /frozen/,
    /ice cream|gelato|sorbet/,
    /frozen.+vegetable|frozen.+fruit/,
    /frozen.+meal|frozen.+pizza/
  ];

  // Pantry patterns
  const pantryPatterns = [
    // Pasta & Rice
    /pasta|spaghetti|noodle|rice|grain|quinoa/,
    // Canned Goods
    /canned|can of|soup|broth|stock/,
    // Baking
    /flour|sugar|baking|yeast|extract/,
    // Breakfast
    /cereal|oatmeal|pancake|syrup/,
    // Snacks
    /chip|cracker|popcorn|nut|seed/,
    // Condiments
    /sauce|dressing|mayonnaise|mustard|ketchup/,
    /oil|vinegar|seasoning|spice|herb/
  ];

  // Beverages patterns
  const beveragePatterns = [
    /water|coffee|tea|juice/,
    /soda|soft drink|beverage/
  ];

  // Household patterns
  const householdPatterns = [
    /paper|napkin|towel/,
    /cleaning|detergent|soap/,
    /storage|bag|container/
  ];

  // Check each category
  for (const pattern of producePatterns) {
    if (pattern.test(normalized)) return 'Produce';
  }

  for (const pattern of meatSeafoodPatterns) {
    if (pattern.test(normalized)) return 'Meat & Seafood';
  }

  for (const pattern of dairyEggsPatterns) {
    if (pattern.test(normalized)) return 'Dairy & Eggs';
  }

  for (const pattern of bakeryPatterns) {
    if (pattern.test(normalized)) return 'Bakery';
  }

  for (const pattern of frozenPatterns) {
    if (pattern.test(normalized)) return 'Frozen Foods';
  }

  for (const pattern of pantryPatterns) {
    if (pattern.test(normalized)) return 'Pantry';
  }

  for (const pattern of beveragePatterns) {
    if (pattern.test(normalized)) return 'Beverages';
  }

  for (const pattern of householdPatterns) {
    if (pattern.test(normalized)) return 'Household';
  }

  // Default to Pantry if no other category matches
  return 'Pantry';
};

// Helper function to detect significant preference changes
function detectSignificantChanges(currentMealPlan, previousMealPlan, preferences) {
  const changes = {
    requiresAI: false,
    types: [], // Changed from single type to array of types
    details: {
      allergens: [],
      restrictions: [],
      peopleCount: { from: 1, to: 1 },
      recipeChanges: [] // Added to track recipe modifications
    }
  };

  // Check for allergen changes that require filtering
  if (preferences.allergens?.length > 0) {
    const hasAllergenConflict = Object.values(currentMealPlan.byType).some(meals =>
      meals.some(meal =>
        meal.ingredients?.some(ingredient =>
          preferences.allergens.some(allergen =>
            ingredient.toLowerCase().includes(allergen.toLowerCase())
          )
        )
      )
    );

    if (hasAllergenConflict) {
      changes.requiresAI = true;
      changes.types.push('FILTER_ALLERGENS');
      changes.details.allergens = preferences.allergens;
    }
  }

  // Check for dietary restriction changes
  if (preferences.dietaryRestrictions?.length > 0) {
    const hasDietaryConflict = Object.values(currentMealPlan.byType).some(meals =>
      meals.some(meal =>
        !meal.dietaryInfo?.every(info =>
          preferences.dietaryRestrictions.includes(info)
        )
      )
    );

    if (hasDietaryConflict) {
      changes.requiresAI = true;
      changes.types.push('ADJUST_DIETARY');
      changes.details.restrictions = preferences.dietaryRestrictions;
    }
  }

  // Check for people count changes
  if (previousMealPlan && 
      preferences.peopleCount !== previousMealPlan.preferences?.peopleCount) {
    changes.requiresAI = true;
    changes.types.push('ADJUST_QUANTITIES');
    changes.details.peopleCount = {
      from: previousMealPlan.preferences?.peopleCount || 1,
      to: preferences.peopleCount || 1
    };
  }

  // Check for recipe modifications
  if (previousMealPlan?.byType) {
    const recipeChanges = [];
    Object.entries(currentMealPlan.byType || {}).forEach(([type, meals]) => {
      const previousMeals = previousMealPlan.byType[type] || [];
      meals.forEach(meal => {
        const prevMeal = previousMeals.find(m => m.name === meal.name);
        if (prevMeal) {
          const changedIngredients = meal.ingredients?.filter(
            ing => !prevMeal.ingredients?.includes(ing)
          );
          if (changedIngredients?.length > 0) {
            recipeChanges.push({
              mealName: meal.name,
              type,
              changedIngredients,
              frequency: meal.frequency
            });
          }
        }
      });
    });

    if (recipeChanges.length > 0) {
      changes.requiresAI = true;
      changes.types.push('RECIPE_CHANGES');
      changes.details.recipeChanges = recipeChanges;
    }
  }

  return changes;
}

// Helper function to process changes with AI
async function processWithAI({ tasks, currentList, changes, mealPlan, preferences }) {
  const safeChanges = {
    allergens: [],
    restrictions: [],
    peopleCount: { from: 1, to: 1 },
    recipeChanges: [],
    ...changes
  };

  // Combine all relevant prompts based on the types of changes
  const prompts = {
    FILTER_ALLERGENS: `Allergens to avoid: ${safeChanges.allergens.join(', ') || 'None specified'}`,
    ADJUST_DIETARY: `Dietary restrictions: ${safeChanges.restrictions.join(', ') || 'None specified'}`,
    ADJUST_QUANTITIES: `Scaling from ${safeChanges.peopleCount.from || 1} to ${safeChanges.peopleCount.to || 1} people`,
    RECIPE_CHANGES: safeChanges.recipeChanges.map(change => 
      `- ${change.mealName}: Changed ingredients: ${change.changedIngredients.join(', ')} (${change.frequency}x per week)`
    ).join('\n')
  };

  // Combine all relevant prompts
  const combinedPrompt = tasks
    .map(task => `${task}:\n${prompts[task]}`)
    .join('\n\n');

  try {
    const shoppingListMessages = [
      { role: 'user', content: SHOPPING_LIST_PROMPT }
    ];

    console.group('Shopping List Generation');
    console.log('Message roles:', shoppingListMessages.map(m => ({ role: m.role })));
    console.groupEnd();

    const completion = await openai.chat.completions.create({
      model: "gpt-4o-mini",
      messages: shoppingListMessages,
      temperature: 0.3
    });

    const content = completion.choices[0].message.content.trim();
    console.log('Response received, parsing JSON...');
    
    const jsonStr = content.replace(/```json\n?|\n?```/g, '').trim();
    
    try {
      return JSON.parse(jsonStr);
    } catch (parseError) {
      console.error('Failed to parse JSON response:', parseError);
      throw new Error('Invalid JSON response from AI');
    }
  } catch (error) {
    console.error('AI processing failed:', error);
    throw error;
  }
}

/**
 * Derives the by-type view from the days array.
 * This is the single bridging function for getting type-based views of the meal plan.
 */
const getMealsByType = (days) => {
  if (!days || !Array.isArray(days)) {
    return {
      breakfast: [],
      lunch: [],
      dinner: [],
      snacks: []
    };
  }

  const mealTypes = ['breakfast', 'lunch', 'dinner', 'snacks'];
  const byType = {};

  // Initialize meal types with Maps to track unique meals
  mealTypes.forEach(type => {
    byType[type] = new Map();
  });

  // First, get all meals from allMeals (if it exists)
  if (state?.mealPlan?.allMeals) {
    mealTypes.forEach(type => {
      const allMealsOfType = state.mealPlan.allMeals[type];
      if (allMealsOfType && allMealsOfType instanceof Map) {
        allMealsOfType.forEach((meal, mealName) => {
          byType[type].set(mealName, {
            ...meal,
            frequency: 0,
            daysUsed: []
          });
        });
      }
    });
  }

  // Then update frequencies from current days
  days.forEach((day, dayIndex) => {
    if (!day?.meals) return;
    
    mealTypes.forEach(type => {
      if (!day.meals[type]) return;
      
      const meal = day.meals[type];
      // Skip placeholder meals but include removed meals in the count
      if (meal && !meal.isPlaceholder && meal.name) {
        if (!byType[type].has(meal.name)) {
          // If somehow not in allMeals, add it
          byType[type].set(meal.name, {
            ...meal,
            frequency: 1,
            daysUsed: [{ dayName: day.dayName, dayIndex }]
          });
        } else {
          // Update existing meal's frequency
          const existingMeal = byType[type].get(meal.name);
          if (existingMeal) {
            existingMeal.frequency += 1;
            existingMeal.daysUsed.push({ dayName: day.dayName, dayIndex });
          }
        }
      }
    });
  });

  // Convert Maps to arrays, maintaining original order from allMeals
  const result = {};
  mealTypes.forEach(type => {
    if (state?.mealPlan?.allMeals?.[type]) {
      // Use original order from allMeals
      result[type] = Array.from(state.mealPlan.allMeals[type].keys())
        .map(mealName => byType[type].get(mealName))
        .filter(Boolean);
    } else {
      // Fallback to current order if allMeals not available
      result[type] = Array.from(byType[type].values());
    }
  });

  return result;
};

// Add new helper function for formatting dates
const formatDateForMealPlan = (date = new Date()) => {
  return `Week of ${date.toLocaleDateString('en-US', { 
    month: 'long', 
    day: 'numeric',
    year: 'numeric'
  })}`;
};

export const MealPlanProvider = ({ children, initialState }) => {
  const [state, setState] = useState(() => ({
    mealPlan: initialState?.mealPlan || null,
    lastFinalizedMealPlan: null,
    status: {
      loading: false,
      error: null,
      hasUnsavedChanges: false,
      isFinalized: false,
      canModify: true,
      needsShoppingListUpdate: false,
      isUpdatingShoppingList: false,
      hasBeenSaved: false,
      activeMealPlanId: null,  // Add this to track active plan
      validationState: {
        isValid: true,
        warnings: []
      }
    }
  }));
  const { preferences, isModifiedSinceMealPlan, submitPreferences, setPreferences } = usePreferences();
  const { user } = useAuth();
  const [shoppingList, setShoppingList] = useState(null);
  const [isGeneratingShoppingList, setIsGeneratingShoppingList] = useState(false);
  const [isFinalized, setIsFinalized] = useState(false);
  const [canModify, setCanModify] = useState(true);
  const navigate = useNavigate();

  // Add new state for saved meal plans
  const [savedMealPlans, setSavedMealPlans] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Add effect to load saved meal plans when user changes
  useEffect(() => {
    if (user) {
      getSavedMealPlans();
    } else {
      setSavedMealPlans([]);
    }
  }, [user]);

  // Add new methods for saved meal plans
  const saveMealPlan = useCallback(async (name = '') => {
    if (!user || !state.mealPlan) return null;
    
    setIsSaving(true);
    try {
      console.log('Saving meal plan:', {
        userId: user.id,
        name,
        hasShoppingList: !!state.mealPlan.shoppingList
      });

      const mealPlanToSave = {
        user_id: user.id,
        name: name || 'My Meal Plan',
        preferences: preferences,
        plan: {
          days: state.mealPlan.days,
          shoppingList: state.mealPlan.shoppingList
        }
      };

      const { data, error } = await supabase
        .from('meal_plans')
        .insert([mealPlanToSave])
        .select()
        .single();

      if (error) throw error;

      console.log('Meal plan saved successfully:', data);
      setSavedMealPlans(prev => [...prev, data]);
      // Remove toast from here as it will be handled by the calling functions
      return data;
    } catch (error) {
      console.error('Error saving meal plan:', error);
      toast.error('Failed to save meal plan');
      return null;
    } finally {
      setIsSaving(false);
    }
  }, [user, state.mealPlan, preferences]);

  const getSavedMealPlans = useCallback(async () => {
    if (!user) {
      console.log('No user found, returning empty array');
      return [];
    }
    
    setIsLoading(true);
    try {
      console.log('Fetching saved meal plans for user:', user.id);
      const { data, error } = await supabase
        .from('meal_plans')
        .select('*')
        .eq('user_id', user.id)
        .order('created_at', { ascending: false });

      if (error) {
        console.error('Supabase error:', error);
        throw error;
      }

      console.log('Fetched meal plans:', data);
      setSavedMealPlans(data || []);
      return data || [];
    } catch (error) {
      console.error('Error fetching saved meal plans:', error);
      toast.error('Failed to fetch saved meal plans');
      setSavedMealPlans([]);
      return [];
    } finally {
      setIsLoading(false);
    }
  }, [user]);

  const loadSavedMealPlan = useCallback(async (id) => {
    if (!user) return;
    
    setIsLoading(true);
    try {
      const { data, error } = await supabase
        .from('meal_plans')
        .select('*')
        .eq('id', id)
        .single();

      if (error) throw error;

      // Update the meal plan state with all necessary data
      setState(prev => ({
        ...prev,
        mealPlan: {
          days: data.plan.days,
          shoppingList: data.plan.shoppingList,
          preferences: data.preferences
        },
        status: {
          ...prev.status,
          hasUnsavedChanges: false,
          needsShoppingListUpdate: false,
          isFinalized: true,
          activeMealPlanId: id  // Set the active plan ID
        }
      }));

      // Set the shopping list directly
      setShoppingList(data.plan.shoppingList);

      // Update preferences context
      if (data.preferences) {
        setPreferences(data.preferences);
      }
    } catch (error) {
      console.error('Error loading meal plan:', error);
      toast.error('Failed to load meal plan');
    } finally {
      setIsLoading(false);
    }
  }, [user, setPreferences]);

  // Update autoSaveCurrentMealPlan to remove its toast
  const autoSaveCurrentMealPlan = useCallback(async () => {
    if (!user || !state.mealPlan) return;
    
    try {
      const savedPlan = await saveMealPlan('My First Meal Plan');
      // Remove the success toast from here since it's handled in finalizeMealPlan
      return savedPlan;
    } catch (error) {
      console.error('Error auto-saving meal plan:', error);
    }
  }, [user, state.mealPlan, saveMealPlan]);

  // Helper function to check if meal plan needs regeneration
  const requiresMealPlanRegeneration = useCallback((oldPreferences, newPreferences) => {
    if (!oldPreferences || !newPreferences) return false;
    
    // People count changes don't require regeneration
    const relevantFields = ['dietaryRestrictions', 'allergens', 'cuisinePreferences', 'mealPreparationTime', 'varietyLevel'];
    
    return relevantFields.some(field => {
      const oldValue = oldPreferences[field];
      const newValue = newPreferences[field];
      
      if (Array.isArray(oldValue) && Array.isArray(newValue)) {
        return JSON.stringify(oldValue.sort()) !== JSON.stringify(newValue.sort());
      }
      return oldValue !== newValue;
    });
  }, []);

  // Define finalizeMealPlan after requiresMealPlanRegeneration
  const finalizeMealPlan = useCallback(async (isPeopleCountUpdate = false) => {
    if (!state.mealPlan) {
      toast.error('No meal plan available');
      return false;
    }

    // Ensure we have valid preferences
    if (!preferences || !preferences.peopleCount || preferences.peopleCount < 1) {
      toast.error('Invalid preferences. Please check your settings.');
      return false;
    }

    try {
      setIsGeneratingShoppingList(true);
      // Navigate to shopping list page before starting the generation
      navigate('/shopping-list');
      
      let finalShoppingList;
      try {
        // Create a clean version of the current meal plan with only active meals
        const currentMealPlan = {
          days: state.mealPlan.days.map(day => ({
            dayName: day.dayName,
            meals: Object.entries(day.meals).reduce((acc, [type, meal]) => ({
              ...acc,
              [type]: !meal.isPlaceholder && !meal.isRemoved ? meal : null
            }), {})
          })).filter(day => 
            Object.values(day.meals).some(meal => meal !== null)
          )
        };

        // Create the prompt with the actual meal plan data
        const promptWithValues = SHOPPING_LIST_PROMPT;

        const shoppingListMessages = [
          { role: 'user', content: promptWithValues },
          { role: 'system', content: JSON.stringify({ 
            mealPlan: currentMealPlan,
            preferences: preferences
          })}
        ];

        console.log('Sending to AI:', JSON.stringify({ 
          mealPlan: currentMealPlan,
          preferences: preferences
        }, null, 2));

        const completion = await openai.chat.completions.create({
          model: "gpt-4o-mini",
          messages: shoppingListMessages,
          temperature: 0.3
        });

        const content = completion.choices[0].message.content.trim();
        console.log('Raw AI Response:', content);

        // Clean and parse JSON
        const cleanAndParseJSON = (jsonString) => {
          try {
            // Remove any markdown code block markers
            let cleaned = jsonString.replace(/```json\n?|\n?```/g, '').trim();
            
            // Fix common JSON formatting issues
            cleaned = cleaned
              .replace(/,\s+"/g, ',"')
              .replace(/([{,]\s*)(\w+):/g, '$1"$2":')
              .replace(/:\s*([^",\{\[\]\}\s][^,\{\[\]\}\s]*)\s*([,\}\]])/g, ':"$1"$2')
              .replace(/,(\s*[}\]])/g, '$1')
              .replace(/"([^"]*)""/g, '"$1"')
              .replace(/":"/g, '": "')
              .replace(/\/\*[\s\S]*?\*\/|\/\/.*/g, '')
              .trim();

            console.log('Cleaned JSON string:', cleaned);
            
            try {
              const parsedList = JSON.parse(cleaned);
              
              // Detailed validation logging
              const validationLog = {
                timestamp: new Date().toISOString(),
                peopleCount: preferences?.peopleCount || 1,
                structuralIssues: [],
                dataIssues: [],
                quantityIssues: [],
                missingFields: [],
                unexpectedFields: [],
                stats: {
                  totalItems: 0,
                  itemsWithQuantity: 0,
                  itemsWithUsedIn: 0,
                  itemsWithNotes: 0,
                  categoriesPresent: [],
                  emptyCategories: []
                }
              };

              // Check for required categories
              const requiredCategories = ['Produce', 'Protein', 'Dairy', 'Pantry', 'Spices'];
              const missingCategories = requiredCategories.filter(cat => !parsedList[cat]);
              if (missingCategories.length > 0) {
                validationLog.structuralIssues.push(`Missing categories: ${missingCategories.join(', ')}`);
              }

              // Validate each category and item
              Object.entries(parsedList).forEach(([category, items]) => {
                validationLog.stats.categoriesPresent.push(category);

                if (!Array.isArray(items)) {
                  validationLog.structuralIssues.push(`Category ${category} is not an array`);
                  return;
                }

                if (items.length === 0) {
                  validationLog.stats.emptyCategories.push(category);
                }

                items.forEach((item, index) => {
                  validationLog.stats.totalItems++;

                  // Check required fields
                  if (!item) {
                    validationLog.dataIssues.push(`Null/undefined item in ${category} at index ${index}`);
                    return;
                  }

                  // Track missing required fields
                  if (!item.item) {
                    validationLog.missingFields.push(`Missing item name in ${category} at index ${index}`);
                  }
                  if (!item.quantity) {
                    validationLog.missingFields.push(`Missing quantity for ${item.item || 'unknown item'} in ${category}`);
                  } else {
                    validationLog.stats.itemsWithQuantity++;
                  }
                  if (!item.usedIn || !Array.isArray(item.usedIn)) {
                    validationLog.missingFields.push(`Missing or invalid usedIn for ${item.item || 'unknown item'} in ${category}`);
                  } else {
                    validationLog.stats.itemsWithUsedIn++;
                  }

                  // Track unexpected fields
                  const expectedFields = ['item', 'quantity', 'usedIn', 'notes'];
                  const unexpectedFields = Object.keys(item).filter(key => !expectedFields.includes(key));
                  if (unexpectedFields.length > 0) {
                    validationLog.unexpectedFields.push(`Unexpected fields for ${item.item || 'unknown item'} in ${category}: ${unexpectedFields.join(', ')}`);
                  }

                  // Check quantity format and scaling
                  if (item.quantity) {
                    const numericPart = parseFloat(item.quantity.match(/[\d.]+/)?.[0]);
                    if (isNaN(numericPart)) {
                      validationLog.quantityIssues.push(`Invalid quantity format: "${item.quantity}" for ${item.item} in ${category}`);
                    } else {
                      // Check for scaling issues based on people count
                      const peopleCount = preferences?.peopleCount || 1;
                      if (numericPart < 0.1) {
                        validationLog.quantityIssues.push(`Suspiciously small quantity (${item.quantity}) for ${item.item} in ${category}`);
                      }
                      if (numericPart > 100) {
                        validationLog.quantityIssues.push(`Suspiciously large quantity (${item.quantity}) for ${item.item} in ${category}`);
                      }
                      if (numericPart % 1 !== 0 && numericPart % (1/peopleCount) !== 0) {
                        validationLog.quantityIssues.push(`Quantity ${item.quantity} for ${item.item} may not be properly scaled for ${peopleCount} people`);
                      }
                    }
                  }

                  if (item.notes) {
                    validationLog.stats.itemsWithNotes++;
                  }
                });
              });

              // Log validation results
              console.log('AI Shopping List Validation:', {
                ...validationLog,
                hasIssues: (
                  validationLog.structuralIssues.length > 0 ||
                  validationLog.dataIssues.length > 0 ||
                  validationLog.quantityIssues.length > 0 ||
                  validationLog.missingFields.length > 0
                ),
                stats: {
                  ...validationLog.stats,
                  percentageWithQuantity: (validationLog.stats.itemsWithQuantity / validationLog.stats.totalItems * 100).toFixed(1) + '%',
                  percentageWithUsedIn: (validationLog.stats.itemsWithUsedIn / validationLog.stats.totalItems * 100).toFixed(1) + '%'
                }
              });

              return parsedList;
            } catch (parseError) {
              console.error('Failed to parse cleaned JSON:', parseError);
              // Try one more time with a more aggressive cleaning
              cleaned = cleaned
                .replace(/[^\x20-\x7E]/g, '')
                .replace(/\[\s*,/g, '[')
                .replace(/,\s*\]/g, ']')
                .replace(/\[\s*\]/g, '[]');
              
              console.log('Aggressively cleaned JSON string:', cleaned);
              return JSON.parse(cleaned);
            }
          } catch (error) {
            console.error('JSON cleaning and parsing failed:', error);
            throw new Error('Failed to parse shopping list response. Please try again.');
          }
        };

        finalShoppingList = cleanAndParseJSON(content);

        // Basic structure validation
        const requiredCategories = ['Produce', 'Protein', 'Dairy', 'Pantry', 'Spices'];
        requiredCategories.forEach(category => {
          if (!finalShoppingList[category]) {
            finalShoppingList[category] = [];
          }
        });

      } catch (error) {
        console.error('Failed to generate shopping list:', error);
        toast.error('Failed to generate shopping list. Please try again.');
        return false;
      }
      
      setShoppingList(finalShoppingList);
      setState(prev => {
        // Create a deep copy of the meal plan
        const mealPlanCopy = JSON.parse(JSON.stringify(prev.mealPlan));
        
        // Properly serialize the allMeals Maps
        const serializedAllMeals = {};
        if (prev.mealPlan.allMeals) {
          const mealTypes = ['breakfast', 'lunch', 'dinner', 'snacks'];
          mealTypes.forEach(type => {
            if (prev.mealPlan.allMeals[type] instanceof Map) {
              serializedAllMeals[type] = {};
              prev.mealPlan.allMeals[type].forEach((meal, mealName) => {
                serializedAllMeals[type][mealName] = meal;
              });
            }
          });
        }
        
        return {
          ...prev,
          lastFinalizedMealPlan: {
            ...mealPlanCopy,
            allMeals: serializedAllMeals,
            preferences: { ...preferences }  // Store the current preferences
          },
          mealPlan: {
            ...prev.mealPlan,
            shoppingList: finalShoppingList,
            preferences: { ...preferences }
          },
          status: {
            ...prev.status,
            isFinalized: true,
            canModify: true,
            needsShoppingListUpdate: false,
            isUpdatingShoppingList: false,  // Reset this when finalizing
            hasUnsavedChanges: false,  // Reset this when finalizing
            hasBeenSaved: false  // Reset this when finalizing
          }
        };
      });
      
      if (user) {
        const savedPlan = await autoSaveCurrentMealPlan();
        if (savedPlan) {
          setState(prev => ({
            ...prev,
            status: {
              ...prev.status,
              hasBeenSaved: true,
              activeMealPlanId: savedPlan.id
            }
          }));
          await getSavedMealPlans();  // Refresh the list after saving
        }
      }
      return true;
    } catch (error) {
      console.error('Failed to update shopping list:', error);
      toast.error('Failed to update shopping list');
      return false;
    } finally {
      setIsGeneratingShoppingList(false);
    }
  }, [state.mealPlan, preferences, navigate, user]);

  // Update effect to handle preference changes
  useEffect(() => {
    const handlePreferenceChanges = async () => {
      // Watch for preference changes that affect the shopping list
      if (preferences && state.mealPlan) {
        const lastPeopleCount = state.mealPlan.preferences?.peopleCount;
        const currentPeopleCount = preferences.peopleCount;
        
        if (lastPeopleCount !== currentPeopleCount) {
        setState(prev => ({
          ...prev,
          status: {
            ...prev.status,
              needsShoppingListUpdate: true
          }
        }));
        }
      }
    };

    handlePreferenceChanges();
  }, [preferences, state.mealPlan]);

  // Update the startModifications function to check for existing shopping list
  const startModifications = useCallback(() => {
    setState(prev => {
      // Only show update message if we have an existing shopping list and it's not empty
      const hasExistingShoppingList = prev.mealPlan?.shoppingList && 
        Object.values(prev.mealPlan.shoppingList).some(category => category.length > 0);

      return {
        ...prev,
        status: {
          ...prev.status,
          canModify: true,
          needsShoppingListUpdate: hasExistingShoppingList && !prev.status.hasUnsavedChanges
        }
      };
    });
  }, []);

  const cancelModifications = useCallback(() => {
    if (state.lastFinalizedMealPlan) {
      // First restore the preferences to what they were when shopping list was generated
      if (state.lastFinalizedMealPlan.preferences) {
        setPreferences(state.lastFinalizedMealPlan.preferences);
      }

      // Create a deep copy of the last finalized meal plan
      const restoredMealPlan = {
        ...JSON.parse(JSON.stringify(state.lastFinalizedMealPlan)),
        allMeals: {
          breakfast: new Map(),
          lunch: new Map(),
          dinner: new Map(),
          snacks: new Map()
        }
      };

      // First, check if the lastFinalizedMealPlan has allMeals and restore from it
      if (state.lastFinalizedMealPlan.allMeals) {
        const mealTypes = ['breakfast', 'lunch', 'dinner', 'snacks'];
        mealTypes.forEach(type => {
          if (state.lastFinalizedMealPlan.allMeals[type]) {
            // Convert the object back to a Map
            const allMealsOfType = state.lastFinalizedMealPlan.allMeals[type];
            if (typeof allMealsOfType === 'object') {
              Object.entries(allMealsOfType).forEach(([mealName, meal]) => {
                // Remove isRemoved flag if present
                const cleanMeal = { ...meal };
                delete cleanMeal.isRemoved;
                restoredMealPlan.allMeals[type].set(mealName, cleanMeal);
              });
            }
          }
        });
      }

      // Then also populate allMeals from days array to ensure we have all active meals
      restoredMealPlan.days.forEach(day => {
        Object.entries(day.meals).forEach(([type, meal]) => {
          if (meal && !meal.isPlaceholder) {
            // Remove isRemoved flag if present
            const cleanMeal = { ...meal };
            delete cleanMeal.isRemoved;
            restoredMealPlan.allMeals[type].set(meal.name, cleanMeal);
          }
        });
      });

      // Make sure all days have the correct meal structure
      restoredMealPlan.days = restoredMealPlan.days.map(day => ({
        ...day,
        meals: Object.entries(day.meals).reduce((acc, [type, meal]) => {
          // If the meal was removed, restore it to its original state
          if (meal && meal.isRemoved) {
            return {
              ...acc,
              [type]: {
                ...meal,
                isRemoved: false
              }
            };
          }
          return {
            ...acc,
            [type]: meal
          };
        }, {})
      }));

      setState(prev => ({
        ...prev,
        mealPlan: restoredMealPlan,
        status: {
          ...prev.status,
          needsShoppingListUpdate: false,
          hasUnsavedChanges: false,
          isFinalized: true
        }
      }));
      
      toast.success('Changes cancelled. Meal plan restored.');
    } else {
      toast.error('No previous meal plan state to restore to.');
    }
  }, [state.lastFinalizedMealPlan, setPreferences]);

  // Move getAvailableMealsForDay inside the provider
  const getAvailableMealsForDay = useCallback((mealType) => {
    if (!state.mealPlan?.days) return [];
    
    // Get all unique meals of this type (excluding placeholders)
    const uniqueMeals = new Map();
    
    // First add all meals from current days
    state.mealPlan.days.forEach(day => {
      const meal = day.meals[mealType];
      if (meal && !meal.isPlaceholder) {
        uniqueMeals.set(meal.name, meal);
      }
    });
    
    // Then add any meals from allMeals if it exists
    if (state.mealPlan.allMeals?.[mealType]) {
      const allMealsOfType = state.mealPlan.allMeals[mealType];
      if (allMealsOfType instanceof Map) {
        allMealsOfType.forEach((meal, mealName) => {
          if (!uniqueMeals.has(mealName)) {
            uniqueMeals.set(mealName, meal);
          }
        });
      }
    }

    // Convert to array and sort alphabetically for stable ordering
    return Array.from(uniqueMeals.values())
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [state.mealPlan]);

  // Add new function for generation-time validation only
  const validateGeneratedMealPlan = (mealPlan, preferences) => {
    console.group('Validating Generated Meal Plan');
    console.log('Meal Plan Structure:', mealPlan);
    console.log('Preferences:', preferences);

    const warnings = [];

    if (!mealPlan || !preferences || !mealPlan.days) {
      console.error('Invalid meal plan structure:', { mealPlan, preferences });
      console.groupEnd();
      return 'Invalid meal plan structure: missing required properties';
    }

    // First validate the day structure
    const mealTypes = ['breakfast', 'lunch', 'dinner', 'snacks'];
    mealPlan.days.forEach((day, index) => {
      if (!day.dayName) {
        warnings.push(`Day ${index + 1} missing dayName`);
      }
      mealTypes.forEach(type => {
        if (!day.meals?.[type]) {
          warnings.push(`Day ${day.dayName || index + 1} missing ${type}`);
        }
      });
    });

    // Then validate variety levels
    if (preferences.varietyLevel) {
      const validFrequencyDistributions = {
        low: {
          3: [3, 2, 2],
          4: [3, 2, 1, 1]
        },
        medium: {
          4: [2, 2, 2, 1],
          5: [2, 2, 1, 1, 1]
        },
        high: {
          6: [2, 1, 1, 1, 1, 1],
          7: [1, 1, 1, 1, 1, 1, 1]
        }
      };

      // Get meals organized by type
      const mealsByType = getMealsByType(mealPlan.days);

      // For each meal type, analyze its distribution
      Object.entries(mealsByType).forEach(([type, meals]) => {
        const uniqueMeals = meals.length;
        const frequencies = meals.map(meal => meal.frequency).sort((a, b) => b - a);
        const totalMeals = frequencies.reduce((sum, freq) => sum + freq, 0);

        console.log(`${type} distribution:`, {
          uniqueMeals,
          frequencies,
          meals
        });

        // Validate against variety level requirements
        const level = preferences.varietyLevel.toLowerCase();
        const validDistributions = validFrequencyDistributions[level];
        
        if (!validDistributions) {
          warnings.push(`Invalid variety level: ${preferences.varietyLevel}`);
          return;
        }

        const validDistribution = validDistributions[uniqueMeals];
        if (!validDistribution) {
          warnings.push(
            `${type} has ${uniqueMeals} unique meals (not valid for ${level} variety). ` +
            `Valid counts: ${Object.keys(validDistributions).join(' or ')}`
          );
          return;
        }

        // Check frequency distribution
        const frequenciesMatch = frequencies.every(
          (freq, i) => freq === validDistribution[i]
        );
        
        if (!frequenciesMatch) {
          warnings.push(
            `${type} has frequency distribution [${frequencies}]. ` +
            `Recommended [${validDistribution}] for ${level} variety with ${uniqueMeals} meals`
          );
        }

        // Check for consecutive days with same meal (as a warning)
        mealPlan.days.forEach((day, index) => {
          if (index > 0) {
            const prevMeal = mealPlan.days[index - 1].meals[type]?.name;
            const currentMeal = day.meals[type]?.name;
            if (prevMeal === currentMeal && !day.meals[type].isPlaceholder) {
              warnings.push(
                `${type}: Same meal "${currentMeal}" appears on consecutive days ` +
                `(${mealPlan.days[index - 1].dayName} and ${day.dayName}). ` +
                `Consider spacing out repeated meals.`
              );
            }
          }
        });
      });
    }

    // Log any warnings
    if (warnings.length > 0) {
      console.warn('Meal plan generated with warnings:', warnings);
    }

    console.log('Validation completed with warnings:', warnings.length > 0);
    console.groupEnd();
    
    // Return warnings object to store in state, but don't prevent meal plan generation
    return { warnings };
  };

  // Update generateMealPlan to use the updated validation
  const generateMealPlan = useCallback(async () => {
    if (isModifiedSinceMealPlan) {
      submitPreferences();
    }

    console.group('Meal Plan Generation');
    console.log('Current preferences:', {
      peopleCount: preferences?.peopleCount,
      hasAllergens: preferences?.allergens?.length > 0,
      hasDietaryRestrictions: preferences?.dietaryRestrictions?.length > 0,
      hasCuisinePreferences: preferences?.cuisinePreferences?.length > 0,
      mealPrepTimeLimit: preferences?.mealPrepTimeLimit
    });
    console.log('Modified since last meal plan:', isModifiedSinceMealPlan);

    setState(prev => ({
      ...prev,
      status: {
        ...prev.status,
        loading: true,
        error: null,
        isFinalized: false,
        canModify: true
      }
    }));

    try {
      if (!preferences?.peopleCount) {
        throw new Error('Please set your preferences first');
      }

      // Replace preference placeholders in system message
      const systemMessageWithPrefs = SYSTEM_MESSAGE
        .replace('<peopleCount>', preferences.peopleCount || '')
        .replace('<allergens>', JSON.stringify(preferences.allergens || []))
        .replace('<dietaryRestrictions>', JSON.stringify(preferences.dietaryRestrictions || []))
        .replace('<varietyLevel>', preferences.varietyLevel || 'Medium')
        .replace('<cuisinePreferences>', JSON.stringify(preferences.cuisinePreferences || []))
        .replace('<mealPreparationTime>', preferences.mealPrepTimeLimit || 'Any')
        .replace('<mealTypes>', JSON.stringify(preferences.mealTypes?.includes('all') 
          ? ['breakfast', 'lunch', 'dinner', 'snacks'] 
          : preferences.mealTypes || []));

      const messages = [
        { role: 'system', content: systemMessageWithPrefs },
        {
          role: 'user',
          content: JSON.stringify({ preferences })
        }
      ];

      console.group('Message Structure');
      console.log('Message roles:', messages.map(m => ({ role: m.role })));
      console.groupEnd();

      const response = await openai.chat.completions.create({
        model: "gpt-4o-mini",
        messages: [
          ...messages,
          {
            role: "system",
            content: "You must respond with valid JSON only, no other text. Do not include markdown code blocks or any other formatting."
          }
        ],
        temperature: 0.7,
        max_tokens: 4000
      });

      const rawResponse = response.choices[0].message.content;
      console.log('Response received, parsing JSON...');
      
      // Sanitize and fix common JSON formatting issues
      const sanitizeJSON = (jsonString) => {
        try {
          // Remove any markdown code block markers
          let cleaned = jsonString.replace(/```json\n?|\n?```/g, '');
          
          // Fix common formatting issues
          cleaned = cleaned
            // Remove extra spaces in arrays
            .replace(/,\s+"/g, ',"')
            // Fix missing quotes around property names
            .replace(/([{,]\s*)(\w+):/g, '$1"$2":')
            // Fix missing quotes around string values
            .replace(/:\s*([^",\{\[\]\}\s][^,\{\[\]\}\s]*)\s*([,\}\]])/g, ':"$1"$2')
            // Remove any trailing commas
            .replace(/,(\s*[}\]])/g, '$1')
            // Fix any double quotes within double quotes
            .replace(/"([^"]*)""/g, '"$1"')
            // Ensure proper spacing after colons
            .replace(/":"/g, '": "')
            // Fix any remaining formatting issues
            .trim();

          // Attempt to parse the cleaned JSON
          return JSON.parse(cleaned);
        } catch (error) {
          console.error('JSON sanitization failed:', error);
          console.log('Attempted to parse:', cleaned);
          throw new Error('Failed to parse response: ' + error.message);
        }
      };

      let generatedMealPlan;
      try {
        generatedMealPlan = sanitizeJSON(rawResponse);
        console.log('Parsed Meal Plan:', generatedMealPlan);
      } catch (parseError) {
        console.error('Failed to parse OpenAI response:', parseError);
        throw new Error('Failed to parse meal plan response');
      }

      // Process the generated meal plan to organize by type and day
      const processedMealPlan = {
        days: generatedMealPlan.days.map(day => ({
          dayName: day.dayName.toLowerCase(),
          meals: Object.entries(day.meals).reduce((acc, [type, meal]) => {
            acc[type.toLowerCase()] = {
              ...meal,
              isPlaceholder: meal.isPlaceholder === 'true' || meal.isPlaceholder === true
            };
            return acc;
          }, {})
        })),
        byType: {
          breakfast: [],
          lunch: [],
          dinner: [],
          snacks: []
        },
        byDay: {},
        extraMeals: {
          breakfast: [],
          lunch: [],
          dinner: [],
          snacks: []
        },
        allMeals: {
          breakfast: new Map(),
          lunch: new Map(),
          dinner: new Map(),
          snacks: new Map()
        },
        preferences: preferences,
        shoppingList: {
          Produce: [],
          Protein: [],
          Dairy: [],
          Pantry: [],
          Spices: []
        }
      };

      // First, collect all unique meals and store them in allMeals
      processedMealPlan.days.forEach(day => {
        Object.entries(day.meals).forEach(([type, meal]) => {
          const normalizedType = type.toLowerCase();
          if (meal && !meal.isPlaceholder && !processedMealPlan.allMeals[normalizedType].has(meal.name)) {
            processedMealPlan.allMeals[normalizedType].set(meal.name, meal);
          }
        });
      });

      // Then, organize meals by type
      const mealsByType = new Map();
      processedMealPlan.days.forEach(day => {
        Object.entries(day.meals).forEach(([type, meal]) => {
          // Ensure type is lowercase for consistency
          const normalizedType = type.toLowerCase();
          if (!processedMealPlan.byType[normalizedType]) {
            processedMealPlan.byType[normalizedType] = [];
          }
          
          const mealKey = `${normalizedType}-${meal.name}`;
          if (!mealsByType.has(mealKey)) {
            mealsByType.set(mealKey, {
              ...meal,
              frequency: 1,
              daysUsed: [day.dayName],
              isPlaceholder: meal.isPlaceholder === 'true' || meal.isPlaceholder === true
            });
          } else {
            const existingMeal = mealsByType.get(mealKey);
            existingMeal.frequency += 1;
            existingMeal.daysUsed.push(day.dayName);
          }
        });
      });

      // Convert Map to arrays for each meal type
      mealsByType.forEach((meal, key) => {
        const [type] = key.split('-');
        if (processedMealPlan.byType[type]) {
          processedMealPlan.byType[type].push(meal);
        }
      });

      // Sort meals by frequency
      Object.keys(processedMealPlan.byType).forEach(type => {
        if (Array.isArray(processedMealPlan.byType[type])) {
          processedMealPlan.byType[type].sort((a, b) => b.frequency - a.frequency);
        }
      });

      // Organize meals by day
      generatedMealPlan.days.forEach(day => {
        const normalizedDayName = day.dayName.toLowerCase();
        processedMealPlan.byDay[normalizedDayName] = Object.entries(day.meals).reduce((acc, [type, meal]) => {
          const normalizedType = type.toLowerCase();
          acc[normalizedType] = {
            ...meal,
            isPlaceholder: meal.isPlaceholder === 'true' || meal.isPlaceholder === true
          };
          return acc;
        }, {});
      });

      // Validate the processed meal plan
      const validationResult = validateMealPlan(processedMealPlan, preferences);
      if (validationResult && typeof validationResult === 'string') {
        throw new Error('Generated meal plan has validation errors: ' + validationResult);
      }

      setState(prev => ({
        ...prev,
        mealPlan: processedMealPlan,
        status: {
          ...prev.status,
          loading: false,
          error: null,
          warnings: validationResult?.warnings || [],
          needsShoppingListUpdate: true,  // Always true after generating new meal plan
          isFinalized: false
        }
      }));

      // Removed success toast message here
      console.log('Meal plan generated and updated successfully:', processedMealPlan);
    } catch (error) {
      console.error('Full error details:', error);
      console.error('Error stack:', error.stack);
      setState(prev => ({
        ...prev,
        status: {
          ...prev.status,
          loading: false,
          error: error.message
        }
      }));
      toast.error('Failed to generate meal plan: ' + error.message);
    }
    console.groupEnd();
  }, [isModifiedSinceMealPlan, submitPreferences, preferences, user]);

  const fetchSavedMealPlan = useCallback(async () => {
    if (!user) return;

    setState(prev => ({
      ...prev,
      status: {
        ...prev.status,
        loading: true,
        error: null
      }
    }));

    try {
      const { data, error } = await supabase
        .from('meal_plans')
        .select('*')
        .eq('user_id', user.id)
        .order('created_at', { ascending: false })
        .limit(1)
        .single();

      if (error) throw error;

      if (data) {
        // Convert old format to new format if necessary
        let mealPlan = data.meal_plan;
        if (!mealPlan.days) {
          // If no days array, this is an old format that needs conversion
          const days = [
            'monday', 'tuesday', 'wednesday', 'thursday', 
            'friday', 'saturday', 'sunday'
          ].map(dayName => ({
            dayName,
            meals: {
              breakfast: {
                name: 'No meal assigned',
                description: 'No meal selected for this slot',
                prepTime: '-',
                isPlaceholder: true
              },
              lunch: {
                name: 'No meal assigned',
                description: 'No meal selected for this slot',
                prepTime: '-',
                isPlaceholder: true
              },
              dinner: {
                name: 'No meal assigned',
                description: 'No meal selected for this slot',
                prepTime: '-',
                isPlaceholder: true
              },
              snacks: {
                name: 'No meal assigned',
                description: 'No meal selected for this slot',
                prepTime: '-',
                isPlaceholder: true
              }
            }
          }));

          // If we have old byType data, use it to populate days
          if (mealPlan.byType) {
            Object.entries(mealPlan.byType).forEach(([type, meals]) => {
              meals.forEach(meal => {
                if (meal.frequency > 0) {
                  // Find days where this meal is used
                  const daysUsed = meal.daysUsed || [];
                  daysUsed.forEach(({ dayName }) => {
                    const dayIndex = days.findIndex(d => d.dayName === dayName);
                    if (dayIndex !== -1) {
                      days[dayIndex].meals[type] = {
                        ...meal,
                        isPlaceholder: false
                      };
                    }
                  });
                }
              });
            });
          }

          mealPlan = {
            days,
            preferences: mealPlan.preferences || null,
            shoppingList: mealPlan.shoppingList || {
              Produce: [],
              Protein: [],
              Dairy: [],
              Pantry: [],
              Spices: []
            }
          };
        }

        // Validate the meal plan
        const validationResult = validateGeneratedMealPlan(mealPlan, preferences);
        const isValid = !validationResult || validationResult.warnings;

        setState(prev => ({
          ...prev,
          mealPlan,
          status: {
            ...prev.status,
            loading: false,
            error: null,
            validationState: {
              isValid,
              warnings: validationResult?.warnings || []
            }
          }
        }));

        toast.success('Meal plan loaded successfully!');
      } else {
        setState(prev => ({
          ...prev,
          status: {
            ...prev.status,
            loading: false
          }
        }));
      }
    } catch (error) {
      console.error('Error fetching meal plan:', error);
      setState(prev => ({
        ...prev,
        status: {
          ...prev.status,
          loading: false,
          error: error.message
        }
      }));
      toast.error('Failed to load meal plan');
    }
  }, [user, preferences]);

  // Update getMealsByType to properly handle zero-frequency meals
  const getMealsByType = useCallback((days) => {
    if (!days || !Array.isArray(days)) {
      return {
        breakfast: [],
        lunch: [],
        dinner: [],
        snacks: []
      };
    }

    const mealTypes = ['breakfast', 'lunch', 'dinner', 'snacks'];
    const byType = {};

    // Initialize meal types with Maps to track unique meals
    mealTypes.forEach(type => {
      byType[type] = new Map();
    });

    // First, get all meals from allMeals (if it exists)
    if (state?.mealPlan?.allMeals) {
      mealTypes.forEach(type => {
        const allMealsOfType = state.mealPlan.allMeals[type];
        if (allMealsOfType && allMealsOfType instanceof Map) {
          allMealsOfType.forEach((meal, mealName) => {
            byType[type].set(mealName, {
              ...meal,
              frequency: 0, // Start with 0 frequency
              daysUsed: [] // Start with empty daysUsed
            });
          });
        }
      });
    }

    // Then update frequencies from current days
    days.forEach((day, dayIndex) => {
      if (!day?.meals) return;
      
      mealTypes.forEach(type => {
        if (!day.meals[type]) {
          // Remove console warning
          return;
        }
        
        const meal = day.meals[type];
        if (meal && !meal.isPlaceholder && meal.name) {
          if (!byType[type].has(meal.name)) {
            // If somehow not in allMeals, add it
            byType[type].set(meal.name, {
              ...meal,
              frequency: 1,
              daysUsed: [{ dayName: day.dayName, dayIndex }]
            });
          } else {
            // Update existing meal's frequency
            const existingMeal = byType[type].get(meal.name);
            if (existingMeal) {
              existingMeal.frequency += 1;
              existingMeal.daysUsed.push({ dayName: day.dayName, dayIndex });
            }
          }
        }
      });
    });

    // Convert Maps to arrays, maintaining original order from allMeals
    const result = {};
    mealTypes.forEach(type => {
      if (state?.mealPlan?.allMeals?.[type]) {
        // Use original order from allMeals
        result[type] = Array.from(state.mealPlan.allMeals[type].keys())
          .map(mealName => byType[type].get(mealName))
          .filter(Boolean);
      } else {
        // Fallback to current order if allMeals not available
        result[type] = Array.from(byType[type].values());
      }
    });

    return result;
  }, [state.mealPlan?.allMeals]);

  // Update updateMealFrequency to maintain meal cards
  const updateMealFrequency = useCallback((mealType, mealName, newFrequency) => {
    if (!state.mealPlan || !state.status.canModify) {
      console.warn('Cannot update frequency: meal plan not available or modifications not allowed');
      return;
    }

    // Ensure newFrequency is within valid range
    const validFrequency = Math.max(0, Math.min(7, newFrequency));

    setState(prev => {
      // Initialize or get the allMeals map from state
      const allMeals = prev.mealPlan.allMeals || {};
      
      // Initialize the Map for this meal type if it doesn't exist or isn't a Map
      if (!allMeals[mealType] || !(allMeals[mealType] instanceof Map)) {
        allMeals[mealType] = new Map();
      }

      // Get the days array
      const days = [...prev.mealPlan.days];

      // First try to find meal details from allMeals
      let mealDetails = null;
      if (allMeals[mealType] instanceof Map) {
        mealDetails = allMeals[mealType].get(mealName);
      }

      // If not in allMeals, try to find from current days
      if (!mealDetails) {
        mealDetails = days.find(day => {
          // Add null check before calling toLowerCase()
          if (!day.meals[mealType]?.name || !mealName) return false;
          return day.meals[mealType].name.toLowerCase() === mealName.toLowerCase();
        })?.meals[mealType];

        if (!mealDetails) {
          console.warn(`Meal ${mealName} not found in ${mealType}`);
          return prev;
        }
      }

      // Store the meal in allMeals if it's not already there
      if (allMeals[mealType] instanceof Map && !allMeals[mealType].has(mealName)) {
        allMeals[mealType].set(mealName, mealDetails);
      }

      // Clear all instances of this meal
      const updatedDays = days.map(day => ({
        ...day,
        meals: {
          ...day.meals,
          [mealType]: (day.meals[mealType]?.name && mealName && 
                      day.meals[mealType].name.toLowerCase() === mealName.toLowerCase())
            ? {
                name: 'No meal assigned',
                description: 'No meal selected for this slot',
                prepTime: '-',
                ingredients: [],
                notes: '',
                isPlaceholder: true
              }
            : day.meals[mealType]
        }
      }));

      // Fill in the meal from the start of the week up to the desired frequency
      for (let i = 0; i < validFrequency; i++) {
        const availableDayIndex = updatedDays.findIndex(day => 
          day.meals[mealType]?.isPlaceholder === true
        );

        if (availableDayIndex === -1) break;

        updatedDays[availableDayIndex] = {
          ...updatedDays[availableDayIndex],
          meals: {
            ...updatedDays[availableDayIndex].meals,
            [mealType]: {
              ...mealDetails,
              isPlaceholder: false
            }
          }
        };
      }

      return {
        ...prev,
        mealPlan: {
          ...prev.mealPlan,
          days: updatedDays,
          allMeals
        },
        status: {
          ...prev.status,
          hasUnsavedChanges: true,
          needsShoppingListUpdate: true
        }
      };
    });
  }, [state.mealPlan, state.status.canModify]);

  // Update swapDailyMeal to ensure UI updates
  const swapDailyMeal = useCallback((dayName, mealType, newMealName) => {
    if (!state.mealPlan || !state.status.canModify) {
      console.warn('Cannot swap meal: meal plan not available or modifications not allowed');
      return;
    }

    setState(prev => {
      const days = [...prev.mealPlan.days];
      
      // Find the target day
      const dayIndex = days.findIndex(d => 
        d.dayName.toLowerCase() === dayName.toLowerCase()
      );
      
      if (dayIndex === -1) {
        console.warn(`Day ${dayName} not found`);
        return prev;
      }

      // Initialize allMeals if it doesn't exist
      const allMeals = prev.mealPlan.allMeals || {
        breakfast: new Map(),
        lunch: new Map(),
        dinner: new Map(),
        snacks: new Map()
      };
      
      // Get the current meal that's being replaced
      const currentMeal = days[dayIndex].meals[mealType];
      
      // Handle meal removal case (when newMealName is null)
      if (newMealName === null) {
        // Store the current meal in allMeals if it's not a placeholder
        if (!currentMeal.isPlaceholder) {
          // Store the meal without the isRemoved flag in allMeals so it can be reused
          const mealForAllMeals = { ...currentMeal };
          delete mealForAllMeals.isRemoved;
          allMeals[mealType].set(currentMeal.name, mealForAllMeals);
        }
        
        // Update the specific day with a placeholder meal
        const updatedDays = days.map((day, index) => {
          if (index === dayIndex) {
            return {
              ...day,
              meals: {
                ...day.meals,
                [mealType]: {
                  name: "No meal assigned",
                  description: "No meal selected for this slot",
                  prepTime: "-",
                  ingredients: [],
                  notes: "",
                  isPlaceholder: true,
                  isRemoved: true
                }
              }
            };
          }
          return day;
        });

        return {
          ...prev,
          mealPlan: {
            ...prev.mealPlan,
            days: updatedDays,
            allMeals
          },
          status: {
            ...prev.status,
            hasUnsavedChanges: true,
            needsShoppingListUpdate: true
          }
        };
      }
      
      // First try to find the new meal details from allMeals
      let newMealDetails = null;
      if (allMeals[mealType]?.has(newMealName)) {
        newMealDetails = allMeals[mealType].get(newMealName);
      }

      // If not in allMeals, try to find from current days
      if (!newMealDetails) {
        newMealDetails = days.find(day => {
          // Add null check before calling toLowerCase()
          if (!day.meals[mealType]?.name || !newMealName) return false;
          return day.meals[mealType].name.toLowerCase() === newMealName.toLowerCase() &&
            day.meals[mealType]?.isPlaceholder !== true;
        })?.meals[mealType];

        if (!newMealDetails) {
          console.warn(`Meal ${newMealName} not found in ${mealType}`);
          return prev;
        }
      }

      // Store both meals in allMeals
      if (!currentMeal.isPlaceholder) {
        // Store the meal without the isRemoved flag in allMeals so it can be reused
        const mealForAllMeals = { ...currentMeal };
        delete mealForAllMeals.isRemoved;
        allMeals[mealType].set(currentMeal.name, mealForAllMeals);
      }
      
      // Store the new meal without the isRemoved flag in allMeals
      const newMealForAllMeals = { ...newMealDetails };
      delete newMealForAllMeals.isRemoved;
      allMeals[mealType].set(newMealName, newMealForAllMeals);

      // Update the specific day with the new meal
      const updatedDays = days.map((day, index) => {
        if (index === dayIndex) {
          return {
            ...day,
            meals: {
              ...day.meals,
              [mealType]: {
                ...newMealDetails,
                isPlaceholder: false,
                isRemoved: false
              }
            }
          };
        }
        return day;
      });

      return {
        ...prev,
        mealPlan: {
          ...prev.mealPlan,
          days: updatedDays,
          allMeals
        },
        status: {
          ...prev.status,
          hasUnsavedChanges: true,
          needsShoppingListUpdate: true
        }
      };
    });
  }, [state.mealPlan, state.status.canModify]);

  // Update updateMeal to check for actual changes
  const updateMeal = useCallback((mealType, mealName, updatedMeal) => {
    if (!state.mealPlan) return;

    setState(prev => {
      const currentMealPlan = { ...prev.mealPlan };
      const days = [...currentMealPlan.days];
      
      // Update all occurrences of this meal
      const updatedDays = days.map(day => {
        if (day.meals[mealType].name === mealName) {
          return {
            ...day,
            meals: {
              ...day.meals,
              [mealType]: {
                ...day.meals[mealType],
                ...updatedMeal
              }
            }
          };
        }
        return day;
      });

      // Only update if changes were made
      const hasChanges = JSON.stringify(days) !== JSON.stringify(updatedDays);
      if (!hasChanges) return prev;

      return {
        ...prev,
        mealPlan: {
          ...currentMealPlan,
          days: updatedDays
        },
        status: {
          ...prev.status,
          hasUnsavedChanges: true,
          needsShoppingListUpdate: true
        }
      };
    });
  }, [state.mealPlan]);

  const deleteMeal = useCallback((mealType, mealName) => {
    if (!state.mealPlan) return;

    setState(prev => {
      const currentMealPlan = { ...prev.mealPlan };
      const days = [...currentMealPlan.days];
      
      // Replace all occurrences of this meal with placeholders
      const updatedDays = days.map(day => {
        if (day.meals[mealType].name === mealName) {
          return {
            ...day,
            meals: {
              ...day.meals,
              [mealType]: {
                name: 'No meal assigned',
                description: 'No meal selected for this slot',
                prepTime: '-',
                isPlaceholder: true
              }
            }
          };
        }
        return day;
      });

      return {
        ...prev,
        mealPlan: {
          ...currentMealPlan,
          days: updatedDays
        },
        status: {
          ...prev.status,
          hasUnsavedChanges: true,
          needsShoppingListUpdate: true
        }
      };
    });
  }, [state.mealPlan]);

  const getMealTypeTotal = useCallback((mealType) => {
    if (!state.mealPlan?.days) return 0;
    
    // Count non-placeholder meals of the specified type
    return state.mealPlan.days.reduce((total, day) => 
      total + (day.meals[mealType]?.isPlaceholder === true ? 0 : 1), 0
    );
  }, [state.mealPlan]);

  // Add new helper functions for meal management
  const moveToExtraMeals = useCallback((dayIndex, mealType) => {
    if (!state.mealPlan) return;

    setState(prev => {
      const currentMealPlan = { ...prev.mealPlan };
      const days = [...currentMealPlan.days];
      const extraMeals = { ...currentMealPlan.extraMeals };

      // Get the meal to move
      const meal = days[dayIndex].meals[mealType];
      if (!meal || meal?.isPlaceholder === true) return prev;

      // Add to extra meals
      extraMeals[mealType] = [...extraMeals[mealType], meal];

      // Replace with placeholder in days
      days[dayIndex] = {
        ...days[dayIndex],
        meals: {
          ...days[dayIndex].meals,
          [mealType]: {
            name: 'No meal assigned',
            description: 'No meal selected for this slot',
            prepTime: '-',
            isPlaceholder: true
          }
        }
      };

      return {
        ...prev,
        mealPlan: {
          ...currentMealPlan,
          days,
          extraMeals
        },
        status: {
          ...prev.status,
          hasUnsavedChanges: true,
          needsShoppingListUpdate: true
        }
      };
    });
  }, [state.mealPlan]);

  const moveFromExtraMeals = useCallback((mealType, mealIndex, targetDayIndex) => {
    if (!state.mealPlan) return;

    setState(prev => {
      const currentMealPlan = { ...prev.mealPlan };
      const days = [...currentMealPlan.days];
      const extraMeals = { ...currentMealPlan.extraMeals };

      // Get the meal to move
      const meal = extraMeals[mealType][mealIndex];
      if (!meal) return prev;

      // Check if target day has a placeholder
      if (!days[targetDayIndex]?.meals?.[mealType]?.isPlaceholder) {
        toast.error('Selected day already has a meal assigned');
        return prev;
      }

      // Move meal to target day
      days[targetDayIndex] = {
        ...days[targetDayIndex],
        meals: {
          ...days[targetDayIndex].meals,
          [mealType]: meal
        }
      };

      // Remove from extra meals
      extraMeals[mealType] = extraMeals[mealType].filter((_, index) => index !== mealIndex);

      return {
        ...prev,
        mealPlan: {
          ...currentMealPlan,
          days,
          extraMeals
        },
        status: {
          ...prev.status,
          hasUnsavedChanges: true,
          needsShoppingListUpdate: true
        }
      };
    });
  }, [state.mealPlan]);

  // Update saveSessionDataAfterSignup to use a single toast
  const saveSessionDataAfterSignup = useCallback(async () => {
    if (!user || !state.mealPlan || state.status.hasBeenSaved) return;

    try {
      if (state.mealPlan.shoppingList && state.status.isFinalized) {
        console.log('Attempting to save meal plan after signup:', {
          user: user.id,
          hasShoppingList: !!state.mealPlan.shoppingList,
          isFinalized: state.status.isFinalized
        });

        const savedPlan = await saveMealPlan('My First Meal Plan');
        if (savedPlan) {
          setState(prev => ({
            ...prev,
            status: {
              ...prev.status,
              hasBeenSaved: true,
              activeMealPlanId: savedPlan.id
            }
          }));

          await getSavedMealPlans();
        }
      }
    } catch (error) {
      console.error('Error saving session data after signup:', error);
      toast.error('Failed to save meal plan');
    }
  }, [user, state.mealPlan, state.status.isFinalized, state.status.hasBeenSaved, saveMealPlan, navigate, getSavedMealPlans]);

  // Add effect to watch for auth changes
  useEffect(() => {
    if (user) {
      saveSessionDataAfterSignup();
    }
  }, [user, saveSessionDataAfterSignup]);

  const value = {
    mealPlan: state.mealPlan,
    loading: state.status.loading,
    error: state.status.error,
    isModified: state.status.hasUnsavedChanges,
    isFinalized: state.status.isFinalized,
    canModify: state.status.canModify,
    needsShoppingListUpdate: state.status.needsShoppingListUpdate,
    isUpdatingShoppingList: state.status.isUpdatingShoppingList,
    generateMealPlan,
    fetchSavedMealPlan,
    finalizeMealPlan,
    startModifications,
    cancelModifications,
    updateMeal,
    deleteMeal,
    updateMealFrequency,
    getMealTypeTotal,
    swapDailyMeal,
    status: state.status,
    validationState: state.status.validationState,
    shoppingList,
    isGeneratingShoppingList,
    setShoppingList,
    getAvailableMealsForDay,
    getMealsByType,
    saveMealPlan,
    getSavedMealPlans,
    loadSavedMealPlan,
    autoSaveCurrentMealPlan,
    savedMealPlans,
    isSaving,
    isLoading,
    activeMealPlanId: state.status.activeMealPlanId
  };

  return (
    <MealPlanContext.Provider value={value}>
      {children}
    </MealPlanContext.Provider>
  );
};