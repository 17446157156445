import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  RiDownloadLine, 
  RiCheckLine, 
  RiCloseLine, 
  RiShoppingCart2Line, 
  RiAlertLine, 
  RiShoppingBasketLine, 
  RiRestaurantLine,
  RiArrowLeftLine,
  RiRefreshLine,
  RiCalendarLine,
  RiSaveLine,
  RiUserSettingsLine,
  RiArrowDownSLine
} from 'react-icons/ri';
import { jsPDF } from 'jspdf';
import { useMealPlan } from '../contexts/MealPlanContext';
import { useAuth } from '../contexts/AuthContext';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { LoadingQuote } from '../components/LoadingQuote';
import BenefitsDisplay from '../components/BenefitsDisplay';
import FeedbackModal from '../components/FeedbackModal';
import SaveShoppingListModal from '../components/SaveShoppingListModal';
import InstacartModal from '../components/InstacartModal';
import InstacartButton from '../components/InstacartButton';
import StickyActions from '../components/StickyActions';
import { SHOPPING_LIST_MESSAGES } from '../utils/toastMessages';
import { useViewMode, VIEW_MODES } from '../contexts/ViewModeContext';
import ViewModeToggle from '../components/ViewModeToggle';
import { createInstacartProductsLink, saveInstacartLink } from '../utils/instacartService';

const ShoppingList = () => {
  const { 
    mealPlan, 
    shoppingList, 
    isGeneratingShoppingList,
    isUpdatingShoppingList,
    needsShoppingListUpdate,
    finalizeMealPlan,
    cancelModifications,
    getMealsByType
  } = useMealPlan();
  const { user } = useAuth();
  const [checkedItems, setCheckedItems] = useState({});
  const [allChecked, setAllChecked] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [showInstacartModal, setShowInstacartModal] = useState(false);
  const navigate = useNavigate();
  const { viewMode, isDetailed, setViewMode } = useViewMode();
  const [expandedCategories, setExpandedCategories] = useState(new Set());

  useEffect(() => {
    if (shoppingList) {
      const initialCheckedState = {};
      Object.entries(shoppingList).forEach(([category, items]) => {
        if (Array.isArray(items)) {
          items.forEach((item, index) => {
            if (item && item.item) {
              initialCheckedState[`${category}-${index}-${item.item}`] = true;
            }
          });
        }
      });
      setCheckedItems(initialCheckedState);
      setAllChecked(true);
    }
  }, [shoppingList]);

  useEffect(() => {
    if (viewMode === VIEW_MODES.DETAILED) {
      // Expand all categories
      const allCategories = Object.keys(shoppingList || {});
      setExpandedCategories(new Set(allCategories));
    } else {
      // Collapse all categories
      setExpandedCategories(new Set());
    }
  }, [viewMode, shoppingList]);

  const handleToggleAll = () => {
    const newCheckedState = {};
    Object.entries(shoppingList || {}).forEach(([category, items]) => {
      if (Array.isArray(items)) {
        items.forEach((item, index) => {
          if (item && item.item) {
            newCheckedState[`${category}-${index}-${item.item}`] = !allChecked;
          }
        });
      }
    });
    setCheckedItems(newCheckedState);
    setAllChecked(!allChecked);
  };

  const handleToggleItem = (itemKey) => {
    setCheckedItems(prev => {
      const newState = { ...prev, [itemKey]: !prev[itemKey] };
      const allItemsChecked = Object.values(newState).every(Boolean);
      setAllChecked(allItemsChecked);
      return newState;
    });
  };

  const getTotalItems = () => {
    let total = 0;
    Object.values(shoppingList || {}).forEach(category => {
      total += category.length;
    });
    return total;
  };

  const handleExport = () => {
    // Format the shopping list
    const formattedList = Object.entries(shoppingList || {})
      .map(([category, items]) => {
        if (category.toLowerCase() === 'warnings' || !Array.isArray(items) || items.length === 0) return '';
        
        const selectedItems = items.filter((item, index) => 
          checkedItems[`${category}-${index}-${item?.item}`]
        );
        if (selectedItems.length === 0) return '';
        
        return `${category.toUpperCase()}\n${selectedItems
          .map(item => {
            if (!item?.item) return '';
            const capitalizedItem = item.item.split(' ')
              .map(word => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ');
            return `  □ ${capitalizedItem} - ${item.quantity}${item.notes ? ` (${item.notes})` : ''}`;
          })
          .filter(Boolean)
          .join('\n')}`;
      })
      .filter(Boolean)
      .join('\n\n');

    // Format the meal plan by day
    const mealPlanByDay = mealPlan?.days?.map(day => {
      return `${day.dayName.toUpperCase()}\n${Object.entries(day.meals)
        .map(([type, meal]) => `  ${type.charAt(0).toUpperCase() + type.slice(1)}: ${meal?.name || 'No meal assigned'}`)
        .join('\n')}`;
    }).join('\n\n');

    // Format the meal plan by type
    const mealsByType = getMealsByType(mealPlan?.days);
    const mealPlanByType = Object.entries(mealsByType)
      .map(([type, meals]) => {
        const activeMeals = meals.filter(meal => meal.frequency > 0);
        if (activeMeals.length === 0) return '';
        return `${type.toUpperCase()}\n${activeMeals
          .map(meal => `  ${meal.name} (${meal.frequency}x)`)
          .join('\n')}`;
      })
      .filter(Boolean)
      .join('\n\n');

    // Combine all sections with headers and spacing
    const content = [
      'SHOPPING LIST',
      '',
      formattedList,
      '',
      'MEAL PLAN - BY DAY',
      '',
      mealPlanByDay,
      '',
      'MEAL PLAN - BY TYPE',
      '',
      mealPlanByType
    ].join('\n');

    const blob = new Blob([content], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'EasyMeals-Shopping-List.txt';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const capitalizeItem = (itemName) => {
    if (!itemName || typeof itemName !== 'string') return '';
    return itemName.split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  // Add a debug handler for the button click
  const handleSaveClick = () => {
    if (user) {
      handleExport();
    } else {
      console.log('Opening save modal for non-logged-in user');
      setShowSaveModal(true);
    }
  };

  const handleCategoryToggle = (category) => {
    setExpandedCategories(prev => {
      const newSet = new Set(prev);
      if (newSet.has(category)) {
        newSet.delete(category);
      } else {
        newSet.add(category);
      }
      return newSet;
    });
  };

  // Filter shopping list to only include checked items
  const getFilteredShoppingList = () => {
    if (!shoppingList) return null;
    
    const filteredList = {};
    
    Object.entries(shoppingList).forEach(([category, items]) => {
      if (!Array.isArray(items)) {
        filteredList[category] = items;
        return;
      }
      
      const filteredItems = items.filter((item, index) => 
        item && item.item && checkedItems[`${category}-${index}-${item.item}`]
      );
      
      if (filteredItems.length > 0) {
        filteredList[category] = filteredItems;
      }
    });
    
    return filteredList;
  };

  const handleInstacartClick = async () => {
    // Get filtered shopping list with only checked items
    const filteredShoppingList = getFilteredShoppingList();
    
    // Check if there are any items in the filtered list
    const hasItems = Object.values(filteredShoppingList || {}).some(
      items => Array.isArray(items) && items.length > 0
    );
    
    if (!hasItems) {
      toast.error('Please check at least one item to send to Instacart');
      return;
    }

    // Check if we have a cached link in localStorage
    const cachedLink = localStorage.getItem('instacart_link');
    const cachedLinkId = localStorage.getItem('instacart_link_id');
    const cachedShoppingList = localStorage.getItem('instacart_shopping_list');
    const cachedMealPlanId = localStorage.getItem('instacart_meal_plan_id');
    const currentMealPlanId = mealPlan?.id || null;

    console.log('DEBUG: Checking cached data:', {
      hasCachedLink: !!cachedLink,
      hasCachedLinkId: !!cachedLinkId,
      hasCachedShoppingList: !!cachedShoppingList,
      cachedMealPlanId,
      currentMealPlanId
    });

    // If we have a cached link and the shopping list hasn't changed, reuse it
    if (cachedLink && cachedLinkId && cachedShoppingList && 
        ((cachedMealPlanId === currentMealPlanId) || (!cachedMealPlanId && !currentMealPlanId))) {
      try {
        const previousList = JSON.parse(cachedShoppingList);
        const currentListString = JSON.stringify(filteredShoppingList);
        const previousListString = JSON.stringify(previousList);

        console.log('DEBUG: Comparing shopping lists:', {
          currentListLength: currentListString.length,
          previousListLength: previousListString.length,
          areEqual: currentListString === previousListString,
          mealPlanMatch: cachedMealPlanId === currentMealPlanId
        });

        if (currentListString === previousListString) {
          console.log('DEBUG: Reusing existing Instacart link');
          setShowInstacartModal(true);
          return;
        } else {
          console.log('DEBUG: Shopping list has changed, generating new link');
        }
      } catch (error) {
        console.error('DEBUG: Error comparing shopping lists:', error);
        // If there's an error parsing the cached list, proceed with generating a new link
      }
    } else {
      console.log('DEBUG: No valid cached data found, generating new link', {
        hasCachedLink: !!cachedLink,
        hasCachedLinkId: !!cachedLinkId,
        hasCachedShoppingList: !!cachedShoppingList,
        mealPlanMatch: cachedMealPlanId === currentMealPlanId
      });
    }
    
    // Show loading toast
    const loadingToast = toast.loading('Preparing your shopping list...');
    
    try {
      // Generate the Instacart link
      const productsLinkUrl = await createInstacartProductsLink(filteredShoppingList);
      
      // Save the link to the database
      const savedLink = await saveInstacartLink(
        user?.id || null,
        currentMealPlanId,
        productsLinkUrl,
        user ? null : localStorage.getItem('easymeals_session_id') || Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
      );
      
      // Cache the new link and shopping list
      localStorage.setItem('instacart_link', productsLinkUrl);
      localStorage.setItem('instacart_link_id', savedLink.id);
      localStorage.setItem('instacart_shopping_list', JSON.stringify(filteredShoppingList));
      localStorage.setItem('instacart_meal_plan_id', currentMealPlanId || '');
      
      toast.dismiss(loadingToast);
      setShowInstacartModal(true);
    } catch (error) {
      console.error('Error generating Instacart link:', error);
      toast.dismiss(loadingToast);
      toast.error('Failed to generate Instacart link. Please try again.');
    }
  };

  if (isGeneratingShoppingList || isUpdatingShoppingList) {
    return (
      <div className="container max-w-4xl mx-auto py-8 px-4">
        <div className="flex flex-col items-center justify-center min-h-[60vh] space-y-8">
          <motion.div
            animate={{ rotate: 360 }}
            transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
          >
            <RiRefreshLine className="w-12 h-12 text-primary" />
          </motion.div>
          <LoadingQuote />
        </div>
      </div>
    );
  }

  if (!mealPlan || !shoppingList) {
    return (
      <div className="max-w-4xl mx-auto py-12 text-center">
        <RiShoppingCart2Line className="text-6xl text-foggy mx-auto mb-4" />
        <h2 className="text-2xl font-bold text-dark mb-4">
          No Shopping List Yet
        </h2>
        <p className="text-foggy mb-8">
          Create a meal plan first to generate your shopping list
        </p>
        <div className="flex justify-center">
          <button
            onClick={() => navigate('/meal-plan')}
            className="btn-primary flex items-center gap-2"
          >
            <RiCalendarLine />
            <span>Create Meal Plan</span>
          </button>
        </div>
      </div>
    );
  }

  if (needsShoppingListUpdate) {
    return (
      <div className="max-w-4xl mx-auto py-8 sm:py-12 px-4">
        <div className="card bg-light/50 border-2 border-dashed border-foggy/30 mb-8">
          <div className="text-center py-6 sm:py-8">
            <RiShoppingCart2Line className="text-3xl sm:text-4xl text-foggy mx-auto mb-3 sm:mb-4" />
            <h2 className="text-lg sm:text-xl font-medium text-dark mb-2">
              Shopping List Needs Updating
            </h2>
            <p className="text-sm sm:text-base text-foggy mb-6 max-w-md mx-auto px-4">
              Updates were made to preferences or the meal plan that require an update to the shopping list.
            </p>
            <div className="flex flex-col sm:flex-row items-stretch sm:items-center justify-center gap-2 sm:gap-3 px-4">
              <button
                onClick={cancelModifications}
                className="btn-secondary flex-1 sm:flex-initial flex items-center justify-center gap-2 py-2.5 sm:py-3 text-sm sm:text-base min-w-[160px]"
              >
                <RiCloseLine className="text-lg sm:text-xl" />
                Cancel Changes
              </button>
              <button
                onClick={() => finalizeMealPlan()}
                className="btn-primary flex-1 sm:flex-initial flex items-center justify-center gap-1.5 py-2.5 sm:py-3 text-sm sm:text-base min-w-[160px]"
              >
                <RiRefreshLine className="text-lg sm:text-xl" />
                Update Shopping List
              </button>
            </div>
          </div>
        </div>
        
        <div>
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-xl sm:text-2xl font-bold text-dark">Current Shopping List</h1>
          </div>
          
          <div className="card opacity-50 pointer-events-none">
            <div className="flex items-center justify-between mb-6">
              <button
                disabled
                className="text-sm text-foggy opacity-50 cursor-not-allowed"
              >
                {allChecked ? 'Uncheck All' : 'Check All'}
              </button>
            </div>

            <div className="space-y-8">
              {Object.entries(shoppingList).map(([category, items]) => {
                if (!Array.isArray(items) || items.length === 0) return null;

                return (
                  <div key={category} className="space-y-4">
                    <h3 className="font-medium text-dark">{category}</h3>
                    <div className="space-y-2">
                      {items.map((item, index) => {
                        if (!item || !item.item) return null;
                        const itemKey = `${category}-${index}-${item.item}`;
                        const capitalizedItem = capitalizeItem(item.item);

                        return (
                          <div
                            key={itemKey}
                            className="flex items-start gap-3 p-3 rounded-lg"
                          >
                            <input
                              type="checkbox"
                              checked={checkedItems[itemKey] || false}
                              disabled
                              className="mt-1 rounded border-light text-primary focus:ring-primary"
                            />
                            <div className="flex-1">
                              <div className="flex items-baseline gap-2">
                                <span className="text-dark">{capitalizedItem}</span>
                                <span className="text-primary font-medium text-sm">{item.quantity}</span>
                              </div>
                              {item.notes && (
                                <p className="text-sm text-foggy mt-1">{item.notes}</p>
                              )}
                              {item.usedIn && Array.isArray(item.usedIn) && item.usedIn.length > 0 && (
                                <p className="text-xs text-foggy mt-1">
                                  Used in: {item.usedIn.join(', ')}
                                </p>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }

  const CategorySummary = ({ category, items }) => {
    if (!Array.isArray(items) || items.length === 0 || category.toLowerCase() === 'warnings') return null;

    const isExpanded = expandedCategories.has(category);

    // Calculate number of checked items
    const checkedCount = items.reduce((count, item, index) => {
      const itemKey = `${category}-${index}-${item.item}`;
      return checkedItems[itemKey] ? count + 1 : count;
    }, 0);

    return (
      <div className="bg-white rounded-lg border border-light">
        {/* Header - Always visible */}
        <div className="border-b border-light p-4">
          <div className="flex items-center justify-between">
            <h3 className="text-sm font-medium text-dark">{category}</h3>
            <button 
              onClick={() => handleCategoryToggle(category)}
              className="text-xs text-primary font-medium bg-primary/10 px-2 py-0.5 rounded-full hover:bg-primary/20 transition-colors"
            >
              {checkedCount} {checkedCount === 1 ? 'item' : 'items'}
            </button>
          </div>
        </div>

        {/* Content with conditional animation only when toggling category */}
        {isExpanded ? (
          <div className="border-t border-light">
            <div className="divide-y divide-light">
              {items.map((item, index) => {
                if (!item || !item.item) return null;
                const itemKey = `${category}-${index}-${item.item}`;
                const capitalizedItem = capitalizeItem(item.item);

                return (
                  <div key={itemKey} className="hover:bg-light/50 transition-colors">
                    <div className="p-4">
                      <label className="flex items-start gap-3 cursor-pointer">
                        <div className="relative flex items-center justify-center mt-1">
                          <input
                            type="checkbox"
                            checked={checkedItems[itemKey] || false}
                            onChange={() => handleToggleItem(itemKey)}
                            className="w-4 h-4 rounded border-light text-primary focus:ring-primary"
                          />
                        </div>
                        <div className="flex-1 min-w-0">
                          <div className="flex items-baseline gap-2 flex-wrap">
                            <span className="text-sm sm:text-base text-dark break-words">{capitalizedItem}</span>
                            <span className="text-xs sm:text-sm text-foggy">{item.quantity}</span>
                          </div>
                          {item.notes && (
                            <p className="text-xs sm:text-sm text-foggy mt-1">{item.notes}</p>
                          )}
                          {item.usedIn && Array.isArray(item.usedIn) && item.usedIn.length > 0 && (
                            <p className="text-xs text-foggy mt-1">
                              Used in: {item.usedIn.join(', ')}
                            </p>
                          )}
                        </div>
                      </label>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div className="border-t border-light">
            <div className="px-4 py-3">
              <p className="text-xs text-foggy">
                {items.slice(0, 3).map(item => capitalizeItem(item.item)).join(', ')}
                {items.length > 3 && `, +${items.length - 3} more`}
              </p>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="container max-w-4xl mx-auto pt-0 sm:pt-4 px-4">
        <div className="flex flex-col gap-6 pb-56">
          {/* Header */}
          <div>
            <div className="flex items-start sm:items-center gap-2.5 sm:gap-3 mb-3 sm:mb-4">
              <RiShoppingCart2Line className="hidden sm:block w-8 h-8 sm:w-10 sm:h-10 text-primary mt-0.5 sm:mt-0" />
              <div>
                <h1 className="text-2xl sm:text-2xl font-bold text-dark leading-tight">Shopping List</h1>
                <p className="text-sm sm:text-base text-foggy mt-0.5 sm:mt-1">Below is a shopping list with everything you need for your meal plan! If taking your shopping list to Instacart, you can uncheck any items you don't need before clicking on 'Shop with Instacart'.</p>
              </div>
            </div>
          </div>

          {/* Meal Plan Summary */}
          <div className="card bg-light/50">
            <div className="flex flex-col gap-3">
              <div className="flex items-center gap-2">
                <RiUserSettingsLine className="w-5 h-5 text-foggy" />
                <span className="text-dark text-sm sm:text-base">Shopping for {mealPlan?.preferences?.peopleCount || 1} {mealPlan?.preferences?.peopleCount === 1 ? 'person' : 'people'}</span>
              </div>
              <div className="grid grid-cols-2 sm:flex sm:flex-wrap gap-2 sm:gap-4">
                {Object.entries(getMealsByType(mealPlan?.days)).map(([type, meals]) => {
                  const totalMeals = meals.reduce((sum, meal) => sum + (meal.frequency || 0), 0);
                  return (
                    <div key={type} className="flex items-center gap-1.5">
                      <span className="text-xs sm:text-sm text-foggy capitalize">{type}:</span>
                      <span className="text-xs sm:text-sm text-primary font-medium">{totalMeals} meals</span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          {/* View Mode Toggle */}
          <div className="bg-white rounded-lg border border-light p-2.5 mb-4">
            <div className="flex-1">
              <ViewModeToggle />
            </div>
          </div>

          <div className="card">
            <div className="flex items-center justify-between mb-4 sm:mb-6">
              <button
                onClick={handleToggleAll}
                className="text-xs sm:text-sm text-foggy hover:text-primary transition-colors"
              >
                {allChecked ? 'Uncheck All' : 'Check All'}
              </button>
            </div>

            <div className="space-y-6 sm:space-y-8">
              {Object.entries(shoppingList).map(([category, items]) => (
                <CategorySummary key={category} category={category} items={items} />
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Sticky Footer */}
      <StickyActions>
        <div className="flex flex-col sm:flex-row gap-2 sm:gap-3 sm:max-w-3xl sm:mx-auto w-full">
          {user ? (
            <>
              <InstacartButton
                onClick={handleInstacartClick}
                className="w-full"
                mealPlanId={mealPlan?.id}
              />
              <button
                onClick={handleExport}
                className="w-full btn-secondary flex items-center justify-center gap-2 py-3 sm:whitespace-nowrap"
              >
                <RiDownloadLine className="text-xl" />
                <span>Export Shopping List</span>
              </button>
            </>
          ) : (
            <>
              <InstacartButton
                onClick={handleInstacartClick}
                className="w-full"
                mealPlanId={mealPlan?.id}
              />
              <button
                onClick={handleSaveClick}
                className="w-full btn-secondary flex items-center justify-center gap-2 py-3 sm:whitespace-nowrap"
              >
                <RiSaveLine className="text-xl" />
                <span>Save Shopping List</span>
              </button>
            </>
          )}
        </div>
      </StickyActions>

      <SaveShoppingListModal 
        isOpen={showSaveModal}
        onClose={() => {
          console.log('Closing save modal');
          setShowSaveModal(false);
        }}
        onExport={() => {
          console.log('Exporting from modal');
          handleExport();
          setShowSaveModal(false);
        }}
      />

      <InstacartModal 
        isOpen={showInstacartModal} 
        onClose={() => setShowInstacartModal(false)} 
        shoppingList={getFilteredShoppingList()} 
        mealPlanId={mealPlan?.id} 
      />
    </>
  );
};

export default ShoppingList;