import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { RiChat1Line } from 'react-icons/ri';
import FeedbackModal from './FeedbackModal';

const FeedbackButton = ({ isOpen: externalIsOpen, onClose: externalOnClose }) => {
  const [internalIsOpen, setInternalIsOpen] = useState(false);

  // Use external state if provided, otherwise use internal state
  const isOpen = externalIsOpen !== undefined ? externalIsOpen : internalIsOpen;
  const handleClose = () => {
    if (externalOnClose) {
      externalOnClose();
    } else {
      setInternalIsOpen(false);
    }
  };

  return (
    <>
      {/* Feedback Button - Only show on desktop */}
      {!externalIsOpen && (
        <motion.button
          onClick={() => setInternalIsOpen(true)}
          className="fixed right-4 bottom-[8.5rem] md:bottom-8 bg-white text-orange-400 border-2 border-orange-400 w-12 h-12 rounded-full shadow-level2 flex items-center justify-center hover:bg-orange-50 transition-colors z-40 hidden md:flex"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <RiChat1Line className="text-xl" />
        </motion.button>
      )}

      {/* Use the improved FeedbackModal component */}
      <FeedbackModal isOpen={isOpen} onClose={handleClose} />
    </>
  );
};

export default FeedbackButton;