import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import toast from 'react-hot-toast';
import { supabase } from '../config/supabase';
import { useAuth } from './AuthContext';
import { PREFERENCES_MESSAGES } from '../utils/toastMessages';

const PreferencesContext = createContext({});

export const usePreferences = () => {
  return useContext(PreferencesContext);
};

export const PreferencesProvider = ({ children }) => {
  const [preferences, setPreferences] = useState({
    peopleCount: 1,
    cuisinePreferences: [],
    dietaryRestrictions: [],
    allergens: [],
    mealPreparationTime: 'medium',
    varietyLevel: 'more',
    mealTypes: ['all'],
    email: ''
  });

  const [lastSubmittedPreferences, setLastSubmittedPreferences] = useState(null);
  const [isModifiedSinceMealPlan, setIsModifiedSinceMealPlan] = useState(false);
  const [needsRevert, setNeedsRevert] = useState(false);

  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      loadUserPreferences();
    }
  }, [user]);

  useEffect(() => {
    if (user && preferences) {
      const savePreferencesAfterSignup = async () => {
        try {
          const { data, error } = await supabase
            .from('user_preferences')
            .select('preferences_json')
            .eq('user_id', user.id)
            .maybeSingle();

          // Only proceed if no data exists and no error (other than no rows)
          if ((!data || !data.preferences_json) && (!error || error.code === 'PGRST116')) {
            await savePreferences(preferences);
            console.log('Preferences saved after signup');
          }
        } catch (error) {
          console.error('Error saving preferences after signup:', error);
          // Don't show error toast here as it's handled in savePreferences
        }
      };

      savePreferencesAfterSignup();
    }
  }, [user, preferences]);

  const loadUserPreferences = async () => {
    try {
      if (!supabase || typeof supabase.from !== 'function') {
        console.error('Invalid supabase client:', supabase);
        throw new Error('Invalid supabase client');
      }

      const { data, error } = await supabase
        .from('user_preferences')
        .select('preferences_json')
        .eq('user_id', user.id)
        .maybeSingle();

      if (error && error.code !== 'PGRST116') throw error;
      
      if (data?.preferences_json) {
        setPreferences(data.preferences_json);
        setLastSubmittedPreferences(data.preferences_json);
        console.log('Preferences loaded successfully:', data.preferences_json);
      } else {
        console.log('No saved preferences found for user');
      }
    } catch (error) {
      console.error('Error loading preferences:', error);
      toast.error('Failed to load preferences');
    }
  };

  const validatePreferences = (prefs) => {
    if (!prefs.peopleCount || prefs.peopleCount < 1 || prefs.peopleCount > 10) {
      throw new Error('Number of people must be between 1 and 10');
    }
    return true;
  };

  const checkForSignificantChanges = (newPrefs, oldPrefs) => {
    if (!oldPrefs) return false;
    
    // Check for changes that would affect meal plan
    const hasSignificantChanges = (
      newPrefs.peopleCount !== oldPrefs.peopleCount ||
      JSON.stringify(newPrefs.dietaryRestrictions) !== JSON.stringify(oldPrefs.dietaryRestrictions) ||
      JSON.stringify(newPrefs.allergens) !== JSON.stringify(oldPrefs.allergens) ||
      newPrefs.mealPreparationTime !== oldPrefs.mealPreparationTime
    );

    if (hasSignificantChanges) {
      setNeedsRevert(true);
    }

    return hasSignificantChanges;
  };

  const revertPreferences = useCallback(() => {
    if (lastSubmittedPreferences) {
      // Create a new object to ensure state update triggers
      const restoredPreferences = JSON.parse(JSON.stringify(lastSubmittedPreferences));
      // Update all states synchronously
      setPreferences(restoredPreferences);
      setNeedsRevert(false);
      setIsModifiedSinceMealPlan(false);
      // Return the restored preferences for immediate use
      return restoredPreferences;
    }
    return null;
  }, [lastSubmittedPreferences]);

  const savePreferences = async (newPreferences) => {
    try {
      validatePreferences(newPreferences);

      if (user) {
        // Upsert approach - will update if exists, insert if doesn't exist
        const { error } = await supabase
          .from('user_preferences')
          .upsert({
            user_id: user.id,
            preferences_json: newPreferences
          }, {
            onConflict: 'user_id'  // This tells Supabase to update on user_id conflict
          });

        if (error) throw error;
      }

      setPreferences(newPreferences);
      setLastSubmittedPreferences(newPreferences);
      setIsModifiedSinceMealPlan(false);
      setNeedsRevert(false);
      return true;
    } catch (error) {
      console.error('Error saving preferences:', error);
      toast.error(PREFERENCES_MESSAGES.SAVE_ERROR(error.message));
      return false;
    }
  };

  const value = {
    preferences,
    setPreferences,
    savePreferences,
    revertPreferences,
    isModifiedSinceMealPlan,
    needsRevert,
    loadUserPreferences
  };

  return (
    <PreferencesContext.Provider value={value}>
      {children}
    </PreferencesContext.Provider>
  );
};