import React from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import Welcome from './pages/Welcome';
import Preferences from './pages/Preferences';
import MealPlan from './pages/MealPlan';
import ShoppingList from './pages/ShoppingList';
import SavedMealPlans from './pages/SavedMealPlans';
import Navigation from './components/Navigation';
import FeedbackButton from './components/FeedbackButton';
import { AuthProvider } from './contexts/AuthContext';
import { PreferencesProvider } from './contexts/PreferencesContext';
import { MealPlanProvider } from './contexts/MealPlanContext';
import { ViewModeProvider } from './contexts/ViewModeContext';

export const App = () => {
  return (
    <Router>
      <AuthProvider>
        <PreferencesProvider>
          <MealPlanProvider>
            <ViewModeProvider>
              <div className="min-h-screen bg-gray-50">
                <Navigation />
                <main className="container mx-auto px-4 mt-14 sm:mt-[4.5rem]">
                  <Routes>
                    <Route path="/" element={<Welcome />} />
                    <Route path="/preferences" element={<Preferences />} />
                    <Route path="/meal-plan" element={<MealPlan />} />
                    <Route path="/shopping-list" element={<ShoppingList />} />
                    <Route path="/saved-meal-plans" element={<SavedMealPlans />} />
                  </Routes>
                </main>
                <FeedbackButton />
                <Toaster position="bottom-center" />
              </div>
            </ViewModeProvider>
          </MealPlanProvider>
        </PreferencesProvider>
      </AuthProvider>
    </Router>
  );
};

export default App;