import React, { createContext, useContext, useState, useEffect } from 'react';
import { supabase } from '../config/supabase';
import { useAnalytics } from '../hooks/useAnalytics';

const AuthContext = createContext({});

export const useAuth = () => {
  return useContext(AuthContext);
};

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [highlightSavedPlans, setHighlightSavedPlans] = useState(false);
  const { trackEvent } = useAnalytics();

  useEffect(() => {
    // Check active sessions and sets the user
    const session = supabase.auth.getSession();
    setUser(session?.user ?? null);
    setLoading(false);

    // Listen for changes on auth state (sign in, sign out, etc.)
    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      setUser(session?.user ?? null);
      setLoading(false);
    });

    return () => subscription.unsubscribe();
  }, []);

  const signUp = async (email, password) => {
    try {
      trackEvent('signup_attempt', { method: 'email' });
      
      const { data, error } = await supabase.auth.signUp({
        email,
        password,
      });
      if (error) throw error;
      
      console.log('User created successfully:', data);
      setHighlightSavedPlans(true);
      // Clear highlight after 5 seconds
      setTimeout(() => setHighlightSavedPlans(false), 5000);
      
      trackEvent('signup_success', { 
        method: 'email',
        user_id: data.user.id
      });
      
      return data;
    } catch (error) {
      console.error('Error creating user:', error);
      trackEvent('signup_error', { 
        method: 'email',
        error: error.message
      });
      throw error;
    }
  };

  const signIn = async (email, password) => {
    try {
      trackEvent('signin_attempt', { method: 'email' });
      
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });
      if (error) throw error;
      
      console.log('User signed in successfully:', data);
      trackEvent('signin_success', { 
        method: 'email',
        user_id: data.user.id
      });
      
      return data;
    } catch (error) {
      console.error('Error signing in:', error);
      trackEvent('signin_error', { 
        method: 'email',
        error: error.message
      });
      throw error;
    }
  };

  const signOut = async () => {
    try {
      trackEvent('signout_attempt');
      
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      
      console.log('User signed out successfully');
      trackEvent('signout_success');
    } catch (error) {
      console.error('Error signing out:', error);
      trackEvent('signout_error', { 
        error: error.message
      });
      throw error;
    }
  };

  const value = {
    user,
    loading,
    signUp,
    signIn,
    signOut,
    highlightSavedPlans,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}