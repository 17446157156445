import React, { createContext, useContext, useState, useEffect } from 'react';

const ViewModeContext = createContext();

export const VIEW_MODES = {
  AT_A_GLANCE: 'at-a-glance',
  DETAILED: 'detailed'
};

export function ViewModeProvider({ children }) {
  const [viewMode, setViewMode] = useState(() => {
    const saved = localStorage.getItem('viewMode');
    return saved || VIEW_MODES.AT_A_GLANCE;
  });

  const [viewType, setViewType] = useState(() => {
    const saved = localStorage.getItem('viewType');
    return saved || 'type';
  });

  useEffect(() => {
    localStorage.setItem('viewMode', viewMode);
  }, [viewMode]);

  useEffect(() => {
    localStorage.setItem('viewType', viewType);
  }, [viewType]);

  const value = {
    viewMode,
    setViewMode,
    viewType,
    setViewType,
    isAtAGlance: viewMode === VIEW_MODES.AT_A_GLANCE,
    isDetailed: viewMode === VIEW_MODES.DETAILED
  };

  return (
    <ViewModeContext.Provider value={value}>
      {children}
    </ViewModeContext.Provider>
  );
}

export function useViewMode() {
  const context = useContext(ViewModeContext);
  if (context === undefined) {
    throw new Error('useViewMode must be used within a ViewModeProvider');
  }
  return context;
} 