import React from 'react';
import { RiAlertLine } from 'react-icons/ri';
import { motion, AnimatePresence } from 'framer-motion';

const ModificationWarningModal = ({ isOpen, onKeepModifying, onCancel }) => {
  if (!isOpen) return null;

  return (
    <AnimatePresence>
      <div className="fixed inset-0 bg-dark/50 flex items-center justify-center p-4 z-50">
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.95 }}
          className="bg-white rounded-xl shadow-xl max-w-md w-full p-6"
        >
          <div className="flex items-center gap-3 mb-4">
            <div className="bg-warning/10 p-2 rounded-lg">
              <RiAlertLine className="text-2xl text-warning" />
            </div>
            <h2 className="text-xl font-semibold text-dark">
              Making modifications to your meal plan?
            </h2>
          </div>
          
          <p className="text-foggy mb-6">
            We'll need to update the shopping list to ensure it supports the new meal plan. 
            Once you are done, simply hit "update shopping list" or you can cancel to keep 
            the current shopping list.
          </p>
          
          <div className="flex gap-3 justify-end">
            <button
              onClick={onCancel}
              className="btn-secondary"
            >
              Cancel
            </button>
            <button
              onClick={onKeepModifying}
              className="btn-primary"
            >
              Keep Modifying
            </button>
          </div>
        </motion.div>
      </div>
    </AnimatePresence>
  );
};

export default ModificationWarningModal; 