import React from 'react';
import { 
  RiCloudLine,
  RiThumbUpLine,
  RiShoppingCart2Line,
  RiStarLine
} from 'react-icons/ri';

const BENEFITS = [
  {
    title: 'Save Your Meal Plans & Shopping Lists',
    description: 'Access your meal plans and shopping lists anytime, anywhere!',
    icon: RiCloudLine
  },
  {
    title: 'Personalized Recommendations',
    description: 'Get better meal suggestions as Easy Meals learns what you like',
    icon: RiThumbUpLine
  },
  {
    title: 'Instacart Integration',
    description: 'Coming soon: Order groceries directly from your shopping list',
    icon: RiShoppingCart2Line
  },
  {
    title: 'More Helpful Benefits',
    description: 'Be the first to access new features as we release them',
    icon: RiStarLine
  }
];

const BenefitsDisplay = ({ className = '' }) => {
  return (
    <div className={`grid grid-cols-1 md:grid-cols-2 gap-2.5 md:gap-3 ${className}`}>
      {BENEFITS.map((benefit, index) => {
        const Icon = benefit.icon;
        return (
          <div
            key={index}
            className="flex items-start gap-3 p-2.5 md:p-3 bg-primary/5 rounded-lg"
          >
            <div className="flex-shrink-0 w-7 h-7 md:w-8 md:h-8 rounded-full bg-primary/10 flex items-center justify-center">
              <Icon className="text-primary text-base md:text-lg" />
            </div>
            <div className="min-w-0">
              <h3 className="text-sm font-medium text-dark leading-tight">
                {benefit.title}
              </h3>
              <p className="text-xs text-foggy mt-0.5 leading-snug">
                {benefit.description}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default BenefitsDisplay; 