import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  RiTimeLine, 
  RiArrowDownSLine, 
  RiAddLine, 
  RiSubtractLine,
  RiInformationLine,
  RiRepeatLine
} from 'react-icons/ri';
import { useMealPlan } from '../contexts/MealPlanContext';
import { useViewMode, VIEW_MODES } from '../contexts/ViewModeContext';

const MealCard = ({ meal, mealType, totalMeals, onReachTarget }) => {
  const { updateMealFrequency } = useMealPlan();
  const { viewMode } = useViewMode();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [isError, setIsError] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showRedPlus, setShowRedPlus] = useState(false);

  useEffect(() => {
    // Automatically expand/collapse based on view mode
    setIsExpanded(viewMode === VIEW_MODES.DETAILED);
  }, [viewMode]);

  const handleCardClick = (e) => {
    // Don't expand if clicking frequency controls
    if (e.target.closest('.frequency-controls')) return;
    setIsExpanded(!isExpanded);
  };
  
  const handleFrequencyChange = (change) => {
    const newFrequency = (meal.frequency || 0) + change;
    const newTotal = totalMeals + change;

    if (change > 0 && totalMeals >= 7) {
      setIsError(true);
      setShowWarning(false);
      setIsSaved(false);
      setShowRedPlus(true);
      setShowTooltip(true);
      setTimeout(() => {
        setShowTooltip(false);
      }, 1500);
      return;
    }

    if (newFrequency >= 0) {
      if (newTotal < 7) {
        setShowWarning(true);
        setIsSaved(false);
        setIsError(false);
      } else if (newTotal === 7) {
        setIsSaved(true);
        setShowWarning(false);
        setIsError(false);
      }
      updateMealFrequency(mealType, meal.name, newFrequency);
    }
  };

  useEffect(() => {
    if (isError || showWarning || isSaved || showRedPlus) {
      const timer = setTimeout(() => {
        setIsError(false);
        setShowWarning(false);
        setIsSaved(false);
        setShowRedPlus(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [isError, showWarning, isSaved, showRedPlus]);

  const getBorderColor = () => {
    if (isError) return '#ef4444';
    if (showWarning) return '#f97316';
    if (isSaved) return '#22c55e';
    return '#f1f5f9';
  };

  const renderFrequencyControls = () => (
    <div className="frequency-controls flex items-center gap-2">
      <motion.button
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        onClick={(e) => {
          e.stopPropagation();
          handleFrequencyChange(-1);
        }}
        className="p-1.5 text-foggy hover:text-primary transition-colors bg-light/50 rounded-full shadow-sm"
        aria-label="Decrease frequency"
        disabled={meal.frequency <= 0}
      >
        <RiSubtractLine className={`text-lg ${meal.frequency <= 0 ? 'opacity-50' : ''}`} />
      </motion.button>
      <motion.span
        key={meal.frequency}
        initial={{ scale: 0.8 }}
        animate={{ scale: 1 }}
        className="w-5 text-center font-medium text-dark text-sm"
      >
        {meal.frequency || 0}
      </motion.span>
      <div className="relative">
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={(e) => {
            e.stopPropagation();
            handleFrequencyChange(1);
          }}
          onMouseEnter={() => {
            if (totalMeals >= 7) {
              setShowTooltip(true);
              setTimeout(() => {
                setShowTooltip(false);
              }, 1500);
            }
          }}
          onMouseLeave={() => setShowTooltip(false)}
          className={`p-1.5 transition-colors bg-light/50 rounded-full shadow-sm ${
            showRedPlus ? 'text-red-500' : totalMeals >= 7 ? 'hover:text-red-500' : 'text-foggy hover:text-primary'
          }`}
          aria-label="Increase frequency"
        >
          <RiAddLine className="text-lg" />
        </motion.button>
        <AnimatePresence>
          {showTooltip && totalMeals >= 7 && (
            <motion.div
              initial={{ opacity: 0, y: 5 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 5 }}
              className="absolute right-0 bottom-full mb-2 w-48 px-2.5 py-1.5 bg-red-500 text-white text-xs rounded shadow-lg z-50"
            >
              Too many meals. Remove another meal first!
              <div className="absolute bottom-0 right-4 transform translate-y-1/2 rotate-45 w-2 h-2 bg-red-500" />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );

  const renderHeader = () => (
    <div className="flex items-center justify-between p-2.5 gap-3">
      <h3 className="text-sm font-medium text-dark break-words flex-1">{meal.name}</h3>
      <div className="flex items-center gap-3 shrink-0">
        <div className="flex items-center text-foggy text-xs">
          <RiTimeLine className="mr-1" />
          <span>{meal.prepTime.split(' ')[0]}m</span>
        </div>
        {renderFrequencyControls()}
      </div>
    </div>
  );

  const renderExpandedContent = () => (
    <div className="px-3 pt-2 pb-3 space-y-4">
      {/* Description */}
      <p className="text-sm text-foggy leading-relaxed">{meal.description}</p>

      {/* Notes (if available) */}
      {meal.notes && (
        <div className="p-2.5 bg-[#E3F2FD] rounded-md flex items-start gap-2">
          <RiInformationLine className="text-[#1976D2] shrink-0 mt-0.5 text-lg" />
          <p className="text-sm text-[#0D47A1] leading-relaxed">{meal.notes}</p>
        </div>
      )}

      {/* Macros (if available) */}
      {meal.macros && (
        <div className="flex items-center gap-4 text-sm text-foggy">
          <span>Protein: {meal.macros.protein}g</span>
          <span>Carbs: {meal.macros.carbs}g</span>
          <span>Fat: {meal.macros.fat}g</span>
        </div>
      )}

      {/* Ingredients (if available) */}
      {meal.ingredients?.length > 0 && (
        <div>
          <h4 className="text-sm font-medium text-dark mb-2">Ingredients</h4>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-1.5">
            {meal.ingredients.map((ingredient, index) => (
              <div 
                key={index}
                className="text-sm text-foggy bg-light/50 px-3 py-1.5 rounded-md"
              >
                {ingredient}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );

  return (
    <motion.div 
      layout
      animate={{
        borderColor: getBorderColor(),
        transition: { duration: 0.3 }
      }}
      onClick={handleCardClick}
      className={`cursor-pointer border-2 rounded-lg bg-white shadow-sm hover:border-primary/20 transition-colors ${
        isExpanded ? 'border-primary/20' : ''
      }`}
    >
      {renderHeader()}
      <AnimatePresence>
        {isExpanded && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="border-t border-light"
          >
            {renderExpandedContent()}
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default MealCard; 