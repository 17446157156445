import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { RiCloseLine, RiShoppingBasketLine, RiExternalLinkLine, RiLoader4Line, RiCheckLine } from 'react-icons/ri';
import toast from 'react-hot-toast';
import { supabase } from '../config/supabase';
import { useAuth } from '../contexts/AuthContext';
import { createInstacartProductsLink, saveInstacartLink, getInstacartLink, trackInstacartLinkClick } from '../utils/instacartService';
import InstacartButton from './InstacartButton';

// Helper function to get or create a session ID for anonymous users
const getSessionId = () => {
  let sessionId = localStorage.getItem('easymeals_session_id');
  if (!sessionId) {
    sessionId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    localStorage.setItem('easymeals_session_id', sessionId);
  }
  return sessionId;
};

const InstacartModal = ({ isOpen, onClose, shoppingList, mealPlanId }) => {
  const { user } = useAuth();
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [instacartLink, setInstacartLink] = useState(null);
  const [linkData, setLinkData] = useState(null);
  const [isGeneratingLink, setIsGeneratingLink] = useState(false);
  const sessionId = getSessionId();

  // Check for pre-generated link or existing link when the modal opens
  useEffect(() => {
    if (isOpen) {
      // First check for a pre-generated link in localStorage
      const preGeneratedLink = localStorage.getItem('instacart_link');
      const preGeneratedLinkId = localStorage.getItem('instacart_link_id');
      
      if (preGeneratedLink && preGeneratedLinkId) {
        setInstacartLink(preGeneratedLink);
        setLinkData({ 
          id: preGeneratedLinkId,
          products_link_url: preGeneratedLink,
          user_id: user?.id || null,
          meal_plan_id: mealPlanId
        });
        
        // Clear localStorage after using the link
        localStorage.removeItem('instacart_link');
        localStorage.removeItem('instacart_link_id');
      } 
      // If no pre-generated link, check for existing link for authenticated users
      else if (mealPlanId && user) {
        checkExistingLink();
      }
    }
  }, [isOpen, user, mealPlanId]);

  const checkExistingLink = async () => {
    try {
      const link = await getInstacartLink(user.id, mealPlanId);
      if (link) {
        setInstacartLink(link.products_link_url);
        setLinkData(link);
      }
    } catch (error) {
      console.error('Error checking for existing Instacart link:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const emailToUse = user ? user.email : email.trim();
    
    if (!emailToUse) {
      toast.error('Please enter your email address');
      return;
    }

    setIsSubmitting(true);
    try {
      // Try to insert directly and handle the unique constraint violation
      const { error } = await supabase
        .from('instacart_waitlist')
        .insert([{
          email: emailToUse,
          created_at: new Date().toISOString(),
          user_id: user?.id || null
        }]);

      if (error) {
        // Check if this is a unique constraint violation
        if (error.code === '23505') { // Postgres unique violation code
          toast.success("You're already on the list to get notified! Thanks for your interest.", {
            duration: 4000,
          });
          onClose();
          setEmail('');
          return;
        }
        throw error;
      }

      toast.success("Thanks for your interest! We'll notify you when Instacart integration is available.", {
        duration: 4000,
      });
      onClose();
      setEmail('');
    } catch (error) {
      console.error('Error submitting email:', error);
      toast.error('Something went wrong. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleGenerateInstacartLink = async () => {
    console.log('DEBUG: handleGenerateInstacartLink called');
    
    if (!shoppingList) {
      toast.error('No shopping list available to send to Instacart');
      return;
    }

    setIsGeneratingLink(true);
    try {
      console.log('DEBUG: Generating Instacart link for shopping list:', shoppingList);
      console.log('DEBUG: Current meal plan ID:', mealPlanId);
      
      // Generate the Instacart products link
      const productsLinkUrl = await createInstacartProductsLink(shoppingList);
      console.log('DEBUG: Generated products link URL:', productsLinkUrl);
      
      // Save the link to the database
      console.log('DEBUG: Saving link with params:', { 
        userId: user?.id || null, 
        mealPlanId, 
        productsLinkUrl, 
        sessionId: user ? null : sessionId 
      });
      
      const savedLink = await saveInstacartLink(
        user?.id || null,
        mealPlanId, // Pass mealPlanId directly without null check
        productsLinkUrl,
        user ? null : sessionId
      );
      
      console.log('DEBUG: Saved link result:', savedLink);
      
      setInstacartLink(productsLinkUrl);
      setLinkData(savedLink);
      toast.success('Shopping list sent to Instacart successfully!');
    } catch (error) {
      console.error('DEBUG: Error generating Instacart link:', error);
      toast.error('Failed to send shopping list to Instacart. Please try again.');
    } finally {
      setIsGeneratingLink(false);
    }
  };

  const handleInstacartLinkClick = async () => {
    console.log('DEBUG: handleInstacartLinkClick called with linkData:', linkData);
    console.log('DEBUG: Current mealPlanId:', mealPlanId);
    
    // Track the click if we have link data
    if (linkData) {
      console.log('DEBUG: Tracking click with params:', {
        linkId: linkData.id,
        userId: user?.id || null,
        mealPlanId: mealPlanId || linkData.meal_plan_id || null,
        sessionId: user ? null : sessionId
      });
      
      try {
        const result = await trackInstacartLinkClick(
          linkData.id,
          user?.id || null,
          mealPlanId || linkData.meal_plan_id || null,
          user ? null : sessionId
        );
        
        console.log('DEBUG: Click tracking result:', result);
        
        if (!result) {
          console.error('DEBUG: Click tracking failed - no result returned');
        }
      } catch (error) {
        console.error('DEBUG: Error tracking click:', error);
      }
    } else {
      console.warn('DEBUG: No linkData available for click tracking');
    }
  };

  // Test function to diagnose database connection issues
  const testDatabaseConnection = async () => {
    try {
      console.log('TEST: Starting database test...');
      
      // Generate unique test values
      const testTimestamp = Date.now();
      const testUrl = `https://www.instacart.com/test-${testTimestamp}`;
      const testSessionId = `test-session-${testTimestamp}`;
      
      console.log('TEST: Attempting to insert into instacart_links...');
      const { data: linkData, error: linkError } = await supabase
        .from('instacart_links')
        .insert([{
          products_link_url: testUrl,
          is_anonymous: true,
          session_id: testSessionId,
          status: 'test'
        }])
        .select();
      
      console.log('TEST: Link insert result:', { linkData, linkError });
      
      if (linkError) {
        console.error('TEST: Link insert error:', linkError);
        alert(`Link insert error: ${linkError.message}`);
        return;
      }
      
      if (!linkData || linkData.length === 0) {
        console.error('TEST: No link data returned');
        alert('No link data returned');
        return;
      }
      
      console.log('TEST: Successfully inserted link with ID:', linkData[0].id);
      alert(`Successfully inserted link with ID: ${linkData[0].id}`);
      
      // Now try to insert a click
      console.log('TEST: Attempting to insert into instacart_link_clicks...');
      const { data: clickData, error: clickError } = await supabase
        .from('instacart_link_clicks')
        .insert([{
          link_id: linkData[0].id,
          is_anonymous: true,
          session_id: testSessionId,
          clicked_at: new Date().toISOString()
        }])
        .select();
      
      console.log('TEST: Click insert result:', { clickData, clickError });
      
      if (clickError) {
        console.error('TEST: Click insert error:', clickError);
        alert(`Click insert error: ${clickError.message}`);
        return;
      }
      
      console.log('TEST: Successfully inserted click with ID:', clickData[0].id);
      alert(`Successfully inserted click with ID: ${clickData[0].id}`);
      
    } catch (e) {
      console.error('TEST: Exception during test:', e);
      alert(`Test exception: ${e.message}`);
    }
  };

  if (!isOpen) return null;

  return (
    <AnimatePresence>
      <div className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50" onClick={onClose}>
        {/* Mobile View */}
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 20, opacity: 0 }}
          transition={{ type: 'spring', damping: 25, stiffness: 300 }}
          className="fixed inset-x-0 bottom-0 bg-white rounded-t-[2rem] p-6 md:hidden"
          onClick={e => e.stopPropagation()}
        >
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-bold text-dark">
              Your shopping list is ready!
            </h2>
            <button
              onClick={onClose}
              className="p-2 hover:bg-light/80 rounded-full transition-colors group"
              aria-label="Close modal"
            >
              <RiCloseLine className="text-xl text-foggy group-hover:text-dark transition-colors" />
            </button>
          </div>
          
          <div className="space-y-4">
            {instacartLink ? (
              <>
                <div className="bg-green-50 border border-green-200 rounded-lg p-4 mb-4">
                  <div className="flex items-start gap-3">
                    <RiShoppingBasketLine className="text-green-600 text-xl flex-shrink-0 mt-0.5" />
                    <p className="text-sm text-green-700">
                      Click the button below to view and purchase your meal plan ingredients directly through Instacart.
                    </p>
                  </div>
                </div>
                
                <div className="flex justify-center">
                  <InstacartButton
                    href={instacartLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={handleInstacartLinkClick}
                  >
                    <RiExternalLinkLine className="mr-2" />
                    Shop with Instacart
                  </InstacartButton>
                </div>
              </>
            ) : (
              <>
                <div className="bg-green-50 border border-green-200 rounded-lg p-4 mb-4">
                  <div className="flex items-start gap-3">
                    <RiShoppingBasketLine className="text-green-600 text-xl flex-shrink-0 mt-0.5" />
                    <p className="text-sm text-green-700">
                      Click the button below to view and purchase your meal plan ingredients directly through Instacart.
                    </p>
                  </div>
                </div>
                
                <div className="flex justify-center">
                  {isGeneratingLink ? (
                    <InstacartButton disabled>
                      <RiLoader4Line className="animate-spin mr-2" />
                      Generating link...
                    </InstacartButton>
                  ) : (
                    <InstacartButton onClick={handleGenerateInstacartLink}>
                      <RiShoppingBasketLine className="mr-2" />
                      Shop with Instacart
                    </InstacartButton>
                  )}
                </div>
              </>
            )}
          </div>
        </motion.div>

        {/* Desktop View */}
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.95 }}
          className="hidden md:block fixed inset-0 overflow-auto"
          onClick={e => e.stopPropagation()}
        >
          <div className="min-h-screen px-4 flex items-center justify-center">
            <div className="bg-white rounded-[1.5rem] p-6 max-w-md w-full relative shadow-xl">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-bold text-dark">
                  Your shopping list is ready!
                </h2>
                <button
                  onClick={onClose}
                  className="p-2 hover:bg-light/80 rounded-full transition-colors group"
                  aria-label="Close modal"
                >
                  <RiCloseLine className="text-xl text-foggy group-hover:text-dark transition-colors" />
                </button>
              </div>
              
              <div className="space-y-4">
                {instacartLink ? (
                  <>
                    <div className="bg-green-50 border border-green-200 rounded-lg p-4 mb-4">
                      <div className="flex items-start gap-3">
                        <RiShoppingBasketLine className="text-green-600 text-xl flex-shrink-0 mt-0.5" />
                        <p className="text-sm text-green-700">
                          Click the button below to view and purchase your meal plan ingredients directly through Instacart.
                        </p>
                      </div>
                    </div>
                    
                    <div className="flex justify-center">
                      <InstacartButton
                        href={instacartLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={handleInstacartLinkClick}
                      >
                        <RiExternalLinkLine className="mr-2" />
                        Shop with Instacart
                      </InstacartButton>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="bg-green-50 border border-green-200 rounded-lg p-4 mb-4">
                      <div className="flex items-start gap-3">
                        <RiShoppingBasketLine className="text-green-600 text-xl flex-shrink-0 mt-0.5" />
                        <p className="text-sm text-green-700">
                          Click the button below to view and purchase your meal plan ingredients directly through Instacart.
                        </p>
                      </div>
                    </div>
                    
                    <div className="flex justify-center">
                      {isGeneratingLink ? (
                        <InstacartButton disabled>
                          <RiLoader4Line className="animate-spin mr-2" />
                          Generating link...
                        </InstacartButton>
                      ) : (
                        <InstacartButton onClick={handleGenerateInstacartLink}>
                          <RiShoppingBasketLine className="mr-2" />
                          Shop with Instacart
                        </InstacartButton>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </AnimatePresence>
  );
};

export default InstacartModal; 