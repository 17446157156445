import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { RiUserSettingsLine, RiAddLine, RiSubtractLine, RiCalendarLine, RiLoader4Line, RiInformationLine } from 'react-icons/ri';
import { usePreferences } from '../contexts/PreferencesContext';
import toast from 'react-hot-toast';
import { useMealPlan } from '../contexts/MealPlanContext';
import StickyActions from '../components/StickyActions';
import { PREFERENCES_MESSAGES, MEAL_PLAN_MESSAGES } from '../utils/toastMessages';

// Add this new component for the animated number
const AnimatedNumber = ({ number }) => (
  <motion.span
    key={number}
    initial={{ scale: 0.8, opacity: 0 }}
    animate={{ scale: 1, opacity: 1 }}
    className="text-2xl font-medium text-dark min-w-[3rem] text-center block"
  >
    {number}
  </motion.span>
);

const timeDescriptions = {
  quick: '30 minutes or less',
  medium: '60 minutes or less',
  any: 'A combination of quick and medium meals or a few meals that take longer to prepare'
};

const Preferences = () => {
  const navigate = useNavigate();
  const { preferences, setPreferences, savePreferences, revertPreferences } = usePreferences();
  const { generateMealPlan, mealPlan } = useMealPlan();
  const [isLoading, setIsLoading] = React.useState(false);
  const [hasChanges, setHasChanges] = React.useState(false);

  // Add a ref to track if we're in the process of reverting
  const isReverting = React.useRef(false);

  // Track changes by comparing current preferences with meal plan preferences
  React.useEffect(() => {
    if (isReverting.current) {
      isReverting.current = false;
      return;
    }

    if (!mealPlan?.preferences || !preferences) {
      setHasChanges(false);
      return;
    }

    // Helper function to normalize arrays for comparison
    const normalizeArray = (arr) => {
      if (!arr) return [];
      if (arr.includes('Any') || arr.includes('None')) return [];
      return [...arr].sort();
    };

    // Compare arrays after normalization
    const cuisineChanged = JSON.stringify(normalizeArray(preferences.cuisinePreferences)) !== 
                          JSON.stringify(normalizeArray(mealPlan.preferences.cuisinePreferences));
    const dietaryChanged = JSON.stringify(normalizeArray(preferences.dietaryRestrictions)) !== 
                          JSON.stringify(normalizeArray(mealPlan.preferences.dietaryRestrictions));
    const allergensChanged = JSON.stringify(normalizeArray(preferences.allergens)) !== 
                            JSON.stringify(normalizeArray(mealPlan.preferences.allergens));
    const mealTypesChanged = JSON.stringify(normalizeArray(preferences.mealTypes)) !== 
                            JSON.stringify(normalizeArray(mealPlan.preferences.mealTypes));
    const prepTimeChanged = preferences.mealPreparationTime !== mealPlan.preferences.mealPreparationTime;

    const hasSignificantChanges = cuisineChanged || dietaryChanged || allergensChanged || mealTypesChanged || prepTimeChanged;

    setHasChanges(hasSignificantChanges);
  }, [preferences, mealPlan?.preferences]);

  const cuisineOptions = [
    {
      name: 'Any',
      examples: 'No cuisine restrictions - include all types!'
    },
    { 
      name: 'Italian', 
      examples: 'Pizza, Pasta, Risotto, and more!'
    },
    { 
      name: 'Asian', 
      examples: 'Stir-fry, Fried Rice, Pad Thai, and more!'
    },
    { 
      name: 'Mexican', 
      examples: 'Tacos, Enchiladas, Fajitas, and more!'
    },
    { 
      name: 'Mediterranean', 
      examples: 'Falafel, Hummus, Kebab, and more!'
    },
    { 
      name: 'American', 
      examples: 'Burgers, BBQ, Mac & Cheese, and more!'
    },
    { 
      name: 'Indian', 
      examples: 'Curry, Biryani, Tikka Masala, and more!'
    }
  ];

  const dietaryAndAllergenOptions = {
    restrictions: ['None', 'Vegetarian', 'Vegan', 'Pescatarian', 'Gluten-free', 'Dairy-free', 'Keto', 'Paleo'],
    allergens: ['None', 'Dairy', 'Eggs', 'Gluten/Wheat', 'Peanuts', 'Tree Nuts', 'Soy', 'Shellfish', 'Fish']
  };

  const timeOptions = ['quick', 'medium', 'any'];

  // Initialize preferences with default values if they don't exist
  React.useEffect(() => {
    setPreferences(prev => ({
      ...prev,
      peopleCount: prev.peopleCount || 1,
      cuisinePreferences: prev.cuisinePreferences?.length ? prev.cuisinePreferences : ['Any'],
      dietaryRestrictions: prev.dietaryRestrictions?.length ? prev.dietaryRestrictions : ['None'],
      allergens: prev.allergens?.length ? prev.allergens : ['None'],
      mealPreparationTime: prev.mealPreparationTime || 'medium'
    }));
  }, []);

  const adjustPeopleCount = (increment) => {
    const newCount = Math.max(1, Math.min(10, (preferences.peopleCount || 1) + increment));
    setPreferences({
      ...preferences,
      peopleCount: newCount
    });
  };

  const handleCuisineSelection = (name) => {
    const current = preferences.cuisinePreferences || [];
    let newPreferences;

    if (name === 'Any') {
      newPreferences = ['Any'];
    } else {
      // If selecting a specific cuisine, remove 'Any'
      newPreferences = current.filter(c => c !== 'Any');
      if (current.includes(name)) {
        newPreferences = newPreferences.filter(c => c !== name);
      } else {
        newPreferences = [...newPreferences, name];
      }
      // If no cuisines selected, default back to 'Any'
      if (newPreferences.length === 0) {
        newPreferences = ['Any'];
      }
    }

    setPreferences({
      ...preferences,
      cuisinePreferences: newPreferences
    });
  };

  const handleRestrictionSelection = (option, type) => {
    const current = preferences[type] || [];
    let newValues;

    if (option === 'None') {
      newValues = ['None'];
    } else {
      // If selecting a specific restriction/allergen, remove 'None'
      newValues = current.filter(c => c !== 'None');
      if (current.includes(option)) {
        newValues = newValues.filter(c => c !== option);
      } else {
        newValues = [...newValues, option];
      }
      // If no options selected, default back to 'None'
      if (newValues.length === 0) {
        newValues = ['None'];
      }
    }

    setPreferences({
      ...preferences,
      [type]: newValues
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      setIsLoading(true);
      // Clean up preferences before saving
      const cleanedPreferences = {
        ...preferences,
        cuisinePreferences: preferences.cuisinePreferences?.includes('Any') ? [] : preferences.cuisinePreferences,
        dietaryRestrictions: preferences.dietaryRestrictions?.includes('None') ? [] : preferences.dietaryRestrictions,
        allergens: preferences.allergens?.includes('None') ? [] : preferences.allergens,
        varietyLevel: 'more' // Always set to 'more' for 7 unique meals
      };

      await savePreferences(cleanedPreferences);
      navigate('/meal-plan');
      generateMealPlan().catch(error => {
        toast.error(MEAL_PLAN_MESSAGES.GENERATE_ERROR(error.message));
      });
    } catch (error) {
      toast.error(PREFERENCES_MESSAGES.SAVE_ERROR(error.message));
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    isReverting.current = true;
    const restoredPreferences = revertPreferences();
    if (restoredPreferences) {
      // Force immediate update of hasChanges
      setHasChanges(false);
      // Force preferences to match meal plan preferences exactly
      setPreferences(mealPlan.preferences);
    }
  };

  return (
    <div className="container max-w-4xl mx-auto pt-0 sm:pt-4 px-4">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="max-w-2xl mx-auto pb-56"
      >
        <div className="flex items-start sm:items-center gap-2.5 sm:gap-3 mb-3 sm:mb-4">
          <RiUserSettingsLine className="hidden sm:block w-8 h-8 sm:w-10 sm:h-10 text-primary mt-0.5 sm:mt-0" />
          <div>
            <h1 className="text-2xl sm:text-2xl font-bold text-dark leading-tight">Your Food Preferences</h1>
            <p className="text-sm sm:text-base text-foggy mt-0.5 sm:mt-1">A personalized meal plan will be created for you based on the answers below!</p>
          </div>
        </div>

        {hasChanges && mealPlan && (
          <div className="mb-6">
            <div className="p-4 bg-orange-400/10 rounded-lg flex items-start gap-2">
              <RiInformationLine className="text-orange-400 shrink-0 mt-0.5 text-lg" />
              <div className="flex-1 min-w-0">
                <p className="text-sm text-orange-600 leading-relaxed">
                  These preference changes will require a new meal plan to be generated.
                </p>
              </div>
            </div>
          </div>
        )}
        
        <form onSubmit={handleSubmit} className="space-y-4 sm:space-y-8">
          <div className="card">
            <label className="block text-dark text-sm sm:text-base font-medium mb-3 sm:mb-4">
              How many people are you cooking for?
            </label>
            <div className="flex items-center justify-center gap-3 sm:gap-4 p-3 sm:p-4 bg-light/50 rounded-lg">
              <button
                type="button"
                onClick={() => adjustPeopleCount(-1)}
                className="p-2 sm:p-2 rounded-full hover:bg-light transition-colors"
              >
                <RiSubtractLine className="text-xl sm:text-xl text-foggy" />
              </button>
              <AnimatedNumber number={preferences.peopleCount || 1} />
              <button
                type="button"
                onClick={() => adjustPeopleCount(1)}
                className="p-2 sm:p-2 rounded-full hover:bg-light transition-colors"
              >
                <RiAddLine className="text-xl sm:text-xl text-foggy" />
              </button>
            </div>
          </div>

          <div className="card">
            <label className="block text-dark text-sm sm:text-base font-medium mb-3 sm:mb-4">
              Which meals would you like to include in your meal plan?
            </label>
            <div className="flex flex-wrap gap-1.5 sm:gap-2">
              <button
                type="button"
                onClick={() => setPreferences({
                  ...preferences,
                  mealTypes: ['all']
                })}
                className={`px-3 sm:px-4 py-1.5 sm:py-2 rounded-lg border text-sm sm:text-base transition-all ${
                  preferences.mealTypes?.includes('all')
                    ? 'bg-primary text-white border-primary'
                    : 'border-light text-foggy hover:border-primary'
                }`}
              >
                All Meals
              </button>
              {['breakfast', 'lunch', 'dinner', 'snacks'].map((type) => (
                <button
                  key={type}
                  type="button"
                  onClick={() => {
                    const currentTypes = preferences.mealTypes || [];
                    let newTypes;
                    
                    if (currentTypes.includes('all')) {
                      // If 'all' is selected, switch to just this type
                      newTypes = [type];
                    } else if (currentTypes.includes(type)) {
                      // Remove this type if it's already selected
                      newTypes = currentTypes.filter(t => t !== type);
                      // If no types left, default back to 'all'
                      if (newTypes.length === 0) {
                        newTypes = ['all'];
                      }
                    } else {
                      // Add this type
                      newTypes = [...currentTypes, type];
                    }
                    
                    setPreferences({
                      ...preferences,
                      mealTypes: newTypes
                    });
                  }}
                  className={`px-3 sm:px-4 py-1.5 sm:py-2 rounded-lg border text-sm sm:text-base transition-all ${
                    preferences.mealTypes?.includes(type) && !preferences.mealTypes?.includes('all')
                      ? 'bg-primary text-white border-primary'
                      : 'border-light text-foggy hover:border-primary'
                  }`}
                >
                  {type.charAt(0).toUpperCase() + type.slice(1)}
                </button>
              ))}
            </div>
          </div>

          <div className="card">
            <label className="block text-dark text-sm sm:text-base font-medium mb-3 sm:mb-4">
              What type of food would you like?
            </label>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2.5 sm:gap-3">
              {cuisineOptions.map(({ name, examples }) => (
                <button
                  key={name}
                  type="button"
                  onClick={() => handleCuisineSelection(name)}
                  className={`p-3 sm:p-4 rounded-lg border transition-all text-left ${
                    preferences.cuisinePreferences?.includes(name) || 
                    (name === 'Any' && (!preferences.cuisinePreferences || preferences.cuisinePreferences.length === 0))
                      ? 'bg-primary text-white border-primary'
                      : 'border-light text-dark hover:border-primary'
                  }`}
                >
                  <div className="text-sm sm:text-base font-medium">{name}</div>
                  <div className={`text-xs sm:text-sm mt-0.5 sm:mt-1 ${
                    preferences.cuisinePreferences?.includes(name) || 
                    (name === 'Any' && (!preferences.cuisinePreferences || preferences.cuisinePreferences.length === 0))
                      ? 'text-white/80'
                      : 'text-foggy'
                  }`}>
                    {examples}
                  </div>
                </button>
              ))}
            </div>
          </div>

          <div className="card">
            <label className="block text-dark text-sm sm:text-base font-medium mb-3 sm:mb-4">
              Any dietary restrictions or allergies?
            </label>
            <div className="space-y-3 sm:space-y-4">
              <div>
                <div className="text-xs sm:text-sm text-foggy mb-2">Dietary Restrictions</div>
                <div className="flex flex-wrap gap-1.5 sm:gap-2">
                  {dietaryAndAllergenOptions.restrictions.map((option) => (
                    <button
                      key={option}
                      type="button"
                      onClick={() => handleRestrictionSelection(option, 'dietaryRestrictions')}
                      className={`px-3 sm:px-4 py-1.5 sm:py-2 rounded-lg border text-sm sm:text-base transition-all ${
                        preferences.dietaryRestrictions?.includes(option)
                          ? 'bg-primary text-white border-primary'
                          : 'border-light text-foggy hover:border-primary'
                      }`}
                    >
                      {option}
                    </button>
                  ))}
                </div>
              </div>
              
              <div>
                <div className="text-xs sm:text-sm text-foggy mb-2">Allergens</div>
                <div className="flex flex-wrap gap-1.5 sm:gap-2">
                  {dietaryAndAllergenOptions.allergens.map((option) => (
                    <button
                      key={option}
                      type="button"
                      onClick={() => handleRestrictionSelection(option, 'allergens')}
                      className={`px-3 sm:px-4 py-1.5 sm:py-2 rounded-lg border text-sm sm:text-base transition-all ${
                        preferences.allergens?.includes(option)
                          ? 'bg-primary text-white border-primary'
                          : 'border-light text-foggy hover:border-primary'
                      }`}
                    >
                      {option}
                    </button>
                  ))}
                </div>
              </div>
              
              <div className="p-3 bg-[#E3F2FD] rounded-md flex items-start gap-2">
                <RiInformationLine className="text-[#1976D2] shrink-0 mt-0.5 text-lg" />
                <p className="text-sm text-[#0D47A1] break-words leading-relaxed flex-1">
                  Please review all meals and ingredients carefully to ensure they meet your dietary and allergy preferences.
                </p>
              </div>
            </div>
          </div>

          <div className="card">
            <label className="block text-dark text-sm sm:text-base font-medium mb-3">
              How long should meals take to prepare?
            </label>
            <div className="flex flex-wrap gap-2 sm:gap-2 mb-3">
              {timeOptions.map((time) => (
                <button
                  key={time}
                  type="button"
                  onClick={() => setPreferences({
                    ...preferences,
                    mealPreparationTime: time
                  })}
                  className={`px-4 py-2 rounded-lg border text-sm sm:text-base transition-all ${
                    preferences.mealPreparationTime === time
                      ? 'bg-primary text-white border-primary'
                      : 'border-light text-foggy hover:border-primary'
                  }`}
                >
                  {time.charAt(0).toUpperCase() + time.slice(1)}
                </button>
              ))}
            </div>
            {preferences.mealPreparationTime && (
              <p className="text-sm sm:text-sm text-foggy">
                {timeDescriptions[preferences.mealPreparationTime]}
              </p>
            )}
          </div>
        </form>
      </motion.div>

      <StickyActions>
        <div className="flex flex-col sm:flex-row gap-2 sm:gap-3 w-full sm:justify-center">
          {hasChanges && mealPlan && (
            <button
              onClick={handleCancel}
              className="w-full sm:w-auto btn-secondary flex items-center justify-center gap-2 py-3 px-6 text-base sm:text-base whitespace-nowrap"
            >
              Cancel Changes
            </button>
          )}
          <button
            onClick={mealPlan && !hasChanges ? () => navigate('/meal-plan') : handleSubmit}
            disabled={isLoading}
            className="w-full sm:w-auto btn-primary flex items-center justify-center gap-2 py-3 px-6 text-base sm:text-base whitespace-nowrap"
          >
            {isLoading ? (
              <span className="flex items-center justify-center gap-2">
                <RiLoader4Line className="animate-spin text-xl" />
                <span>Generating...</span>
              </span>
            ) : (
              <>
                <RiCalendarLine className="text-xl" />
                <span>
                  {mealPlan 
                    ? (hasChanges ? 'Create New Meal Plan' : 'View Meal Plan')
                    : 'Create Meal Plan'}
                </span>
              </>
            )}
          </button>
        </div>
      </StickyActions>
    </div>
  );
};

export default Preferences;