import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

// Loading quotes about food and cooking
const LOADING_QUOTES = [
  {
    text: "One cannot think well, love well, sleep well, if one has not dined well.",
    author: "Virginia Woolf"
  },
  {
    text: "People who love to eat are always the best people.",
    author: "Julia Child"
  },
  {
    text: "Cooking is like love. It should be entered into with abandon or not at all.",
    author: "Harriet Van Horne"
  },
  {
    text: "The only time to eat diet food is while you're waiting for the steak to cook.",
    author: "Julia Child"
  },
  {
    text: "Life is uncertain. Eat dessert first.",
    author: "Ernestine Ulmer"
  },
  {
    text: "Let food be thy medicine, and medicine be thy food.",
    author: "Hippocrates"
  },
  {
    text: "A recipe has no soul. You, as the cook, must bring soul to the recipe.",
    author: "Thomas Keller"
  },
  {
    text: "Cooking is the ultimate giving.",
    author: "Jamie Oliver"
  }
];

export function LoadingQuote() {
  const [quote, setQuote] = useState(LOADING_QUOTES[0]);

  useEffect(() => {
    // Change quote every 5 seconds
    const interval = setInterval(() => {
      setQuote(LOADING_QUOTES[Math.floor(Math.random() * LOADING_QUOTES.length)]);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <motion.div
      key={quote.text}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className="max-w-md mx-auto"
    >
      <p className="text-lg text-dark italic mb-2">"{quote.text}"</p>
      <p className="text-sm text-foggy">— {quote.author}</p>
    </motion.div>
  );
}

export default LoadingQuote; 