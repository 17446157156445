import { supabase } from '../config/supabase';

/**
 * Formats the shopping list for Instacart API
 * @param {Object} shoppingList - The shopping list object from EasyMeals
 * @returns {Array} - Array of line items formatted for Instacart
 */
export const formatShoppingListForInstacart = (shoppingList) => {
  if (!shoppingList) return [];
  
  const lineItems = [];
  
  // Iterate through each category in the shopping list
  Object.entries(shoppingList).forEach(([category, items]) => {
    if (!Array.isArray(items)) return;
    
    items.forEach(item => {
      if (!item || !item.item) return;
      
      // Extract quantity and unit if available
      let quantity = 1;
      let unit = "each";
      
      if (item.quantity) {
        // Try to parse the quantity as a number
        const parsedQuantity = parseFloat(item.quantity);
        if (!isNaN(parsedQuantity)) {
          quantity = parsedQuantity;
        }
      }
      
      // Add the item to the line items array
      lineItems.push({
        name: item.item,
        quantity,
        unit
      });
    });
  });
  
  return lineItems;
};

// Determine if we're in development or production
const isDevelopment = import.meta.env.DEV;
// Use mock in development by default, but allow override via env variable
const USE_MOCK = isDevelopment && import.meta.env.VITE_USE_REAL_INSTACART !== 'true';
// Get the API URL - use a proxy in production, direct in development with real API
const INSTACART_API_URL = isDevelopment && !USE_MOCK
  ? 'https://api.instacart.com/idp/v1/products/products_link'
  : '/api/instacart/products-link';

/**
 * Creates an Instacart products link
 * @param {Object} shoppingList - The shopping list object from EasyMeals
 * @param {string} title - The title for the shopping list
 * @returns {Promise<string>} - The Instacart products link URL
 */
export const createInstacartProductsLink = async (shoppingList, title = "EasyMeals Shopping List") => {
  // For local development, use a mock implementation to bypass CORS issues
  if (USE_MOCK) {
    console.log('MOCK MODE: Would have sent to Instacart:', {
      title,
      line_items: formatShoppingListForInstacart(shoppingList),
      landing_page_configuration: {
        partner_linkback_url: window.location.origin + "/shopping-list"
      }
    });
    
    // Simulate a delay to mimic an API call
    await new Promise(resolve => setTimeout(resolve, 1000));
    
    // Return a mock Instacart URL with affiliate parameters
    return "https://www.instacart.com/store/partner/list/mock-list-id?irad=1744632&irmp=6071614";
  }
  
  try {
    // In development with real API, we need to format the shopping list
    // In production, the serverless function will handle the formatting
    const requestBody = isDevelopment && !USE_MOCK
      ? {
          title,
          line_items: formatShoppingListForInstacart(shoppingList),
          landing_page_configuration: {
            partner_linkback_url: window.location.origin + "/shopping-list"
          }
        }
      : {
          shoppingList,
          title
        };
    
    // Make the API call
    const response = await fetch(INSTACART_API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...(isDevelopment && !USE_MOCK ? {
          'Authorization': `Bearer ${import.meta.env.VITE_INSTACART_API_KEY}`
        } : {})
      },
      body: JSON.stringify(requestBody)
    });
    
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || `Instacart API error: ${response.statusText}`);
    }
    
    const data = await response.json();
    const baseUrl = data.products_link_url;
    
    // Append affiliate tracking parameters
    const url = new URL(baseUrl);
    url.searchParams.append('irad', '1744632');
    url.searchParams.append('irmp', '6071614');
    
    console.log('DEBUG: Generated Instacart URL with affiliate tracking:', url.toString());
    
    return url.toString();
  } catch (error) {
    console.error('Error creating Instacart products link:', error);
    throw error;
  }
};

/**
 * Saves an Instacart link to the database
 * @param {string} userId - The user ID (optional for anonymous users)
 * @param {string} mealPlanId - The meal plan ID (optional)
 * @param {string} productsLinkUrl - The Instacart products link URL
 * @param {string} sessionId - Session ID for anonymous users (optional)
 * @returns {Promise<Object>} - The saved Instacart link
 */
export const saveInstacartLink = async (userId = null, mealPlanId = null, productsLinkUrl, sessionId = null) => {
  try {
    console.log('DEBUG: saveInstacartLink called with:', { 
      userId, mealPlanId, productsLinkUrl, sessionId 
    });
    
    const isAnonymous = !userId;
    const sessionIdToUse = isAnonymous ? (sessionId || generateSessionId()) : null;
    
    console.log('DEBUG: Prepared data:', { 
      isAnonymous, sessionIdToUse 
    });
    
    // Log the Supabase URL and key (just existence, not the actual key)
    console.log('DEBUG: Supabase URL:', supabase.supabaseUrl);
    console.log('DEBUG: Supabase key exists:', !!supabase.supabaseKey);
    
    const insertData = {
      user_id: userId,
      meal_plan_id: mealPlanId,
      products_link_url: productsLinkUrl,
      session_id: sessionIdToUse,
      is_anonymous: isAnonymous,
      status: 'active',
      expires_in: 7,
      created_at: new Date().toISOString()
    };
    
    console.log('DEBUG: Insert data:', insertData);
    
    const { data, error } = await supabase
      .from('instacart_links')
      .insert([insertData])
      .select();
      
    console.log('DEBUG: Insert result:', { data, error });
    
    if (error) {
      console.error('DEBUG: Error details:', error);
      throw error;
    }
    
    return data[0];
  } catch (error) {
    console.error('DEBUG: Exception:', error);
    throw error;
  }
};

/**
 * Gets the most recent Instacart link for a meal plan
 * @param {string} userId - The user ID
 * @param {string} mealPlanId - The meal plan ID
 * @returns {Promise<Object|null>} - The Instacart link or null if not found
 */
export const getInstacartLink = async (userId, mealPlanId) => {
  try {
    const { data, error } = await supabase
      .from('instacart_links')
      .select('*')
      .eq('user_id', userId)
      .eq('meal_plan_id', mealPlanId)
      .eq('status', 'active')
      .order('created_at', { ascending: false })
      .limit(1);
      
    if (error) throw error;
    return data.length > 0 ? data[0] : null;
  } catch (error) {
    console.error('Error getting Instacart link:', error);
    return null;
  }
};

/**
 * Tracks a click on an Instacart link
 * @param {string} linkId - The Instacart link ID
 * @param {string} userId - The user ID (optional)
 * @param {string} mealPlanId - The meal plan ID (optional)
 * @param {string} sessionId - The session ID for anonymous users (optional)
 * @returns {Promise<Object>} - The saved click data
 */
export const trackInstacartLinkClick = async (linkId, userId = null, mealPlanId = null, sessionId = null) => {
  try {
    console.log('DEBUG: trackInstacartLinkClick called with:', { 
      linkId, userId, mealPlanId, sessionId 
    });
    
    const isAnonymous = !userId;
    const sessionIdToUse = isAnonymous ? (sessionId || generateSessionId()) : null;
    
    console.log('DEBUG: Prepared click data:', { 
      isAnonymous, sessionIdToUse 
    });
    
    // First, get the link data to retrieve the products_link_url
    const { data: linkData, error: linkError } = await supabase
      .from('instacart_links')
      .select('products_link_url')
      .eq('id', linkId)
      .single();
      
    if (linkError) {
      console.error('DEBUG: Error fetching link data:', linkError);
      throw linkError;
    }
    
    let productsLinkUrl = linkData?.products_link_url;
    
    // Ensure the URL has affiliate parameters
    if (productsLinkUrl) {
      const url = new URL(productsLinkUrl);
      if (!url.searchParams.has('irad')) {
        url.searchParams.append('irad', '1744632');
        url.searchParams.append('irmp', '6071614');
        productsLinkUrl = url.toString();
      }
    }
    
    console.log('DEBUG: Retrieved products_link_url with affiliate tracking:', productsLinkUrl);
    
    const insertData = {
      link_id: linkId,
      user_id: userId,
      meal_plan_id: mealPlanId,
      session_id: sessionIdToUse,
      is_anonymous: isAnonymous,
      products_link_url: productsLinkUrl,
      clicked_at: new Date().toISOString()
    };
    
    console.log('DEBUG: Click insert data:', insertData);
    
    const { data, error } = await supabase
      .from('instacart_link_clicks')
      .insert([insertData])
      .select();
      
    console.log('DEBUG: Click insert result:', { data, error });
    
    if (error) {
      console.error('DEBUG: Click error details:', error);
      throw error;
    }
    
    return data[0];
  } catch (error) {
    console.error('DEBUG: Error tracking Instacart link click:', error);
    // Don't throw the error to prevent disrupting the user experience
    return null;
  }
};

/**
 * Generates a random session ID for anonymous users
 * @returns {string} - A random session ID
 */
const generateSessionId = () => {
  return Math.random().toString(36).substring(2, 15) + 
         Math.random().toString(36).substring(2, 15);
}; 