import React from 'react';

/**
 * InstacartButton component that follows Instacart's design guidelines
 * @see https://docs.instacart.com/developer_platform_api/guide/design/cta_design
 */
const InstacartButton = ({ 
  onClick, 
  isLoading = false, 
  disabled = false, 
  type = 'button',
  href,
  className = '',
  children
}) => {
  // Instacart brand colors
  const INSTACART_DARK = '#003D29';
  const INSTACART_LIGHT = '#FAF1E5';
  const INSTACART_ORANGE = '#FF7009';
  const INSTACART_GREEN = '#0AAD0A';
  
  // Base styles following Instacart guidelines
  const baseStyles = `
    flex items-center justify-center gap-1.5
    h-[46px] px-[16px] py-[14px]
    rounded-full
    font-medium text-base
    transition-colors
    ${disabled ? 'opacity-60 cursor-not-allowed' : 'cursor-pointer'}
    bg-[#003D29] 
    text-[#FAF1E5]
    hover:bg-[#004d33]
    active:bg-[#002d1f]
    ${className}
  `;
  
  // Instacart logo - exact SVG from official Instacart_Carrot.svg
  const InstacartLogo = () => (
    <svg width="20" height="20" viewBox="0 0 42.3 52.9" xmlns="http://www.w3.org/2000/svg">
      <style type="text/css">
        {`.st0{fill:#0AAD0A;}
        .st1{fill:#FF7009;}`}
      </style>
      <g>
        <path className="st0" d="M36.4,8.6c-2.3,0-4,1-5.5,3.2l-4.4,6.4V0H15.9v18.2l-4.4-6.4C9.9,9.6,8.2,8.6,5.9,8.6C2.4,8.6,0,11.2,0,14.4
          c0,2.7,1.3,4.5,4,6.3l17.1,11l17.1-11c2.7-1.8,4-3.5,4-6.3C42.3,11.2,39.9,8.6,36.4,8.6z" />
        <path className="st1" d="M21.1,34.4c10.2,0,18.5,7.6,18.5,18.5h-37C2.6,42,11,34.4,21.1,34.4z" />
      </g>
    </svg>
  );
  
  // If href is provided, render as an anchor tag
  if (href) {
    return (
      <a 
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className={baseStyles}
        disabled={disabled}
      >
        <InstacartLogo />
        <span className="whitespace-nowrap">Shop with Instacart</span>
      </a>
    );
  }
  
  // Otherwise render as a button
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled || isLoading}
      className={baseStyles}
    >
      {isLoading ? (
        <>
          <svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
          <span className="whitespace-nowrap">Preparing Your Cart...</span>
        </>
      ) : (
        <>
          <InstacartLogo />
          <span className="whitespace-nowrap">Shop with Instacart</span>
        </>
      )}
    </button>
  );
};

export default InstacartButton; 