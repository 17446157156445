import React from 'react';
import { useViewMode, VIEW_MODES } from '../contexts/ViewModeContext';
import { RiEyeLine, RiFileListLine } from 'react-icons/ri';

const ViewModeToggle = () => {
  const { viewMode, setViewMode } = useViewMode();

  return (
    <div className="inline-flex rounded-lg border border-light p-1 bg-white w-full">
      <button
        onClick={() => setViewMode(VIEW_MODES.AT_A_GLANCE)}
        className={`flex-1 px-2 py-1.5 rounded-md text-xs font-medium transition-colors ${
          viewMode === VIEW_MODES.AT_A_GLANCE
            ? 'border border-primary text-primary bg-primary/5'
            : 'text-foggy hover:text-primary'
        }`}
      >
        <span className="flex items-center justify-center gap-1">
          <RiEyeLine className="text-[14px]" />
          <span>At a Glance</span>
        </span>
      </button>
      <button
        onClick={() => setViewMode(VIEW_MODES.DETAILED)}
        className={`flex-1 px-2 py-1.5 rounded-md text-xs font-medium transition-colors ${
          viewMode === VIEW_MODES.DETAILED
            ? 'border border-primary text-primary bg-primary/5'
            : 'text-foggy hover:text-primary'
        }`}
      >
        <span className="flex items-center justify-center gap-1">
          <RiFileListLine className="text-[14px]" />
          <span>Detailed</span>
        </span>
      </button>
    </div>
  );
};

export default ViewModeToggle; 