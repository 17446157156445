import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useAuth } from '../contexts/AuthContext';
import { RiCloseLine, RiAlertLine } from 'react-icons/ri';
import toast from 'react-hot-toast';
import { AUTH_MESSAGES } from '../utils/toastMessages';
import { useNavigate } from 'react-router-dom';
import BenefitsDisplay from './BenefitsDisplay';
import { useAnalytics } from '../hooks/useAnalytics';

const SaveShoppingListModal = ({ isOpen, onClose, onExport }) => {
  const navigate = useNavigate();
  const { signUp, signIn } = useAuth();
  const { trackEvent } = useAnalytics();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isAuthMode, setIsAuthMode] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  // Track modal open
  useEffect(() => {
    if (isOpen) {
      trackEvent('shopping_list_save_modal_open');
    }
  }, [isOpen, trackEvent]);

  // Handle tooltip cleanup
  useEffect(() => {
    let timer;
    if (showTooltip) {
      timer = setTimeout(() => {
        setShowTooltip(false);
      }, 3000); // Show tooltip for 3 seconds
    }
    return () => clearTimeout(timer);
  }, [showTooltip]);

  const handleModalClose = () => {
    trackEvent('shopping_list_save_modal_close', {
      method: 'button'
    });
    onClose();
  };

  const handleBackdropClick = () => {
    trackEvent('shopping_list_save_modal_close', {
      method: 'backdrop'
    });
    onClose();
  };

  const handleAuth = async (e) => {
    e.preventDefault();
    try {
      if (isAuthMode) {
        if (isLogin) {
          trackEvent('shopping_list_save_signin_attempt');
          await signIn(email, password);
          toast.success(AUTH_MESSAGES.SIGNED_IN);
          trackEvent('shopping_list_save_signin_success');
        } else {
          trackEvent('shopping_list_save_signup_attempt');
          const { data } = await signUp(email, password);
          if (data) {
            // Wait a moment for auth state to propagate
            await new Promise(resolve => setTimeout(resolve, 500));
            toast.success(AUTH_MESSAGES.ACCOUNT_CREATED);
            setShowTooltip(true);
            trackEvent('shopping_list_save_signup_success', {
              user_id: data.user.id
            });
          }
        }
        setEmail('');
        setPassword('');
        onClose();
      } else {
        setIsAuthMode(true);
        setIsLogin(false);
        trackEvent('shopping_list_save_create_account_click');
      }
    } catch (error) {
      toast.error(AUTH_MESSAGES.AUTH_ERROR(error.message));
      trackEvent(isLogin ? 'shopping_list_save_signin_error' : 'shopping_list_save_signup_error', {
        error: error.message
      });
    }
  };

  const renderAuthForm = () => (
    <div className="space-y-4 mb-8">
      <div>
        <label className="block text-sm font-medium text-dark mb-1">
          Email
        </label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-full px-4 py-3 border border-light rounded-xl focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent transition-all"
          required
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-dark mb-1">
          Password
        </label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="w-full px-4 py-3 border border-light rounded-xl focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent transition-all"
          required
        />
      </div>
      <div className="flex justify-end">
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            setIsLogin(!isLogin);
          }}
          className="text-sm text-green-500 hover:text-green-400 transition-colors font-medium"
        >
          {isLogin ? "Need an account? Sign up" : "Already have an account? Sign in"}
        </button>
      </div>
      {!isLogin && (
        <p className="text-sm text-foggy mt-2 bg-light/50 p-4 rounded-lg leading-relaxed">
          By creating an account, your meal plans and shopping lists will be securely stored for continued access and used to provide personalized recommendations.
        </p>
      )}
    </div>
  );

  const renderBenefits = () => (
    <div className="mb-6">
      <BenefitsDisplay />
    </div>
  );

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50"
          onClick={handleBackdropClick}
        >
          {/* Tooltip */}
          <AnimatePresence>
            {showTooltip && (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ 
                  type: "spring",
                  stiffness: 300,
                  damping: 25
                }}
                className="fixed top-[72px] left-1/2 transform -translate-x-1/2 z-[200] pointer-events-none"
              >
                <div className="bg-orange-400 text-white px-4 py-2 rounded-lg text-sm shadow-lg whitespace-nowrap">
                  <div className="flex items-center gap-2">
                    <RiAlertLine className="text-lg" />
                    <span>Your saved meal plans are available in the navigation bar</span>
                  </div>
                  <div className="absolute -bottom-1 left-1/2 transform -translate-x-1/2 w-2 h-2 rotate-45 bg-orange-400" />
                </div>
              </motion.div>
            )}
          </AnimatePresence>

          {/* Mobile View */}
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 20, opacity: 0 }}
            transition={{ type: 'spring', damping: 25, stiffness: 300 }}
            className="fixed inset-x-0 bottom-0 bg-white rounded-t-[2rem] p-6 md:hidden"
            onClick={e => e.stopPropagation()}
          >
            <div className="flex justify-between items-start gap-4 mb-6">
              <h2 className="text-xl font-bold text-dark leading-tight">
                {isAuthMode ? (isLogin ? 'Welcome Back!' : 'Create your account') : 'Join Easy Meals Today!'}
              </h2>
              <button
                onClick={handleModalClose}
                className="p-2 hover:bg-light/80 rounded-full transition-colors group flex-shrink-0"
                aria-label="Close modal"
              >
                <RiCloseLine className="text-2xl text-foggy group-hover:text-dark transition-colors" />
              </button>
            </div>
            
            {isAuthMode ? renderAuthForm() : renderBenefits()}
            
            <div className="space-y-3">
              <button 
                onClick={handleAuth}
                className="w-full bg-primary text-white py-4 rounded-xl font-medium hover:bg-primary/90 transition-colors"
              >
                {isAuthMode ? (isLogin ? 'Log In' : 'Sign Up') : 'Create Account'}
              </button>
              {!isAuthMode && (
                <button 
                  onClick={() => {
                    trackEvent('shopping_list_export_without_account');
                    onExport();
                    onClose();
                  }}
                  className="w-full bg-light text-dark py-4 rounded-xl font-medium hover:bg-light/80 transition-colors"
                >
                  Export Shopping List
                </button>
              )}
            </div>
          </motion.div>

          {/* Desktop View */}
          <motion.div
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
            transition={{ type: 'spring', damping: 25, stiffness: 300 }}
            className="hidden md:block fixed inset-0 overflow-auto"
            onClick={e => e.stopPropagation()}
          >
            <div className="min-h-screen px-4 flex items-center justify-center">
              <div className="bg-white rounded-[2rem] p-6 max-w-xl w-full relative shadow-xl">
                <div className="flex justify-between items-start gap-4 mb-6">
                  <h2 className="text-xl font-bold text-dark leading-tight">
                    {isAuthMode ? (isLogin ? 'Welcome Back!' : 'Create your account') : 'Join Easy Meals Today!'}
                  </h2>
                  <button
                    onClick={handleModalClose}
                    className="p-2 hover:bg-light/80 rounded-full transition-colors group flex-shrink-0"
                    aria-label="Close modal"
                  >
                    <RiCloseLine className="text-2xl text-foggy group-hover:text-dark transition-colors" />
                  </button>
                </div>
                
                {isAuthMode ? renderAuthForm() : renderBenefits()}
                
                <div className="flex gap-3">
                  <button 
                    onClick={handleAuth}
                    className="flex-1 bg-primary text-white py-4 rounded-xl font-medium hover:bg-primary/90 transition-colors"
                  >
                    {isAuthMode ? (isLogin ? 'Log In' : 'Sign Up') : 'Create Account'}
                  </button>
                  {!isAuthMode && (
                    <button 
                      onClick={() => {
                        trackEvent('shopping_list_export_without_account');
                        onExport();
                        onClose();
                      }}
                      className="flex-1 bg-light text-dark py-4 rounded-xl font-medium hover:bg-light/80 transition-colors"
                    >
                      Export Shopping List
                    </button>
                  )}
                </div>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default SaveShoppingListModal; 