import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { RiCloseLine } from 'react-icons/ri';
import { useAnalytics } from '../hooks/useAnalytics';

const AuthModal = ({ isOpen, onClose, isSignUp, setIsSignUp, handleAuth, email, setEmail, password, setPassword }) => {
  const { trackEvent } = useAnalytics();

  // Track modal open
  React.useEffect(() => {
    if (isOpen) {
      trackEvent('auth_modal_open', {
        type: isSignUp ? 'signup' : 'signin'
      });
    }
  }, [isOpen, isSignUp, trackEvent]);

  const handleModalClose = () => {
    trackEvent('auth_modal_close', {
      method: 'button',
      type: isSignUp ? 'signup' : 'signin'
    });
    onClose();
  };

  const handleBackdropClick = () => {
    trackEvent('auth_modal_close', {
      method: 'backdrop',
      type: isSignUp ? 'signup' : 'signin'
    });
    onClose();
  };

  const handleToggleMode = (e) => {
    e.preventDefault();
    trackEvent('auth_mode_toggle', {
      from: isSignUp ? 'signup' : 'signin',
      to: isSignUp ? 'signin' : 'signup'
    });
    setIsSignUp(!isSignUp);
  };

  if (!isOpen) return null;

  return (
    <AnimatePresence>
      <div className="fixed inset-0 bg-black/50 backdrop-blur-sm z-[100]" onClick={handleBackdropClick}>
        {/* Mobile View */}
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 20, opacity: 0 }}
          transition={{ type: 'spring', damping: 25, stiffness: 300 }}
          className="fixed inset-x-0 bottom-0 bg-white rounded-t-[2rem] p-8 md:hidden"
          onClick={e => e.stopPropagation()}
        >
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-bold text-dark">
              {isSignUp ? 'Create your account' : 'Welcome Back!'}
            </h2>
            <button
              onClick={handleModalClose}
              className="p-3 hover:bg-light/80 rounded-full transition-colors group"
              aria-label="Close modal"
            >
              <RiCloseLine className="text-2xl text-foggy group-hover:text-dark transition-colors" />
            </button>
          </div>

          <form onSubmit={handleAuth} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-dark mb-1">
                Email
              </label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-4 py-3 border border-light rounded-xl focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent transition-all"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-dark mb-1">
                Password
              </label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-4 py-3 border border-light rounded-xl focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent transition-all"
                required
              />
            </div>

            <div className="flex justify-end">
              <button
                type="button"
                onClick={handleToggleMode}
                className="text-sm text-green-500 hover:text-green-400 transition-colors font-medium"
              >
                {isSignUp ? "Already have an account? Sign in" : "Need an account? Sign up"}
              </button>
            </div>

            {isSignUp && (
              <p className="text-sm text-foggy mt-2 bg-light/50 p-4 rounded-lg leading-relaxed">
                By creating an account, your meal plans and shopping lists will be securely stored for continued access and used to provide personalized recommendations.
              </p>
            )}

            <button
              type="submit"
              className="w-full bg-primary text-white py-4 rounded-xl font-medium hover:bg-primary/90 transition-colors mt-4"
            >
              {isSignUp ? 'Sign Up' : 'Sign In'}
            </button>
          </form>
        </motion.div>

        {/* Desktop View */}
        <motion.div
          initial={{ scale: 0.95, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.95, opacity: 0 }}
          transition={{ type: 'spring', damping: 25, stiffness: 300 }}
          className="hidden md:block fixed inset-0 overflow-auto"
          onClick={e => e.stopPropagation()}
        >
          <div className="min-h-screen px-4 flex items-center justify-center">
            <div className="bg-white rounded-[2rem] p-8 max-w-md w-full relative shadow-xl">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-2xl font-bold text-dark">
                  {isSignUp ? 'Create your account' : 'Welcome Back!'}
                </h2>
                <button
                  onClick={handleModalClose}
                  className="p-3 hover:bg-light/80 rounded-full transition-colors group"
                  aria-label="Close modal"
                >
                  <RiCloseLine className="text-2xl text-foggy group-hover:text-dark transition-colors" />
                </button>
              </div>

              <form onSubmit={handleAuth} className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-dark mb-1">
                    Email
                  </label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full px-4 py-3 border border-light rounded-xl focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent transition-all"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-dark mb-1">
                    Password
                  </label>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full px-4 py-3 border border-light rounded-xl focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent transition-all"
                    required
                  />
                </div>

                <div className="flex justify-end">
                  <button
                    type="button"
                    onClick={handleToggleMode}
                    className="text-sm text-green-500 hover:text-green-400 transition-colors font-medium"
                  >
                    {isSignUp ? "Already have an account? Sign in" : "Need an account? Sign up"}
                  </button>
                </div>

                {isSignUp && (
                  <p className="text-sm text-foggy mt-2 bg-light/50 p-4 rounded-lg leading-relaxed">
                    By creating an account, your meal plans and shopping lists will be securely stored for continued access and used to provide personalized recommendations.
                  </p>
                )}

                <button
                  type="submit"
                  className="w-full bg-primary text-white py-4 rounded-xl font-medium hover:bg-primary/90 transition-colors mt-4"
                >
                  {isSignUp ? 'Sign Up' : 'Sign In'}
                </button>
              </form>
            </div>
          </div>
        </motion.div>
      </div>
    </AnimatePresence>
  );
};

export default AuthModal; 