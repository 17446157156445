import React from 'react';
import { motion } from 'framer-motion';

const StickyActions = ({ children, className = '' }) => {
  return (
    <motion.div
      initial={{ y: 100 }}
      animate={{ y: 0 }}
      className={`fixed bottom-16 left-0 right-0 z-20 px-4 py-3 bg-white border-t border-light shadow-level2-reverse md:bg-transparent md:border-0 md:shadow-none md:fixed md:bottom-0 ${className}`}
    >
      <div className="container max-w-xs mx-auto flex flex-col gap-3">
        {children}
      </div>
    </motion.div>
  );
};

export default StickyActions;