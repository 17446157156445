import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  RiCalendarLine, 
  RiDeleteBin6Line, 
  RiLoader4Line,
  RiArrowRightLine,
  RiUserLine,
  RiTimeLine,
  RiEditLine,
  RiCheckLine,
  RiCloseLine,
  RiShoppingCart2Line,
  RiSaveLine,
  RiRestaurantLine
} from 'react-icons/ri';
import { useMealPlan } from '../contexts/MealPlanContext';
import { useAuth } from '../contexts/AuthContext';
import { format } from 'date-fns';
import toast from 'react-hot-toast';
import { supabase } from '../config/supabase';
import { MEAL_PLAN_MESSAGES } from '../utils/toastMessages';

const SavedMealPlans = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { 
    savedMealPlans, 
    getSavedMealPlans, 
    loadSavedMealPlan,
    saveMealPlan,
    isLoading,
    activeMealPlanId
  } = useMealPlan();

  // State for editing plan names
  const [editingId, setEditingId] = useState(null);
  const [editingName, setEditingName] = useState('');
  const [showShoppingList, setShowShoppingList] = useState(null);
  // Add new state for showing meal plan
  const [showMealPlan, setShowMealPlan] = useState(null);
  // Add state for delete confirmation
  const [deleteConfirmId, setDeleteConfirmId] = useState(null);
  const [showLoadingState, setShowLoadingState] = useState(false);

  useEffect(() => {
    let loadingTimeout;
    if (isLoading) {
      // Only show loading state if the load takes more than 500ms
      loadingTimeout = setTimeout(() => {
        setShowLoadingState(true);
      }, 500);
    } else {
      setShowLoadingState(false);
    }
    return () => clearTimeout(loadingTimeout);
  }, [isLoading]);

  useEffect(() => {
    console.log('SavedMealPlans mounted, user:', user?.id);
    if (user) {
      console.log('Fetching saved meal plans...');
      getSavedMealPlans().then(plans => {
        console.log('Fetched plans:', plans);
      });
    }
  }, [getSavedMealPlans, user]);

  const handleLoadMealPlan = async (id) => {
    try {
      await loadSavedMealPlan(id);
      navigate('/meal-plan');
      toast.success(MEAL_PLAN_MESSAGES.LOADED);
    } catch (error) {
      console.error('Error loading meal plan:', error);
      toast.error(MEAL_PLAN_MESSAGES.LOAD_ERROR);
    }
  };

  const startEditing = (plan) => {
    setEditingId(plan.id);
    setEditingName(plan.name);
  };

  const cancelEditing = () => {
    setEditingId(null);
    setEditingName('');
  };

  const handleNameSubmit = async (plan) => {
    try {
      const { data, error } = await supabase
        .from('meal_plans')
        .update({ name: editingName })
        .eq('id', plan.id)
        .select()
        .single();

      if (error) throw error;

      await getSavedMealPlans(); // Refresh the list
      setEditingId(null);
      toast.success(MEAL_PLAN_MESSAGES.PLAN_NAME_UPDATED);
    } catch (error) {
      console.error('Error updating plan name:', error);
      toast.error(MEAL_PLAN_MESSAGES.PLAN_NAME_UPDATE_ERROR);
    }
  };

  const handleKeyPress = (e, plan) => {
    if (e.key === 'Enter') {
      handleNameSubmit(plan);
    } else if (e.key === 'Escape') {
      cancelEditing();
    }
  };

  const toggleShoppingList = (planId) => {
    const plan = savedMealPlans.find(p => p.id === planId);
    console.group('Shopping List Toggle');
    console.log('Current showShoppingList state:', showShoppingList);
    console.log('Toggling shopping list for planId:', planId);
    console.log('Found plan:', plan);
    console.log('Shopping list data:', {
      hasShoppingList: !!plan?.plan?.shoppingList,
      shoppingList: plan?.plan?.shoppingList,
      categories: ['dairy', 'pantry', 'produce', 'protein', 'spices'].map(category => ({
        category,
        items: plan?.plan?.shoppingList?.[category] || []
      }))
    });
    console.groupEnd();

    // Toggle the state
    const newState = showShoppingList === planId ? null : planId;
    console.log('New showShoppingList state:', newState);
    setShowShoppingList(newState);
  };

  // Add debugging for savedMealPlans data
  useEffect(() => {
    console.group('SavedMealPlans Data');
    console.log('Current savedMealPlans:', savedMealPlans);
    savedMealPlans.forEach(plan => {
      console.log(`Plan ${plan.id} shopping list:`, {
        hasShoppingList: !!plan?.plan?.shoppingList,
        shoppingListKeys: plan?.plan?.shoppingList ? Object.keys(plan.plan.shoppingList) : []
      });
    });
    console.groupEnd();
  }, [savedMealPlans]);

  // Add debugging for showShoppingList state changes
  useEffect(() => {
    console.log('showShoppingList state changed to:', showShoppingList);
  }, [showShoppingList]);

  // Add toggle function for meal plan
  const toggleMealPlan = (planId) => {
    setShowMealPlan(showMealPlan === planId ? null : planId);
  };

  const handleDelete = async (planId) => {
    try {
      // First verify the plan exists and belongs to the current user
      const { data: plan, error: fetchError } = await supabase
        .from('meal_plans')
        .select()
        .eq('id', planId)
        .eq('user_id', user.id)
        .single();

      if (fetchError || !plan) {
        throw new Error('Meal plan not found or access denied');
      }

      // Then delete the specific plan
      const { error: deleteError } = await supabase
        .from('meal_plans')
        .delete()
        .eq('id', planId)
        .eq('user_id', user.id)
        .single();

      if (deleteError) throw deleteError;

      await getSavedMealPlans(); // Refresh the list
      setDeleteConfirmId(null);
      toast.success(MEAL_PLAN_MESSAGES.PLAN_DELETED);
    } catch (error) {
      console.error('Error deleting meal plan:', error);
      toast.error(error.message || MEAL_PLAN_MESSAGES.LOAD_ERROR);
    }
  };

  if (!user) {
    return (
      <div className="container max-w-4xl mx-auto py-12 px-4">
        <div className="flex flex-col items-center justify-center min-h-[60vh] space-y-4 text-center">
          <RiCalendarLine className="w-12 h-12 text-foggy" />
          <h2 className="text-xl font-medium text-dark">Please Sign In</h2>
          <p className="text-foggy">Sign in to view your saved meal plans</p>
          <button
            onClick={() => navigate('/')}
            className="btn-primary"
          >
            Go to Home
          </button>
        </div>
      </div>
    );
  }

  // Show loading state only for longer loads
  if (showLoadingState) {
    return (
      <div className="container max-w-4xl mx-auto py-8 px-4">
        <div className="flex items-center justify-between mb-8">
          <div>
            <h1 className="text-2xl font-bold text-dark">Saved Meal Plans</h1>
            <p className="text-foggy mt-1">Your previously saved meal plans</p>
          </div>
          <div className="w-[120px]" /> {/* Placeholder for button width */}
        </div>
        <div className="flex flex-col items-center justify-center min-h-[40vh] space-y-4">
          <motion.div
            animate={{ rotate: 360 }}
            transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
          >
            <RiLoader4Line className="w-12 h-12 text-primary" />
          </motion.div>
          <p className="text-foggy">Loading your saved meal plans...</p>
        </div>
      </div>
    );
  }

  console.log('Rendering saved meal plans:', savedMealPlans);

  if (!savedMealPlans?.length) {
    return (
      <div className="container max-w-4xl mx-auto py-12 px-4">
        <div className="flex flex-col items-center justify-center min-h-[60vh] space-y-4 text-center">
          <RiCalendarLine className="w-12 h-12 text-foggy" />
          <h2 className="text-xl font-medium text-dark">No Saved Meal Plans</h2>
          <p className="text-foggy">Generate a meal plan to get started!</p>
          <button
            onClick={() => navigate('/preferences')}
            className="btn-primary"
          >
            Create Meal Plan
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="container max-w-4xl mx-auto pt-0 sm:pt-4 px-4 pb-56">
      <div>
        <div className="flex items-start sm:items-center gap-2.5 sm:gap-3 mb-3 sm:mb-4">
          <RiSaveLine className="hidden sm:block w-8 h-8 sm:w-10 sm:h-10 text-primary mt-0.5 sm:mt-0" />
          <div>
            <h1 className="text-2xl sm:text-2xl font-bold text-dark leading-tight">Saved Meal Plans</h1>
            <p className="text-sm sm:text-base text-foggy mt-0.5 sm:mt-1">Your previously saved meal plans</p>
          </div>
        </div>
      </div>

      <div className="space-y-4">
        {savedMealPlans.map((plan) => (
          <div
            key={plan.id}
            className={`bg-white rounded-lg shadow-sm border transition-all ${
              activeMealPlanId === plan.id 
                ? 'border-green-500 shadow-md ring-1 ring-green-500' 
                : 'border-light hover:shadow-md'
            } p-3 sm:p-4`}
          >
            <div className="flex flex-col sm:flex-row sm:items-start justify-between gap-4">
              <div className="space-y-2 w-full sm:w-auto">
                <div className="flex flex-col sm:flex-row sm:items-center gap-2">
                  {editingId === plan.id ? (
                    <div className="flex items-center gap-2 w-full sm:w-auto">
                      <input
                        type="text"
                        value={editingName}
                        onChange={(e) => setEditingName(e.target.value)}
                        onKeyDown={(e) => handleKeyPress(e, plan)}
                        className="flex-1 px-2 py-1 border border-primary rounded-md text-base sm:text-lg font-medium text-dark focus:outline-none focus:ring-2 focus:ring-primary"
                        autoFocus
                      />
                      <div className="flex items-center gap-1">
                        <button
                          onClick={() => handleNameSubmit(plan)}
                          className="p-1 text-green-500 hover:text-green-600"
                          title="Save"
                        >
                          <RiCheckLine className="text-xl" />
                        </button>
                        <button
                          onClick={cancelEditing}
                          className="p-1 text-red-500 hover:text-red-600"
                          title="Cancel"
                        >
                          <RiCloseLine className="text-xl" />
                        </button>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="flex items-center justify-between w-full sm:w-auto">
                        <div className="flex items-center gap-2">
                          <h3 className="text-base sm:text-lg font-medium text-dark">{plan.name}</h3>
                          {activeMealPlanId === plan.id && (
                            <span className="px-2 py-0.5 bg-green-100 text-green-700 text-xs font-medium rounded-full whitespace-nowrap">
                              Active Plan
                            </span>
                          )}
                        </div>
                        <div className="flex items-center gap-1 sm:ml-2">
                          <button
                            onClick={() => startEditing(plan)}
                            className="p-1 text-foggy hover:text-primary transition-colors"
                            title="Edit name"
                          >
                            <RiEditLine className="text-sm" />
                          </button>
                          <button
                            onClick={() => setDeleteConfirmId(plan.id)}
                            className="p-1 text-foggy hover:text-red-500 transition-colors"
                            title="Delete plan"
                          >
                            <RiDeleteBin6Line className="text-sm" />
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="flex flex-wrap items-center gap-x-4 gap-y-2 text-sm text-foggy">
                  <div className="flex items-center gap-1">
                    <RiCalendarLine className="flex-shrink-0 text-base" />
                    <span className="whitespace-nowrap">
                      Created {format(new Date(plan.created_at), 'MMM d, yyyy')}
                    </span>
                  </div>
                  <div className="flex items-center gap-1">
                    <RiUserLine className="flex-shrink-0 text-base" />
                    <span>
                      {plan.preferences?.peopleCount || 1} {plan.preferences?.peopleCount === 1 ? 'person' : 'people'}
                    </span>
                  </div>
                  {plan.preferences?.mealPreparationTime && (
                    <div className="flex items-center gap-1">
                      <RiTimeLine className="flex-shrink-0 text-base" />
                      <span className="capitalize">{plan.preferences.mealPreparationTime}</span>
                    </div>
                  )}
                </div>
                <div className="flex flex-wrap items-center gap-x-4 gap-y-2 text-sm text-foggy mt-2">
                  <div className="flex items-center gap-1">
                    <RiRestaurantLine className="flex-shrink-0 text-base" />
                    <span>
                      {(() => {
                        const mealTypes = plan.preferences?.mealTypes || [];
                        const totalMeals = plan.plan?.days?.reduce((total, day) => {
                          return total + Object.values(day.meals || {}).filter(meal => !meal.isPlaceholder).length;
                        }, 0) || 0;
                        
                        if (mealTypes.includes('all')) {
                          return `All meals (${totalMeals} total)`;
                        }
                        
                        const displayTypes = mealTypes.map(type => type.charAt(0).toUpperCase() + type.slice(1));
                        return `${displayTypes.join(', ')} (${totalMeals} total)`;
                      })()}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col sm:flex-row items-stretch sm:items-center gap-2 mt-2 sm:mt-0">
                <button
                  onClick={() => toggleMealPlan(plan.id)}
                  className="btn-secondary flex-1 sm:flex-initial flex items-center justify-center gap-1.5 py-2 px-4 sm:min-w-[120px]"
                  title={showMealPlan === plan.id ? 'Hide meals' : 'View meals'}
                >
                  <RiCalendarLine className="text-lg" />
                  <span>View Meals</span>
                </button>
                <button
                  onClick={() => toggleShoppingList(plan.id)}
                  className="btn-secondary flex-1 sm:flex-initial flex items-center justify-center gap-1.5 py-2 px-4 sm:min-w-[120px]"
                  title={showShoppingList === plan.id ? 'Hide shopping list' : 'Show shopping list'}
                >
                  <RiShoppingCart2Line className="text-lg" />
                  <span>View List</span>
                </button>
                <button
                  onClick={() => handleLoadMealPlan(plan.id)}
                  className="btn-secondary flex-1 sm:flex-initial flex items-center justify-center gap-1.5 py-2 px-4 sm:min-w-[140px]"
                  title="Use this plan as a starting point for a new meal plan"
                >
                  <div className="flex items-center gap-1.5">
                    <span>Reuse This Plan</span>
                    <RiArrowRightLine className="text-lg" />
                    <span className="ml-2 px-1.5 py-0.5 bg-orange-100 text-orange-600 text-[10px] font-medium rounded-md">
                      Experimental
                    </span>
                  </div>
                </button>
              </div>
            </div>

            {/* Meal Plan Section - Update for mobile */}
            <AnimatePresence>
              {showMealPlan === plan.id && (
                <motion.div
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: 'auto', opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{ duration: 0.2 }}
                  className="overflow-hidden"
                >
                  <div className="mt-4 pt-4 border-t border-light">
                    <div className="flex items-center justify-between mb-2">
                      <h4 className="font-medium text-dark">Weekly Meal Plan</h4>
                      <button 
                        onClick={() => setShowMealPlan(null)}
                        className="p-2 hover:bg-light/80 rounded-full transition-colors"
                      >
                        <RiCloseLine className="text-xl text-foggy" />
                      </button>
                    </div>
                    {plan?.plan?.days ? (
                      <div className="space-y-4">
                        {plan.plan.days.map((day, index) => (
                          <div key={index} className="bg-gray-50 p-3 sm:p-4 rounded-lg">
                            <h5 className="font-medium text-dark mb-3 capitalize">{day.dayName}</h5>
                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                              {['breakfast', 'lunch', 'dinner', 'snacks'].map(mealType => {
                                const meal = day.meals?.[mealType];
                                // Only render if meal exists and is not a placeholder
                                return meal && !meal.isPlaceholder && (
                                  <div key={mealType} className="space-y-1">
                                    <div className="flex items-center gap-2">
                                      <span className="text-sm font-medium text-primary capitalize">{mealType}</span>
                                    </div>
                                    {meal.name && <h6 className="text-dark">{meal.name}</h6>}
                                    {meal.description && <p className="text-sm text-foggy">{meal.description}</p>}
                                    {meal.prepTime && (
                                      <p className="text-xs text-foggy flex items-center gap-1">
                                        <RiTimeLine className="text-xs flex-shrink-0" />
                                        {meal.prepTime}
                                      </p>
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p className="text-foggy">No meal plan details available.</p>
                    )}
                  </div>
                </motion.div>
              )}
            </AnimatePresence>

            {/* Shopping List Section */}
            <AnimatePresence>
              {showShoppingList === plan.id && (
                <motion.div
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: 'auto', opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{ duration: 0.2 }}
                  className="overflow-hidden"
                >
                  <div className="mt-4 pt-4 border-t border-light">
                    <div className="flex items-center justify-between mb-2">
                      <h4 className="font-medium text-dark">Shopping List</h4>
                      <button 
                        onClick={() => setShowShoppingList(null)}
                        className="p-2 hover:bg-light/80 rounded-full transition-colors"
                      >
                        <RiCloseLine className="text-xl text-foggy" />
                      </button>
                    </div>
                    {plan?.plan?.shoppingList ? (
                      <div className="space-y-4">
                        {Object.entries(plan.plan.shoppingList)
                          .filter(([category]) => category !== 'warnings' && Array.isArray(plan.plan.shoppingList[category]))
                          .map(([category, items]) => (
                            items && items.length > 0 && (
                              <div key={category} className="bg-gray-50 p-3 sm:p-4 rounded-lg">
                                <h5 className="font-medium text-dark mb-3">{category.charAt(0).toUpperCase() + category.slice(1)}</h5>
                                <div className="space-y-2">
                                  {items.map((item, index) => (
                                    <div key={index} className="space-y-0.5">
                                      <div className="flex items-baseline gap-2">
                                        <span className="text-sm font-medium text-dark whitespace-nowrap">
                                          {item.quantity}
                                        </span>
                                        <span className="text-sm text-dark">
                                          {item.item}
                                        </span>
                                      </div>
                                      {item.notes && (
                                        <div className="text-xs text-foggy">
                                          ({item.notes})
                                        </div>
                                      )}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )
                          ))}
                      </div>
                    ) : (
                      <p className="text-foggy">No shopping list available for this meal plan.</p>
                    )}
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        ))}
      </div>

      {/* Delete Confirmation Modal */}
      {deleteConfirmId && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <h2 className="text-xl font-bold mb-4">Delete Meal Plan</h2>
            <p className="text-foggy mb-6">Are you sure you want to delete this meal plan? This action cannot be undone.</p>
            <div className="flex justify-end gap-4">
              <button
                onClick={() => setDeleteConfirmId(null)}
                className="px-4 py-2 text-foggy hover:text-dark transition-colors"
              >
                Cancel
              </button>
              <button
                onClick={() => handleDelete(deleteConfirmId)}
                className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SavedMealPlans; 